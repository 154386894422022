import React, { useState, useMemo, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../elements/Button";
import TextInput from "../../elements/TextInput";
import Select from "react-dropdown-select";
import FormLayout from "../../layouts/FormLayout";
// import SelectSearch, { SelectedOptionValue } from "react-select-search";
import { iNotification } from "react-notifications-component";
import countryList from "react-select-country-list";
import { getErrorMessage, notify, requestERPLYData } from "../../../utils";
import { noAccountError, useAuthState } from "../../../context/auth";
import Server, { partnerKey } from "../../../service/Server";
import { ErplyRequestData, SessionRequestI } from "../../../types";
import classNames from "classnames";
import ErplyIcon from "../../elements/ErplyIcon";

interface SignUpProps {
  toggleChangePassword: (state: boolean) => void;
  setPasswordResetToken: React.Dispatch<React.SetStateAction<string>>;
  togglRotatePassword: (state: boolean) => void;
}

interface FormState {
  name: string;
  companyName: string;
  country: {
    name: string;
    value: string;
  }[];
  phone: string;
  website: string;
  email: string;
  password: string;
  confirmPassword: string;
  terms: boolean;
}

const initialState: FormState = {
  name: "",
  companyName: "",
  country: [{ name: "United States", value: "US" }],
  phone: "",
  website: "",
  email: "",
  password: "",
  confirmPassword: "",
  terms: false,
};

const FullAccount: React.FC<SignUpProps> = ({
  togglRotatePassword,
  toggleChangePassword,
  setPasswordResetToken,
}) => {
  const [formState, setState] = useState<FormState>(initialState);
  const [errors, setErrors] = useState<FormState>(initialState);
  const [isLoading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);

  const navigate = useNavigate();

  const countryOptions = useMemo(
    () =>
      countryList()
        .getData()
        .map((e) => ({ name: e.label, value: e.value })),
    []
  );

  const {
    handleChange,
    isAuthenticated,
    session,
    sessionKey,
    baseUrl,
    setAccountAdminBaseUrl,
    setUserAPIBaseUrl,
  } = useAuthState();

  useEffect(() => {
    const dropdownContent = document.querySelector(
      ".react-dropdown-select-content"
    );

    if (dropdownContent) {
      dropdownContent.setAttribute(
        "data-testid",
        "create-free-account-country"
      );
    }
  }, []);

  useEffect(() => {
    if (isDropDownOpen) {
      const dropdownItems = document.querySelectorAll(
        ".react-dropdown-select-item"
      );

      if (dropdownItems.length) {
        dropdownItems.forEach((e) => {
          const country = e.getAttribute("aria-label");
          if (country) {
            e.setAttribute("data-test-key", country);
          }
        });
      }
    }
  }, [isDropDownOpen]);

  // react-dropdown-select-content
  // react-dropdown-select-dropdown
  // react-dropdown-select-item

  const onChange = (value: string | boolean, name: string) => {
    if (value) {
      if (name !== "terms") {
        setErrors({ ...errors, [name]: "" });
      } else {
        setErrors({ ...errors, terms: false });
      }
    } else {
      if (name !== "terms") {
        setErrors({ ...errors, [name]: "This field is required" });
      } else if (name === "terms") {
        setErrors({ ...errors, terms: true });
      }
    }

    setState({ ...formState, [name]: value });
  };

  const isErrorInData = (): boolean => {
    let isError = false;

    const errorsObject = JSON.parse(JSON.stringify(errors)) as FormState;

    if (!formState.name) {
      isError = true;
      errorsObject.name = "Name is required";
    }

    if (!formState.password) {
      isError = true;
      errorsObject.password = "Password is required";
    }

    if (!formState.companyName) {
      isError = true;
      errorsObject.companyName = "Company name is required";
    }

    // if (!formState.phone) {
    //   isError = true;
    //   errorsObject.phone = "Phnone number is required";
    // }

    if (!formState.email) {
      isError = true;
      errorsObject.email = "Email is required";
    }

    // if (!formState.website) {
    //   isError = true;
    //   errorsObject.website = "Website URL is required";
    // }

    if (!formState.country.length) {
      isError = true;
      // errorsObject.country = "Country is required";
    }

    // if (!formState.confirmPassword) {
    //   isError = true;
    //   errorsObject.confirmPassword = "Confirm password is required";
    // }

    // if (formState.confirmPassword && formState.password) {
    //   if (formState.password !== formState.confirmPassword) {
    //     isError = true;
    //     errorsObject.confirmPassword =
    //       "Confirm password and password must match";
    //   }
    // }

    if (!formState.terms) {
      isError = true;
      errorsObject.terms = true;
    }

    if (isError) {
      setErrors({ ...errors, ...errorsObject });
    }

    return isError;
  };

  const handleLogin = async (
    clientCode: string,
    username: string
  ): Promise<void> => {
    // localStorage.setItem(`${clientCode}_TARGET`, target);
    localStorage.setItem("SSO_CLIENT", "");
    try {
      setLoading(true);
      const { data } = await Server.getServiceEndPoint({
        clientCode,
        request: "getServiceEndpoints",
      });
      if (data.status.responseStatus === "error") {
        notify({
          message: noAccountError(),
          type: "danger",
        } as iNotification);
        setLoading(false);
        navigate("/");
      } else {
        const baseUrl: string = data.records[0].auth.url
          ? data.records[0].auth.url
          : "";
        const accAdminBaseUrl: string = data.records[0]["account-admin"].url
          ? data.records[0]["account-admin"].url
          : "";
        setAccountAdminBaseUrl(accAdminBaseUrl);
        const userBaseUrl: string = data.records[0]["user"].url
          ? data.records[0]["user"].url
          : "";
        setUserAPIBaseUrl(userBaseUrl);
        try {
          const request: SessionRequestI = {
            value: {
              password: formState.password,
              username,
              sessionLength: 43200,
            },
            clientCode,
            baseUrl,
          };
          const { data } = await Server.createSession(request);

          if (
            data.data.responseType &&
            data.data.responseType === "RENEW-PASSWORD-TOKEN" &&
            data.data.passwordRenewalToken
          ) {
            toggleChangePassword(true);
            togglRotatePassword(true);
            setPasswordResetToken(data.data.passwordRenewalToken);
            setLoading(false);
            handleChange({
              session: data.data,
              clientCode,
              baseUrl,
              sessionKey: data.data.session?.sessionKey
                ? data.data.session?.sessionKey
                : "",
              isAuthenticated: false,
            });
          } else if (data.data.session?.user.isPasswordExpired) {
            toggleChangePassword(true);
            togglRotatePassword(false);
            setPasswordResetToken("");
            handleChange({
              session: data.data,
              clientCode,
              baseUrl,
              sessionKey: data.data.session?.sessionKey
                ? data.data.session?.sessionKey
                : "",
              isAuthenticated: false,
            });
          } else if (data.data.twoFactorEnabled) {
            handleChange({
              session: data.data,
              clientCode,
              baseUrl,
              sessionKey: data.data.session?.sessionKey
                ? data.data.session?.sessionKey
                : "",
              isAuthenticated: false,
            });
            navigate("/two-factor");
          } else {
            handleChange({
              session: data.data,
              clientCode,
              baseUrl,
              sessionKey: data.data.session?.sessionKey
                ? data.data.session?.sessionKey
                : "",
              isAuthenticated: true,
            });
            setLoading(false);
            // localStorage.setItem("CLIENT", clientCode);
            navigate(`/${clientCode}/apps`);
          }
        } catch (err: any) {
          navigate("/");
          if (err.response) {
            const {
              response: { data },
            } = err;
            if (data) {
              notify({
                message: `${data.status.errorDescription}`,
                type: "danger",
              } as iNotification);
            } else {
              notify({
                message: "Error logging to account. Please try again",
                type: "danger",
              } as iNotification);
            }
          } else {
            notify({
              message: "Error logging to account. Please try again",
              type: "danger",
            } as iNotification);
          }
          setLoading(false);
        }
      }
    } catch (error) {
      notify({
        message: noAccountError(),
        type: "danger",
      } as iNotification);
      setLoading(false);
    }
  };

  const handleSignUp = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isErrorInData()) return;

    const nameSplit = formState.name.split(" ");

    const request: ErplyRequestData = {
      companyName: formState.companyName,
      firstName: nameSplit[0],
      lastName: nameSplit.length >= 2 ? nameSplit[1] : nameSplit[0],
      // phone: formState.phone,
      // website: formState.website,
      email: formState.email,
      password: formState.password,
      countryCode: formState.country[0].value,
      request: "createInstallation",
      username: formState.email,
      sendEmail: 1,
      partnerKey,
    };

    setLoading(true);

    try {
      const { data } = await requestERPLYData(request, true);
      if (data.status.responseStatus === "error") {
        notify({
          message: getErrorMessage(data.status.errorCode),
          type: "danger",
        } as iNotification);
        setLoading(false);
      } else {
        handleChange({
          session,
          sessionKey,
          isAuthenticated,
          baseUrl,
          clientCode: data.records[0].clientCode,
          username: data.records[0].username,
        });
        // navigate("/");
        handleLogin(data.records[0].clientCode, data.records[0].username);
        notify({
          message: "Account created successfully.",
          type: "success",
        } as iNotification);
      }
    } catch (error) {
      notify({
        message: "Error performing operation. Please try again",
        type: "danger",
      } as iNotification);
      setLoading(false);
    }
  };

  const btnCls = classNames(
    "dark:bg-white btn-block bg-erply-success-200 dark:text-gray-900 text-white  border-0 dark:hover:text-gray-900 dark:hover:bg-[#E6E6E6] hover:bg-[#4AD17A] active:bg-[#35A95F] dark:active:bg-erply-dark-100 mb-[30px] rounded-md",
    isLoading && "btn-disabled loading"
  );

  return (
    <FormLayout
      title='Create free account'
      isSignUp
      isLoading={isLoading}
      testIdForm='create-free-account-form'
      testIdFormHeader='create-free-account-hearder'
    >
      <form onSubmit={handleSignUp} className={"sm:w-96 w-80"}>
        <TextInput
          placeholder='Name'
          value={formState.name}
          onChange={(e) => {
            const { value } = e.target;
            onChange(value, "name");
          }}
          className='mb-[20px]'
          error={!!errors.name}
          data-testid='create-free-account-name'
          autoFocus
        />
        <TextInput
          placeholder='Company'
          className='mb-[20px]'
          value={formState.companyName}
          onChange={(e) => {
            const { value } = e.target;
            onChange(value, "companyName");
          }}
          error={!!errors.companyName}
          data-testid='create-free-account-company'
        />
        <div className='relative'>
          {formState.country ? (
            <label
              htmlFor='country'
              className='absolute left-4 top-[-17px] label-text text-[10px] text-[#7A838E] uppercase z-50'
            >
              Country
            </label>
          ) : null}
          {/* <SelectSearch
            onChange={(seleceted) => {
              const opt: any = seleceted as SelectedOptionValue;

              onChange(opt?.value || opt, "country");
            }}
            options={import Select from 'react-dropdown-select';}
            value={formState.country}
            search
            emptyMessage='Not found'
            filterOptions={fuzzySearch}
            id='country'
          /> */}
          <Select
            options={countryOptions}
            values={formState.country}
            required
            labelField='name'
            searchBy='name'
            onChange={(value) => {
              setState({ ...formState, country: value });
            }}
            dropdownHandleRenderer={({ state }) => {
              return (
                <ErplyIcon
                  icon={
                    state.dropdown ? "Chevron-Up-Closed" : "Chevron-Down-Closed"
                  }
                />
              );
            }}
            onDropdownClose={() => setIsDropDownOpen((prev) => !prev)}
            onDropdownOpen={() => setIsDropDownOpen((prev) => !prev)}
          />
        </div>

        <TextInput
          name='Work'
          placeholder='Work email'
          className='mb-[20px]'
          type='email'
          value={formState.email}
          onChange={(e) => {
            const { value } = e.target;
            onChange(value, "email");
          }}
          error={!!errors.email}
          required
          autoFocus={false}
          autoComplete='off'
          data-testid='create-free-account-work-email'
        />
        <TextInput
          placeholder='Password'
          className='mb-[20px]'
          value={formState.password}
          onChange={(e) => {
            const { value } = e.target;
            onChange(value, "password");
          }}
          error={!!errors.password}
          type={showPassword ? "text" : "password"}
          icon={showPassword ? "Eye-Off" : "Eye-On"}
          onIconClick={() => setShowPassword((prev) => !prev)}
          data-testid='create-free-account-password'
          iconTestid='create-free-account-password-icon'
        />

        <div className='flex items-center text-sm mb-[10px]'>
          <input
            type='checkbox'
            className='checkbox'
            checked={formState.terms}
            onChange={(e) => {
              const { checked } = e.target;
              onChange(checked, "terms");
            }}
            data-testid='create-free-account-terms-of-service-accept'
          />
          <span className=' dark:text-white text-gray-900 ml-2'>
            I agree to
          </span>
          <a
            href='https://erply.com/terms-of-service/'
            target='_blank'
            rel='noopener noreferrer'
            className='text-erply-light-200 hover:underline cursor-pointer ml-1'
            data-testid='create-free-account-terms-of-service-link'
          >
            Terms of Service
          </a>
        </div>
        {!formState.terms && errors.terms ? (
          <div className='mb-[10px]'>
            <span className='text-xs text-red-600 dark:text-red-400'>
              Please agree to our terms of service
            </span>
          </div>
        ) : null}

        <Button
          className={btnCls}
          type='submit'
          disabled={isLoading}
          data-testid='create-account-button'
        >
          Create Account
        </Button>

        <p className='text-sm mt-4 dark:text-white text-gray-900'>
          Already have an account?{" "}
          <Link
            to='/'
            className='text-erply-light-200 hover:underline cursor-pointer'
            data-testid='sign-in-here-link'
          >
            Sign in here
          </Link>{" "}
        </p>
      </form>
    </FormLayout>
  );
};

export default FullAccount;
