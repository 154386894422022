import React, { useMemo } from "react";
import sideImg from "../../assets/side-img.png";
import logo from "../../assets/Erply-logo-new.svg";
import { useAuthState } from "../../context/auth";
import { getReleaseInfo } from "../../utils";

interface SideLayoutProps {
  isSignUp?: boolean;
}

const SideLayout: React.FC<SideLayoutProps> = ({ isSignUp }) => {
  const { appType } = useAuthState();

  const { time, version } = getReleaseInfo();

  const getDisplay = useMemo(() => {
    switch (appType) {
      case "backOffice":
        return {
          title: "Back Office",
          description: "Full-Lifecycle Warehouse and Product Management",
        };

      case "pointOfSale":
        return {
          title: "Point of Sale",
          description:
            "Cloud-based POS with Integrated Payments and Offline Support",
        };

      case "serviceApp":
        return {
          title: "Service App",
          description:
            "All-in-one Service Shop Software with Work Orders and Booking Calendars",
        };

      default:
        return {
          title: "",
          description:
            "Real-time Store, Retail Chain and Franchise Management Platform",
        };
    }
  }, [appType]);

  return (
    <div
      className='bg-erply-dark-300 lg:rounded-l-[20px] text-white md:flex md:flex-col sm:justify-between hidden dark:border-0 lg:border lg:shadow-md relative'
      style={{ width: "370px" }}
    >
      <div className='flex flex-col items-center space-y-3 pt-[100px] mb-20'>
        <div className='flex flex-col items-center'>
          {/* <Image src={logo} /> */}
          <img src={logo} alt='Logo' />
          {getDisplay.title ? (
            <h1 className='text-2xl font-semibold pt-2'>{getDisplay.title}</h1>
          ) : null}
        </div>
        <p className='text-sm text-gray-400 text-center pt-1'>
          {getDisplay.description}
        </p>
      </div>
      <img src={sideImg} alt='Side' className='w-full lg:rounded-bl-[20px]' />
      <div className='text-erply-light-10 text-xs px-6 py-4 opacity-70 flex flex-col absolute bottom-0 right-0 items-end'>
        <span>v. {version}</span>
        <span>{time}</span>
      </div>
    </div>
  );
};

export default SideLayout;
