import styled from "styled-components";
import iconValues from "../../styles/icons.json";
import { IconValueType } from "../../styles/iconValues";

const ErplyIcon = styled("div")<{
  icon: IconValueType;
  size?: number;
  color?: string;
  lineHeight?: number;
  cursor?: string;
}>(
  ({ icon, color, size, lineHeight, cursor }) => `::before {
    content: "\\${Number(iconValues[icon]).toString(16)}";
    font-family: Erply-Icons, serif;
    font-size: ${size ?? 24}px;
    cursor: ${cursor ?? "pointer"};
    color: ${color ?? "inherit"};
    line-height: ${lineHeight ?? "24px"};
  }
`,
  {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }
);

export default ErplyIcon;
