import classNames from "classnames";
import React, { useState } from "react";
import Server from "../../../service/Server";
import { notify } from "../../../utils";
import Alert from "../../elements/Alert";
import Button from "../../elements/Button";
import TextInput from "../../elements/TextInput";
import { UpdatePasswordRequestI } from "../../../types/index";
import { useAuthState } from "../../../context/auth";
import { iNotification } from "react-notifications-component";
import useMatchMediaMatches from "../../../hooks/useMatchMediaMatches";

// sm:mt-14 sm:ml-40 md:w-4/5 ml-auto mr-auto

const PasswordSettings: React.FC = () => {
  const [formData, setData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const { clientCode, sessionKey, baseUrl } = useAuthState();

  const { oldPassword, newPassword, confirmPassword } = formData;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setData({ ...formData, [name]: value });
    if (name === "oldPassword") {
      if (value) {
        setErrors({ ...errors, [name]: "" });
      } else {
        setErrors({ ...errors, [name]: "Old password is required" });
      }
    }

    if (name === "newPassword") {
      if (value) {
        setErrors({ ...errors, [name]: "" });
      } else {
        setErrors({ ...errors, [name]: "New password is required" });
      }
    }

    if (name === "confirmPassword") {
      if (value) {
        setErrors({ ...errors, [name]: "" });
      } else {
        setErrors({ ...errors, [name]: "Confirm password is required" });
      }
    }
  };

  const isErrorInData = (): boolean => {
    let isError = false;
    const errorsObject = {
      newPassword: "",
      oldPassword: "",
      confirmPassword: "",
    };

    if (!newPassword) {
      isError = true;
      errorsObject.newPassword = "New password is required";
    }

    if (!oldPassword) {
      isError = true;
      errorsObject.oldPassword = "Old password is required";
    }

    if (!confirmPassword) {
      isError = true;
      errorsObject.confirmPassword = "Confirm password is required";
    }

    if (confirmPassword && confirmPassword !== newPassword) {
      isError = true;
      errorsObject.confirmPassword =
        "New password and confrim password must match";
    }

    if (isError) {
      setErrors({ ...errors, ...errorsObject });
    }

    return isError;
  };

  const handleChangePassword = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    if (isErrorInData()) return;
    try {
      setLoading(true);
      const request: UpdatePasswordRequestI = {
        value: {
          oldPassword,
          newPassword,
        },
        clientCode,
        baseUrl,
        sessionKey,
      };
      await Server.updatePassword(request);
      notify({
        message: "Password updated successfully",
        type: "success",
      } as iNotification);
      setData({ newPassword: "", oldPassword: "", confirmPassword: "" });
      setErrors({ newPassword: "", oldPassword: "", confirmPassword: "" });
      setLoading(false);
    } catch (err: any) {
      if (err.response) {
        const {
          response: { data },
        } = err;
        if (data) {
          notify({
            message: `${data.status.errorDescription}`,
            type: "danger",
          } as iNotification);
        } else {
          notify({
            message: "Error performing operation. Please try again",
            type: "danger",
          } as iNotification);
        }
      } else {
        notify({
          message: "Error performing operation. Please try again",
          type: "danger",
        } as iNotification);
      }
      setLoading(false);
    }
  };

  const isDesktopOrLaptop =
    useMatchMediaMatches("(min-width: 1224px)") === true;

  const btnCls = classNames(
    "dark:bg-white sm:w-[295px] w-full bg-erply-success-200 dark:text-gray-900 text-white border-0 dark:hover:text-gray-900 dark:hover:bg-[#E6E6E6] hover:bg-[#4AD17A] active:bg-[#35A95F] dark:active:bg-erply-dark-100",
    isLoading && "btn-disabled loading"
  );

  return (
    <div
      className={
        isDesktopOrLaptop
          ? "lg:w-[800px] mr-auto md:w-4/6"
          : "md:landscape:w-full md:ml-auto mr-auto md:w-4/6"
      }
      data-testid='password-form'
    >
      <Alert
        message={`Password needs to be minimum 8 characters long, have one capital letter and one number. `}
        className='dark:bg-erply-dark-500 dark:text-white rounded-[5px]'
      />
      <form
        onSubmit={handleChangePassword}
        className='sm:bg-erply-alert-50 dark:bg-erply-dark-500 p-8 mt-7 w-full rounded-[5px]'
      >
        <span
          className='text-base font-bold block mb-8 dark:text-white text-gray-900'
          data-testid='password-header-text'
        >
          Change Password
        </span>
        <div className='sm:flex lg:space-x-3 items-center lg:w-[470px] w-full mb-5 lg:justify-between justify-center'>
          <label
            htmlFor='oldPassword'
            className='lg:block hidden dark:text-white text-gray-900 text-right ml-auto'
          >
            Current Password
          </label>
          <TextInput
            className='sm:w-[295px] w-full dark:bg-transparent bg-white'
            placeholder='Current password'
            name='oldPassword'
            value={oldPassword}
            onChange={onChange}
            type='password'
            error={!!errors.oldPassword}
            data-testid='current-password'
            disableFloatingLabel
          />
        </div>
        <div className='sm:flex lg:space-x-3 items-center lg:w-[470px] w-full mb-5 lg:justify-between justify-center'>
          <label
            htmlFor='newPassword'
            className='lg:flex hidden dark:text-white text-gray-900  ml-auto'
          >
            New Password
          </label>
          <TextInput
            className='sm:w-[295px] w-full dark:bg-transparent bg-white'
            placeholder='New password'
            name='newPassword'
            value={newPassword}
            onChange={onChange}
            type='password'
            autoComplete='off'
            error={!!errors.newPassword}
            data-testid='new-password'
            disableFloatingLabel
          />
        </div>
        <div className='sm:flex lg:space-x-3 items-center lg:w-[470px] w-full mb-5 lg:justify-between justify-center'>
          <label
            htmlFor='confirmPassword'
            className='lg:block hidden dark:text-white text-gray-900 ml-auto'
          >
            Confirm Password
          </label>
          <TextInput
            className='sm:w-[295px] w-full dark:bg-transparent bg-white'
            placeholder='Confirm password'
            name='confirmPassword'
            type='password'
            autoComplete='off'
            value={confirmPassword}
            onChange={onChange}
            error={!!errors.confirmPassword}
            data-testid='confirm-password'
            disableFloatingLabel
          />
        </div>
        <div className='sm:flex  items-center lg:w-[470px] md:w-auto w-full mb-5 lg:justify-end justify-center'>
          <Button
            className={btnCls}
            type='submit'
            data-testid='update-password-button'
          >
            Update Password
          </Button>
        </div>
      </form>
    </div>
  );
};

export default PasswordSettings;
