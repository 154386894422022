import React, { useState } from "react";
import Button from "../elements/Button";
import TextInput from "../elements/TextInput";
import FormLayout from "../layouts/FormLayout";
import { noAccountError, useAuthState } from "../../context/auth";
import useFormHandler from "../../hooks/useFormHandler";
import { iNotification } from "react-notifications-component";
import Server from "../../service/Server";
import { notify } from "../../utils";
import {
  RenewPasswordRequestI,
  UpdatePasswordRequestI,
} from "../../types/index";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import ErplyIcon from "../elements/ErplyIcon";
import Alert from "../elements/Alert";

interface UpdatePasswordProps {
  toggleChangePassword: (state: boolean) => void;
  isPasswordRotation?: boolean;
  passwordRenewalToken?: string;
  isHome?: boolean;
}

const UpdatePassword: React.FC<UpdatePasswordProps> = ({
  toggleChangePassword,
  isPasswordRotation = false,
  passwordRenewalToken = "",
  isHome = false,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const navigate = useNavigate();

  const { oldPassword, newPassword, handleChange } = useFormHandler({
    username: "",
    clientCode: "",
    sessionKey: "",
    password: "",
    oldPassword: "",
    newPassword: "",
    twoFactorKey: "",
  });

  const {
    clientCode,
    baseUrl,
    sessionKey,
    session,
    handleChange: setAuth,
  } = useAuthState();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;

    if (name === "confirmPassword") {
      setConfirmPassword(value);
    } else {
      handleChange(value, name);
    }

    if (name === "oldPassword") {
      if (value) {
        setErrors({ ...errors, [name]: "" });
      } else {
        setErrors({ ...errors, [name]: "Old password is required" });
      }
    }

    if (name === "confirmPassword") {
      if (value) {
        setErrors({ ...errors, [name]: "" });
      } else {
        setErrors({ ...errors, [name]: "Confirm password is required" });
      }
    }

    if (name === "newPassword") {
      if (value) {
        setErrors({ ...errors, [name]: "" });
      } else {
        setErrors({ ...errors, [name]: "New password is required" });
      }
    }
  };

  const isErrorInData = (): boolean => {
    let isError = false;
    const errorsObject = {
      newPassword: "",
      oldPassword: "",
      confirmPassword: "",
    };

    if (!newPassword) {
      isError = true;
      errorsObject.newPassword = "New password is required";
    }

    if (!oldPassword) {
      isError = true;
      errorsObject.oldPassword = "Old password is required";
    }

    if (!confirmPassword) {
      isError = true;
      errorsObject.confirmPassword = "Confirm password is required";
    }

    if (confirmPassword && confirmPassword !== newPassword) {
      isError = true;
      errorsObject.confirmPassword =
        "New password and confrim password must match";
    }

    if (isError) {
      setErrors({ ...errors, ...errorsObject });
    }

    return isError;
  };

  const isErrorInDataPasswordRotation = (): boolean => {
    let isError = false;
    const errorsObject = {
      newPassword: "",
      oldPassword: "",
      confirmPassword: "",
    };

    if (!newPassword) {
      isError = true;
      errorsObject.newPassword = "New password is required";
    }

    if (!confirmPassword) {
      isError = true;
      errorsObject.confirmPassword = "Confirm password is required";
    }

    if (confirmPassword && confirmPassword !== newPassword) {
      isError = true;
      errorsObject.confirmPassword =
        "New password and confrim password must match";
    }

    if (isError) {
      setErrors({ ...errors, ...errorsObject });
    }

    return isError;
  };

  const getServiceEndPointAndSaveChanges = async () => {
    setLoading(true);
    try {
      const { data } = await Server.getServiceEndPoint({
        clientCode,
        request: "getServiceEndpoints",
      });
      if (data.status.responseStatus === "error") {
        notify({
          message: noAccountError(),
          type: "danger",
        } as iNotification);
        setLoading(false);
      } else {
        const url: string = data.records[0].auth.url
          ? data.records[0].auth.url
          : baseUrl;
        if (isPasswordRotation) {
          handlePasswordRenew(url);
        } else {
          handleChangePassword(url);
        }
      }
    } catch (error) {
      notify({
        message: noAccountError(),
        type: "danger",
      } as iNotification);
      setLoading(false);
    }
  };

  const handleChangePassword = async (url: string): Promise<void> => {
    if (isErrorInData()) {
      setLoading(false);
      return;
    }
    try {
      setLoading(true);
      const request: UpdatePasswordRequestI = {
        value: {
          oldPassword,
          newPassword,
        },
        clientCode,
        baseUrl: url,
        sessionKey,
      };
      await Server.updatePassword(request);
      notify({
        message: "Password updated successfully",
        type: "success",
      } as iNotification);

      setAuth({
        clientCode,
        sessionKey,
        session,
        isAuthenticated: true,
        baseUrl,
      });
      setLoading(false);
      // localStorage.setItem("CLIENT", clientCode);
      navigate(`/${clientCode}/apps`);
    } catch (err: any) {
      if (err.response) {
        const {
          response: { data },
        } = err;
        if (data) {
          notify({
            message: `${data.status.errorDescription}`,
            type: "danger",
          } as iNotification);
        } else {
          notify({
            message: "Error performing operation. Please try again",
            type: "danger",
          } as iNotification);
        }
      } else {
        notify({
          message: "Error performing operation. Please try again",
          type: "danger",
        } as iNotification);
      }
      setLoading(false);
    }
  };

  const handlePasswordRenew = async (url: string) => {
    if (isErrorInDataPasswordRotation()) {
      setLoading(false);
      return;
    }
    try {
      setLoading(true);
      const request: RenewPasswordRequestI = {
        value: {
          newPassword,
          token: passwordRenewalToken,
        },
        clientCode,
        baseUrl: url,
      };
      const { data } = await Server.renewPassword(request);
      setAuth({
        session: data.data,
        clientCode,
        baseUrl,
        sessionKey: data.data.session?.sessionKey
          ? data.data.session?.sessionKey
          : "",
        isAuthenticated: true,
      });
      setLoading(false);
      // localStorage.setItem("CLIENT", clientCode);
      navigate(`/${clientCode}/apps`);
    } catch (err: any) {
      if (err.response) {
        const {
          response: { data },
        } = err;
        if (data) {
          notify({
            message: `${
              data?.status?.errorDescription ||
              "Error performing operation. Please try again"
            }`,
            type: "danger",
          } as iNotification);
        } else {
          notify({
            message: "Error performing operation. Please try again",
            type: "danger",
          } as iNotification);
        }
      } else {
        notify({
          message: "Error performing operation. Please try again",
          type: "danger",
        } as iNotification);
      }
      setLoading(false);
    }
  };

  const btnCls = classNames(
    "dark:bg-white btn-block bg-erply-success-200 dark:text-gray-900 text-white border-0 dark:hover:text-gray-900 dark:hover:bg-[#E6E6E6] hover:bg-[#4AD17A] active:bg-[#35A95F] dark:active:bg-erply-dark-100",
    isLoading && "btn-disabled loading"
  );

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isPasswordRotation) {
      if (isErrorInDataPasswordRotation()) {
        return;
      }
    } else {
      if (isErrorInData()) {
        return;
      }
    }
    getServiceEndPointAndSaveChanges();
  };

  const renderForm = () => {
    if (isPasswordRotation && !isHome) {
      return (
        <form onSubmit={handleSubmit} className='sm:w-96 w-80'>
          <Alert
            message={`Password needs to be minimum 8 characters long, have one capital letter and one number. `}
            className='dark:bg-erply-dark-500 dark:text-white mb-5'
          />
          <TextInput
            name='newPassword'
            placeholder='New Password'
            value={newPassword}
            onChange={onChange}
            type='password'
            className='mb-5'
            error={!!errors.newPassword}
            required
          />
          <TextInput
            name='confirmPassword'
            placeholder='Confirm Password'
            value={confirmPassword}
            onChange={onChange}
            type='password'
            className='mb-[30px]'
            error={!!errors.confirmPassword}
            required
          />
          <div className='flex space-x-5'>
            <div className=''>
              <Button
                className='btn-block bg-erply-dark-20 border-0 text-white dark:text-gray-900 dark:hover:text-white'
                onClick={() => {
                  toggleChangePassword(false);
                }}
                type='button'
              >
                <ErplyIcon icon='Arrow-Left' />
              </Button>
            </div>
            <div className='w-full'>
              <Button className={btnCls} type='submit' disabled={isLoading}>
                Change
              </Button>
            </div>
          </div>
        </form>
      );
    }

    if (isPasswordRotation && isHome) {
      return (
        <FormLayout isLoading={isLoading} title='Change password'>
          <form onSubmit={handleSubmit} className='sm:w-96 w-80'>
            <Alert
              message={`Password needs to be minimum 8 characters long, have one capital letter and one number. `}
              className='dark:bg-erply-dark-500 dark:text-white mb-5'
            />
            <TextInput
              name='newPassword'
              placeholder='New Password'
              value={newPassword}
              onChange={onChange}
              type='password'
              className='mb-5'
              error={!!errors.newPassword}
              required
            />
            <TextInput
              name='confirmPassword'
              placeholder='Confirm Password'
              value={confirmPassword}
              onChange={onChange}
              type='password'
              className='mb-[30px]'
              error={!!errors.confirmPassword}
              required
            />
            <div className='flex space-x-5'>
              <div className=''>
                <Button
                  className='btn-block bg-erply-dark-20 border-0 text-white dark:text-gray-900 dark:hover:text-white'
                  onClick={() => toggleChangePassword(false)}
                  type='button'
                >
                  <ErplyIcon icon='Arrow-Left' />
                </Button>
              </div>
              <div className='w-full'>
                <Button className={btnCls} type='submit' disabled={isLoading}>
                  Change
                </Button>
              </div>
            </div>
          </form>
        </FormLayout>
      );
    }

    return (
      <FormLayout isLoading={isLoading} title='Change password'>
        <form onSubmit={handleSubmit} className='sm:w-96 w-80'>
          <Alert
            message={`Password needs to be minimum 8 characters long, have one capital letter and one number. `}
            className='dark:bg-erply-dark-500 dark:text-white mb-5'
          />
          <TextInput
            name='oldPassword'
            placeholder='Old Password'
            value={oldPassword}
            onChange={onChange}
            type='password'
            className='mb-5'
            error={!!errors.oldPassword}
            required
          />
          <TextInput
            name='newPassword'
            placeholder='New Password'
            value={newPassword}
            onChange={onChange}
            type='password'
            className='mb-5'
            error={!!errors.newPassword}
            required
          />
          <TextInput
            name='confirmPassword'
            placeholder='Confirm Password'
            value={confirmPassword}
            onChange={onChange}
            type='password'
            className='mb-[30px]'
            error={!!errors.confirmPassword}
            required
          />
          <div className='flex space-x-5'>
            <div className=''>
              <Button
                className='btn-block bg-erply-dark-20 border-0 text-white dark:text-gray-900 dark:hover:text-white'
                onClick={() => toggleChangePassword(false)}
                type='button'
              >
                <ErplyIcon icon='Arrow-Left' />
              </Button>
            </div>
            <div className='w-full'>
              <Button className={btnCls} type='submit' disabled={isLoading}>
                Change
              </Button>
            </div>
          </div>
        </form>
      </FormLayout>
    );
  };

  return <>{renderForm()}</>;
};

export default UpdatePassword;
