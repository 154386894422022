import React from "react";
import classNames from "classnames";
import ErplyIcon from "./ErplyIcon";
import { IconValueType } from "../../styles/iconValues";

interface TextInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  error?: boolean;
  disableFloatingLabel?: boolean;
  icon?: IconValueType;
  onIconClick?: () => void;
  iconTestid?: string;
}

const TextInput: React.FC<TextInputProps> = ({
  className,
  type,
  error,
  name,
  placeholder,
  disableFloatingLabel,
  onIconClick,
  icon,
  iconTestid,
  ...props
}) => {
  const cls = classNames(
    "input input-bordered w-full dark:text-white text-gray-900 peer z-10 rounded-md !h-[44px] text-[14px]",
    className,
    error
      ? "input-error"
      : "bg-transparent dark:border-[#4b5d74] dark:bg-[#283547]",
    !disableFloatingLabel && "placeholder-transparent",
    icon && "pr-12"
  );

  return (
    <div className='relative'>
      <input
        {...props}
        id={name || placeholder}
        type={type ? type : "text"}
        className={cls}
        placeholder={placeholder}
        name={name}
      />
      {!disableFloatingLabel && (
        <label
          htmlFor={name || placeholder}
          className='absolute uppercase -top-4  left-4 text-[10px] peer-placeholder-shown:top-3.5 peer-placeholder-shown:left-4 transition-all peer-focus:-top-4 peer-focus:uppercase peer-placeholder-shown:normal-case text-[#7A838E] peer-placeholder-shown:text-xs peer-focus:text-[10px] peer-placeholder-shown:text-[14px]'
        >
          {placeholder}
        </label>
      )}
      {icon ? (
        <div className='absolute inset-y-0 right-0 flex items-center px-4 cursor-pointer mb-[20px]'>
          <ErplyIcon
            data-testid={iconTestid}
            icon={icon}
            className='dark:text-white text-gray-900'
            onClick={onIconClick}
          />
        </div>
      ) : null}
    </div>
  );
};

export default TextInput;
