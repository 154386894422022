import React from "react";
import type { RouteObject } from "react-router-dom";
import { Navigate, useRoutes } from "react-router-dom";
import App from "./App";
import SSOPage from "./views/SSOPage";
import ConfirmEmailEnrol from "./views/ConfirmEmailEnrol";
import ConfirmPasswordReset from "./views/ConfirmPasswordReset";
import DemoAccountPage from "./views/DemoAccountPage";
import HomePage from "./views/HomePage";
import ResetPasswordPage from "./views/ResetPasswordPage";
import SignUpPage from "./views/SignUpPage";
import TwoFactorPage from "./views/TwoFactorPage";
import AccountSwitchPage from "./views/AccountSwitchPage";
import ApplicationPage from "./views/ApplicationPage";
import AuthenticatedPage from "./views/AuthenticatedPage";
import PasswordSettingPage from "./views/PasswordSettingPage";
import TwoFactorSettingsPage from "./views/TwoFactorSettingsPage";
// import SupportPage from "./views/SupportPage";

const routes: RouteObject[] = [
  {
    path: "",
    element: <App />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "sign-up",
        element: <SignUpPage />,
      },
      {
        path: "demo-account",
        element: <DemoAccountPage />,
      },
      {
        path: "reset-password",
        element: <ResetPasswordPage />,
      },
      {
        path: "two-factor",
        element: <TwoFactorPage />,
      },
      {
        path: "auth-response",
        element: <SSOPage />,
      },

      {
        path: "confirm-password-reset",
        element: <ConfirmPasswordReset />,
      },

      {
        path: "confirm-two-factor",
        element: <ConfirmEmailEnrol />,
      },

      {
        path: "switch-account/:clientCode",
        element: <AccountSwitchPage />,
      },

      {
        path: ":clientCode",
        element: <AuthenticatedPage />,
        children: [
          {
            path: "apps",
            element: <ApplicationPage />,
          },
          {
            path: "password",
            element: <PasswordSettingPage />,
          },
          {
            path: "two-step-auth",
            element: <TwoFactorSettingsPage />,
          },

          // {
          //   path: "support",
          //   element: <SupportPage />,
          // },

          { path: "*", element: <Navigate to='apps' /> },
        ],
      },

      { path: "*", element: <Navigate to='/' /> },
    ],
  },
];

const AppRouter: React.FC = () => useRoutes(routes);

export default AppRouter;
