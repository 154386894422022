import React from "react";
import useMatchMediaMatches from "../../hooks/useMatchMediaMatches";
import SideLayout from "./SideLayout";

interface MainLayoutProps {
  isSignUp?: boolean;
  children: React.ReactNode;
}

// lg:absolute lg:top-1/2 lg:left-1/2 lg:transform lg:-translate-x-1/2 lg:-translate-y-1/2

const MainLayout: React.FC<MainLayoutProps> = ({ isSignUp, children }) => {
  // const isMoblie = useMediaQuery({ maxWidth: 500 });
  const isDesktopOrLaptop =
    useMatchMediaMatches("(min-width: 1224px)") === true;
  return (
    <main
      className={
        isDesktopOrLaptop
          ? "lg:absolute lg:left-1/2 lg:-translate-x-1/2 lg:-translate-y-1/2 lg:top-1/2 lg:transform"
          : "h-screen"
      }
    >
      <div
        className={
          isDesktopOrLaptop
            ? "md:flex block w-full"
            : "md:flex block w-full min-h-screen"
        }
        style={isDesktopOrLaptop ? { width: "1000px" } : {}}
      >
        <SideLayout isSignUp={isSignUp} />
        {children}
      </div>
    </main>
  );
};

export default MainLayout;
