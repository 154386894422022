import React from "react";
import MainLayout from "../components/layouts/MainLayout";
import DemoAccount from "../components/sections/CreateAccount/DemoAccount";

const DemoAccountPage: React.FC = () => {
  return (
    <MainLayout>
      <DemoAccount />
    </MainLayout>
  );
};

export default DemoAccountPage;
