import React, { useState, useEffect } from "react";
import { noAccountError, useAuthState } from "../../../context/auth";
import Server from "../../../service/Server";
import Button from "../../elements/Button";
import TextInput from "../../elements/TextInput";
import { iNotification } from "react-notifications-component";
import { notify } from "../../../utils";
import classNames from "classnames";

interface SSOLoginProps {
  // setLoading: (value: React.SetStateAction<boolean>) => void;
}

const SSOLogin: React.FC<SSOLoginProps> = () => {
  const [clientCode, setCode] = useState("");
  const [errors, setErrors] = useState({
    accountNumber: "",
  });
  const [isLoading, setLoading] = useState(false);

  const {
    clientCode: userCode,
    session,
    sessionKey,
    isAuthenticated,
    handleChange,
    origin,
    target,
    setAccountAdminBaseUrl,
    setUserAPIBaseUrl,
  } = useAuthState();

  useEffect(() => {
    setCode(userCode);
  }, [userCode]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;

    if (name === "accountNumber") {
      if (value) {
        setErrors({ ...errors, [name]: "" });
      } else {
        setErrors({ ...errors, [name]: "Account number is required" });
      }
    }

    setCode(value);
  };

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    if (isErrorInData()) return;
    setLoading(true);
    localStorage.setItem("SSO_CLIENT", clientCode);
    localStorage.setItem(`${clientCode}_TARGET`, target);

    try {
      const { data } = await Server.getServiceEndPoint({
        clientCode,
        request: "getServiceEndpoints",
      });
      if (data.status.responseStatus === "error") {
        notify({
          message: noAccountError(),
          type: "danger",
        } as iNotification);
      } else {
        const url: string = data.records[0].auth.url
          ? data.records[0].auth.url
          : "";
        const accAdminBaseUrl: string = data.records[0]["account-admin"].url
          ? data.records[0]["account-admin"].url
          : "";
        setAccountAdminBaseUrl(accAdminBaseUrl);
        const userBaseUrl: string = data.records[0]["user"].url
          ? data.records[0]["user"].url
          : "";
        setUserAPIBaseUrl(userBaseUrl);
        handleChange({
          session,
          clientCode,
          baseUrl: url,
          isAuthenticated,
          sessionKey,
        });
        getSSORedirectURL(url);
      }
    } catch (error) {
      notify({
        message: noAccountError(),
        type: "danger",
      } as iNotification);
      setLoading(false);
    }
  };

  const getSSORedirectURL = async (url: string): Promise<void> => {
    try {
      const {
        data: {
          data: { uri },
        },
      } = await Server.getSSORedirectUrl({
        baseUrl: url,
        clientCode,
        redirectUri: `${origin}/auth-response`,
      });

      window.location.href = uri;

      setLoading(false);
    } catch (err: any) {
      if (err.response) {
        const {
          response: { data },
        } = err;
        if (data) {
          notify({
            message: `${data.status.errorDescription}`,
            type: "danger",
          } as iNotification);
        } else {
          notify({
            message: "Error performing operation. Please try again",
            type: "danger",
          } as iNotification);
        }
      } else {
        notify({
          message: "Error performing operation. Please try again",
          type: "danger",
        } as iNotification);
      }
      setLoading(false);
    }
  };

  const isErrorInData = (): boolean => {
    let isError = false;
    const errorsObject = {
      accountNumber: "",
    };

    if (!clientCode) {
      isError = true;
      errorsObject.accountNumber = "Account number is required";
    }

    if (isError) {
      setErrors({ ...errors, ...errorsObject });
    }

    return isError;
  };

  const btnCls = classNames(
    "dark:bg-white btn-block bg-erply-success-200 dark:text-gray-900 text-white dark:hover:text-gray-900 dark:hover:bg-[#E6E6E6] hover:bg-[#4AD17A] active:bg-[#35A95F] dark:active:bg-erply-dark-100 border-0",
    isLoading && "btn-disabled loading"
  );

  return (
    <form onSubmit={handleSubmit} className='w-80 sm:w-96'>
      <TextInput
        name='accountNumber'
        placeholder='Account number'
        value={clientCode}
        onChange={onChange}
        error={!!errors.accountNumber}
        className='mb-[30px]'
        data-testid='account-number'
        required
      />

      <Button
        className={btnCls}
        type='submit'
        disabled={isLoading}
        data-testid='login-button'
      >
        Login
      </Button>
    </form>
  );
};

export default SSOLogin;
