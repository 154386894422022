import React, { createContext, useState, useContext, useEffect } from "react";
import { AppType, Layout, StateI, UserSessionI } from "../types/index";
import Server from "../service/Server";
import { useNavigate } from "react-router-dom";
import useTheme, { Mode } from "../hooks/useTheme";
import { notify, removeFirstAndLastQuotes } from "../utils";
import { iNotification } from "react-notifications-component";

interface StateValueI {
  session: UserSessionI | null;
  clientCode: string;
  baseUrl: string;
  sessionKey: string;
  isAuthenticated: boolean;
  username?: string;
}

interface ContextI extends StateI {
  handleChange: (value: StateValueI) => void;
  target: string;
  origin: string;
  tokenHash: string;
  setHash: (value: React.SetStateAction<string>) => void;
  accAdminBaseUrl: string;
  userAPIBaseUrl: string;
  setAccountAdminBaseUrl: (value: React.SetStateAction<string>) => void;
  setUserAPIBaseUrl: (value: React.SetStateAction<string>) => void;
  hideSignUp: boolean;
  isDarkTheme: boolean;
  handleLogout: () => Promise<void>;
  mode: Mode;
  setMode: React.Dispatch<React.SetStateAction<Mode>>;
  appType: AppType;
  layout: Layout;
  loginAttemptCount: number;
  setLoginAttemptCount: React.Dispatch<React.SetStateAction<number>>;
}

const initialState: ContextI = {
  session: null,
  clientCode: "",
  username: "",
  handleChange: () => null,
  target: "",
  origin: "",
  baseUrl: "",
  sessionKey: "",
  isAuthenticated: false,
  tokenHash: "",
  setHash: () => null,
  accAdminBaseUrl: "",
  userAPIBaseUrl: "",
  setAccountAdminBaseUrl: () => {},
  setUserAPIBaseUrl: () => {},
  hideSignUp: false,
  isDarkTheme: false,
  handleLogout: async () => {},
  mode: "system",
  setMode: () => {},
  appType: "generic",
  layout: "generic",
  loginAttemptCount: 0,
  setLoginAttemptCount: () => {},
};

const StateContext = createContext<ContextI>(initialState);

export const noAccountError = () => (
  <div className='flex flex-col gap-2'>
    <span>
      This account is currently unreachable. Please wait 10 minutes to see if
      the issue resolves automatically. If it does not, please contact Erply
      support team.
    </span>
    <span>
      Please also check that you have entered your account number correctly.
    </span>
    <span>
      If your account has been inactive for an extended period, it has been
      archived in accordance with Erply Terms of Service. Your data is available
      upon request.
    </span>
  </div>
);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, setState] = useState<StateI>({
    session: null,
    clientCode: "",
    baseUrl: "",
    sessionKey: "",
    isAuthenticated: false,
    username: "",
  });
  const [target, setTarget] = useState("");
  const [origin, setOrigin] = useState("");
  const [tokenHash, setHash] = useState("");
  const [accAdminBaseUrl, setAccountAdminBaseUrl] = useState("");
  const [userAPIBaseUrl, setUserAPIBaseUrl] = useState("");
  const [hideSignUp, setHideSignUp] = useState(false);
  const [appType, setAppType] = useState<AppType>("generic");
  const [layout, setLayout] = useState<Layout>("generic");
  const [loginAttemptCount, setLoginAttemptCount] = useState(0);

  const { mode, setMode, theme } = useTheme();

  const navigate = useNavigate();

  useEffect(() => {
    let urlSearchParams = new URLSearchParams(window.location.search);
    let target = urlSearchParams.get("target");
    let clientCode = urlSearchParams.get("clientCode");
    let isLoginAgain = urlSearchParams.get("isLoginAgain"); // boolean flag to login again for POS
    let hideSignUp = urlSearchParams.get("hideSignUp"); // boolean flag to disable signup for POS
    let appType = urlSearchParams.get("appType"); // used to determine side layout design
    let layout = urlSearchParams.get("layout"); // used to determine form layout design
    let origin = window.location.origin.trim();
    // let CLIENT = localStorage.getItem("CLIENT");
    let SSO_CLIENT = localStorage.getItem("SSO_CLIENT");

    setOrigin(origin);

    if (target) {
      setTarget(target);
      // if (target.includes("{CLIENTCODE}")) {
      //   console.log(target.replace("{CLIENTCODE}", "11000"));
      //   target = target.split("{CLIENTCODE}")[0];
      //   setTarget(target);
      // } else {
      //   setTarget(target);
      // }
    }

    if (appType) {
      setAppType(removeFirstAndLastQuotes(appType.trim()) as AppType);
    }

    if (layout) {
      setLayout(removeFirstAndLastQuotes(layout.trim()) as Layout);
    }

    if (hideSignUp) {
      const disabled = JSON.parse(hideSignUp.trim()) as boolean;
      setHideSignUp(disabled);
    }

    if (isLoginAgain) {
      if (!JSON.parse(isLoginAgain.trim())) {
        if (clientCode) {
          getCurrentSession(clientCode);
          return;
        } else if (SSO_CLIENT) {
          getCurrentSession(SSO_CLIENT);
        }
      }
    } else {
      if (clientCode) {
        getCurrentSession(clientCode);
        return;
      } else if (SSO_CLIENT) {
        getCurrentSession(SSO_CLIENT);
      }
    }

    // eslint-disable-next-line
  }, []);

  const handleChange = (value: StateValueI): void => {
    setState({
      ...state,
      session: value.session,
      clientCode: value.clientCode,
      baseUrl: value.baseUrl,
      sessionKey: value.sessionKey,
      isAuthenticated: value.isAuthenticated,
      username: value.username ? value.username : state.username,
    });
  };

  const getCurrentSession = async (clientCode: string): Promise<void> => {
    setState({ ...state, clientCode });
    try {
      const { data } = await Server.getServiceEndPoint({
        clientCode,
        request: "getServiceEndpoints",
      });
      if (data.status.responseStatus === "error") {
        console.log("error");
      } else {
        const baseUrl: string = data.records[0].auth.url
          ? data.records[0].auth.url
          : "";
        const adminBaseUrl: string = data.records[0]["account-admin"].url
          ? data.records[0]["account-admin"].url
          : "";
        const userBaseUrl: string = data.records[0]["user"].url
          ? data.records[0]["user"].url
          : "";
        setUserAPIBaseUrl(userBaseUrl);
        setAccountAdminBaseUrl(adminBaseUrl);

        try {
          const { data } = await Server.getSession({ baseUrl, clientCode });
          handleChange({
            session: data.data,
            clientCode,
            baseUrl,
            sessionKey: data.data.session?.sessionKey
              ? data.data.session?.sessionKey
              : "",
            isAuthenticated: true,
          });
          navigate(`/${clientCode}/apps`);
        } catch (error) {}
      }
    } catch (error) {}
  };

  const handleLogout = async () => {
    try {
      await Server.logout({
        baseUrl: state.baseUrl,
        clientCode: state.clientCode,
        sessionKey: state.sessionKey,
      });
      handleChange({
        session: null,
        clientCode: state.clientCode,
        sessionKey: "",
        isAuthenticated: false,
        baseUrl: state.baseUrl,
      });
      navigate("/");
    } catch (err: any) {
      if (err.response) {
        if (err.response?.status === 403) {
          navigate("/");
        } else {
          const {
            response: { data },
          } = err;
          if (data) {
            if (data.status.errorCode === "UNAUTHENTICATED") {
              navigate("/");
            } else {
              notify({
                message: `${data.status.errorDescription}`,
                type: "danger",
              } as iNotification);
            }
          } else {
            notify({
              message: "Error performing operation. Please try again",
              type: "danger",
            } as iNotification);
          }
        }
      } else {
        notify({
          message: "Error performing operation. Please try again",
          type: "danger",
        } as iNotification);
      }
    }
  };

  return (
    <StateContext.Provider
      value={{
        ...state,
        handleChange,
        target,
        origin,
        tokenHash,
        setHash,
        accAdminBaseUrl,
        userAPIBaseUrl,
        setAccountAdminBaseUrl,
        setUserAPIBaseUrl,
        hideSignUp,
        isDarkTheme: theme === "dark",
        handleLogout,
        mode,
        setMode,
        appType,
        layout,
        loginAttemptCount,
        setLoginAttemptCount,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useAuthState = () => useContext(StateContext);
