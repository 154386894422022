import React from "react";
import Spinner from "../elements/Spinner";
import logo from "../../assets/Erply-logo-new.svg";
import useMatchMediaMatches from "../../hooks/useMatchMediaMatches";

interface FormLayoutProps {
  title?: string;
  isLoading?: boolean;
  showSpinner?: boolean;
  isSignUp?: boolean;
  testIdForm?: string;
  testIdFormHeader?: string;
  [x: string]: any;
}

const FormLayout: React.FC<FormLayoutProps> = ({
  title,
  isLoading = false,
  showSpinner = false,
  isSignUp = false,
  testIdForm,
  testIdFormHeader,
  children,
  ...props
}) => {
  const isDesktopOrLaptop =
    useMatchMediaMatches("(min-width: 1224px)") === true;
  return (
    <>
      <div className='bg-erply-dark-50 w-full h-full py-6 flex justify-center lg:hidden md:hidden'>
        <img src={logo} alt='logo' />
      </div>

      <div
        className={
          isDesktopOrLaptop
            ? "flex flex-col items-center justify-start space-y-3  dark:bg-erply-dark-50 bg-white lg:rounded-r-[20px] w-full  pb-14 lg:h-auto dark:border-0 lg:border lg:shadow-md sm:pt-[99px] "
            : "flex flex-col items-center justify-start space-y-3  dark:bg-erply-dark-50 bg-white lg:rounded-r-[20px] w-full  pb-14 lg:h-auto min-h-screen dark:border-0 lg:border lg:shadow-md sm:pt-[99px] "
        }
        data-testid={testIdForm}
        {...props}
      >
        {showSpinner ? <Spinner /> : null}
        {title ? (
          <span
            className='dark:text-white font-[140] text-[24px] leading-[20px] p-0 mb-[20px] sm:pt-0 mt-3'
            data-testid={testIdFormHeader}
          >
            {title}
          </span>
        ) : null}
        <div className='text-white'>{children}</div>
      </div>
    </>
  );
};

export default FormLayout;

// md:landscape:mt-0
