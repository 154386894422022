import React, { useState } from "react";
import { TwoFactorRequestI } from "../../../types/index";
import { notify } from "../../../utils";
import { iNotification } from "react-notifications-component";
import { useAuthState } from "../../../context/auth";
import Server from "../../../service/Server";
import Spinner from "../../elements/Spinner";
import HelperText from "../../elements/HelperText";
import TextInput from "../../elements/TextInput";
import Modal from "../../elements/Modal";
import Button from "../../elements/Button";
import classNames from "classnames";

interface EmailSettingsModalProps {
  onClose: () => void;
  getSettings: () => Promise<void>;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  email: string;
  isOpen: boolean;
}

const EmailSettingsModal: React.FC<EmailSettingsModalProps> = ({
  onChange,
  getSettings,
  onClose,
  email,
  isOpen,
}) => {
  const [isToken, setIsToken] = useState(false);
  const [twoFactorKey, setToken] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [twoFactorToken, setTwoFactorToken] = useState("");

  const { baseUrl, clientCode, sessionKey } = useAuthState();

  const handleClose = () => {
    setIsToken(false);
    setToken("");
    setLoading(false);
    setTwoFactorToken("");
    onClose();
  };

  const saveEmail = async () => {
    if (!email) return;
    try {
      setLoading(true);
      const request = {
        baseUrl,
        clientCode,
        sessionKey,
        email,
      };
      const { data } = await Server.setTwoFactorEmail(request);
      setTwoFactorToken(data.data.twoFactorToken);
      notify({
        message: `Confirmation code have been sent to your email`,
        type: "success",
      } as iNotification);
      setLoading(false);
      setIsToken(true);
    } catch (err: any) {
      if (err.response) {
        const {
          response: { data },
        } = err;
        if (data) {
          notify({
            message: `${data.status.errorDescription}`,
            type: "danger",
          } as iNotification);
        } else {
          notify({
            message: "Error performing operation. Please try again",
            type: "danger",
          } as iNotification);
        }
      } else {
        notify({
          message: "Error performing operation. Please try again",
          type: "danger",
        } as iNotification);
      }
      setLoading(false);
    }
  };

  const confirmEmail = async () => {
    if (!twoFactorKey) return;
    try {
      setLoading(true);
      const requestData: TwoFactorRequestI = {
        value: {
          twoFactorKey,
          twoFactorToken,
        },
        baseUrl,
        clientCode,
        sessionKey,
      };
      await Server.confirmEmail(requestData);
      notify({
        message: `Email updated successfully`,
        type: "success",
      } as iNotification);
      setLoading(false);
      getSettings();
      setIsToken(false);
      handleClose();
    } catch (err: any) {
      if (err.response) {
        const {
          response: { data },
        } = err;
        if (data) {
          notify({
            message: `${data.status.errorDescription}`,
            type: "danger",
          } as iNotification);
        } else {
          notify({
            message: "Error performing operation. Please try again",
            type: "danger",
          } as iNotification);
        }
      } else {
        notify({
          message: "Error performing operation. Please try again",
          type: "danger",
        } as iNotification);
      }
      setLoading(false);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isToken) {
      confirmEmail();
    } else {
      saveEmail();
    }
  };

  const renderFormType = () => {
    if (isToken) {
      return (
        <form onSubmit={handleSubmit}>
          <HelperText message={`Enter the code sent to your email`} />
          <div className='mt-5'>
            <TextInput
              placeholder='Confirmation token'
              value={twoFactorKey}
              name='token'
              required
              onChange={(e) => setToken(e.target.value)}
              data-testid='email-add-channel-confirmation-token'
              autoFocus
            />
          </div>
        </form>
      );
    }

    return (
      <form onSubmit={handleSubmit}>
        <HelperText
          message={`Set up an email for "Email" two factor channel`}
        />
        <div className='mt-5'>
          <TextInput
            placeholder='Email'
            name='email'
            value={email}
            type='email'
            required
            onChange={onChange}
            data-testid='email-add-channel-email'
            autoFocus
          />
        </div>
      </form>
    );
  };

  const btnCls = classNames(
    "dark:bg-white btn-block bg-erply-success-200 dark:text-gray-900 text-white border-0 dark:hover:text-gray-900 dark:hover:bg-[#E6E6E6] hover:bg-[#4AD17A] active:bg-[#35A95F] dark:active:bg-erply-dark-100",
    isLoading && "btn-disabled loading"
  );

  return (
    <Modal
      isOpen={isOpen}
      title='Email Setting'
      onClose={handleClose}
      testId='email-add-channel-modal'
    >
      <div className='py-4'>
        {isLoading ? (
          <div className='flex justify-center'>
            <Spinner />
          </div>
        ) : null}
        {renderFormType()}
      </div>
      <div className='modal-action justify-center space-x-3'>
        <div>
          <Button
            className='btn-block bg-erply-dark-20 border-0 text-white dark:text-gray-900 dark:hover:text-white'
            onClick={handleClose}
            data-testid='email-add-channel-cancel-button'
          >
            Cancel
          </Button>
        </div>
        {isToken ? (
          <div>
            <Button
              className={btnCls}
              onClick={confirmEmail}
              data-testid='email-add-channel-confirm-token-button'
            >
              Confirm Token
            </Button>
          </div>
        ) : (
          <div>
            <Button
              className={btnCls}
              onClick={saveEmail}
              data-testid='email-add-channel-set-email-button'
            >
              Set Email
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default EmailSettingsModal;
