import React from "react";
import { useAuthState } from "../../../context/auth";
import { AppNames } from "../../../types";
import { getAppInfo } from "../../../utils";
import { useAuthenticatedContext } from "../../../views/AuthenticatedPage";
import AppCard from "../../elements/AppCard";
import Carousel from "../../elements/Carousel";
import Spinner from "../../elements/Spinner";

// md:w-4/5 ml-auto mr-auto px-4 mb-5

const Applications: React.FC = () => {
  const { isDarkTheme, clientCode, session } = useAuthState();

  const { banners, appConfigs, isFetchingConfig } = useAuthenticatedContext();

  // console.log("banners", banners);

  return (
    <div
      className='flex items-center flex-col mt-14'
      data-testid='applications-form'
    >
      <div className='text-center'>
        <h1
          className='text-xl font-bold mb-2'
          data-testid='applications-header-text'
        >
          Welcome back, select your app
        </h1>
        <small>
          You can always choose different one in another browser tab
        </small>
      </div>

      {isFetchingConfig ? (
        <div className='flex flex-col items-center mt-12'>
          <Spinner />
        </div>
      ) : (
        <>
          <div className='flex flex-col space-y-4 mt-12'>
            {!appConfigs ||
            (appConfigs && !appConfigs.value.length) ||
            !appConfigs?.value ? (
              <>
                <AppCard
                  title='Back Office'
                  description='Full-Lifecycle Warehouse and Product Management'
                  type='backoffice'
                  isDarkTheme={isDarkTheme}
                  icon='Office'
                  href={session?.backOfficeURL || ""}
                />
                <AppCard
                  title='Point Of Sale'
                  description={`Cloud-based POS with Integrated Payments and Offline Support`}
                  type='pointofsale'
                  isDarkTheme={isDarkTheme}
                  icon='POS-Terminal'
                  href={`${session?.brazilPosURL}?clientCode=${clientCode}&auth=cookie`}
                />
                <AppCard
                  title='Service Booking'
                  description={`All-in-one Service Shop Software with Work Orders and Booking Calendars`}
                  type='servicebooking'
                  isDarkTheme={isDarkTheme}
                  icon='Calendar'
                  href={`${session?.serviceAppURL}?clientCode=${clientCode}&auth=cookie`}
                />
                <AppCard
                  title='Retry CRM'
                  description={`Manage incoming mail and assign tasks`}
                  type='retrycrm'
                  isDarkTheme={isDarkTheme}
                  icon='Person-CRM'
                  href={`https://ems.retry.com/`}
                />
              </>
            ) : (
              appConfigs.value.map((val) => {
                const appInfo = getAppInfo(
                  val as AppNames,
                  clientCode,
                  session
                );

                if (!appInfo) {
                  return null;
                }

                return (
                  <AppCard
                    key={val}
                    title={appInfo.title}
                    description={appInfo.description}
                    type={appInfo.type}
                    isDarkTheme={isDarkTheme}
                    icon={appInfo.icon}
                    href={appInfo.href}
                  />
                );
              })
            )}
          </div>
          <div className='lg:w-[600px] md:w-[450px] sm:mt-4 mt-28'>
            <Carousel banners={banners} />
          </div>
        </>
      )}
    </div>
  );
};

export default Applications;
