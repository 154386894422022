import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FormLayout from "../components/layouts/FormLayout";
import MainLayout from "../components/layouts/MainLayout";
import Server from "../service/Server";
import { notify } from "../utils";
import { iNotification } from "react-notifications-component";
import { ResetPasswordRequestI } from "../types/index";
import Button from "../components/elements/Button";
import TextInput from "../components/elements/TextInput";
import classNames from "classnames";
import ErplyIcon from "../components/elements/ErplyIcon";
import { noAccountError } from "../context/auth";

const ConfirmPasswordReset: React.FC = () => {
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [resetCode, setResetCode] = useState("");
  const [clientCode, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [token, setToken] = useState("");
  const [baseUrl, setBaseUrl] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    let urlSearchParams = new URLSearchParams(window.location.search);
    let clientCode = urlSearchParams.get("clientCode");
    let key = urlSearchParams.get("key");
    let token = urlSearchParams.get("token");

    if (token && clientCode && key) {
      getServiceEndpoints(clientCode, key, token);
    } else {
      navigate("/");
    }

    // eslint-disable-next-line
  }, []);

  const getServiceEndpoints = async (
    clientCode: string,
    key: string,
    token: string
  ): Promise<void> => {
    setLoading(true);
    try {
      const { data } = await Server.getServiceEndPoint({
        clientCode,
        request: "getServiceEndpoints",
      });
      if (data.status.responseStatus === "error") {
        notify({
          message: noAccountError(),
          type: "danger",
        } as iNotification);
        setLoading(false);
        setError(true);
      } else {
        const baseUrl: string = data.records[0].auth.url
          ? data.records[0].auth.url
          : "";
        setBaseUrl(baseUrl);
        setCode(clientCode);
        setResetCode(key);
        setToken(token);
        setLoading(false);
        setError(false);
      }
    } catch (err: any) {
      if (err.response) {
        const {
          response: { data },
        } = err;
        if (data) {
          notify({
            message: `${data.status.errorDescription}`,
            type: "danger",
          } as iNotification);
        } else {
          notify({
            message: noAccountError(),
            type: "danger",
          } as iNotification);
        }
      } else {
        notify({
          message: noAccountError(),
          type: "danger",
        } as iNotification);
      }
      setLoading(false);
      setError(true);
    }
  };

  const resetPassword = async (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    event.preventDefault();
    try {
      const requestData: ResetPasswordRequestI = {
        value: {
          token,
          key: resetCode,
          newPassword,
        },
        baseUrl,
        clientCode,
      };
      await Server.resetPassword(requestData);
      setLoading(false);
      setError(false);
      setResetCode("");
      setNewPassword("");
      setBaseUrl("");
      setCode("");
      setToken("");
      notify({
        message: `Password reset successful`,
        type: "success",
      } as iNotification);
      navigate("/");
    } catch (err: any) {
      if (err.response) {
        const {
          response: { data },
        } = err;
        if (data) {
          notify({
            message: `${data.status.errorDescription}`,
            type: "danger",
          } as iNotification);
        } else {
          notify({
            message: "Error performing operation. Please try again",
            type: "danger",
          } as iNotification);
        }
      } else {
        notify({
          message: "Error performing operation. Please try again",
          type: "danger",
        } as iNotification);
      }
      setLoading(false);
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    if (name === "resetCode") {
      setResetCode(value);
    }

    if (name === "newPassword") {
      setNewPassword(value);
    }
  };

  const btnCls = classNames(
    "dark:bg-white btn-block bg-erply-success-200 dark:text-gray-900 text-white border-0 dark:hover:text-gray-900 dark:hover:bg-[#E6E6E6] hover:bg-[#4AD17A] active:bg-[#35A95F] dark:active:bg-erply-dark-100",
    isLoading && "btn-disabled loading"
  );

  return (
    <MainLayout>
      <FormLayout showSpinner={isLoading} title='Reset Password'>
        <>
          {isError ? (
            <div className='flex sm:w-96 w-80'>
              <Button
                className='btn-block gap-2'
                onClick={() => {
                  setError(false);
                  navigate("/");
                }}
              >
                <ErplyIcon icon='Arrow-Left' />
                Back
              </Button>
            </div>
          ) : (
            <form className='sm:w-96 w-80' onSubmit={resetPassword}>
              <TextInput
                className='mb-5'
                name='resetCode'
                placeholder='Reset Code'
                autoComplete='off'
                value={resetCode}
                onChange={onChange}
                required
              />
              <TextInput
                type='password'
                className='mb-[30px]'
                name='newPassword'
                placeholder='New Password'
                autoComplete='off'
                value={newPassword}
                onChange={onChange}
                required
              />
              <div className='flex space-x-5'>
                <div>
                  <Button
                    className='btn-block bg-erply-dark-20 border-0 text-white dark:text-gray-900 dark:hover:text-white'
                    onClick={() => {
                      setError(false);
                      navigate("/");
                    }}
                    type='button'
                  >
                    <ErplyIcon icon='Arrow-Left' />
                  </Button>
                </div>
                <div className='w-full'>
                  <Button disabled={isLoading} className={btnCls} type='submit'>
                    Reset Password
                  </Button>
                </div>
              </div>
            </form>
          )}
        </>
      </FormLayout>
    </MainLayout>
  );
};

export default ConfirmPasswordReset;
