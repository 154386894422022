import React from "react";
import Button from "../elements/Button";
import { loginOptions } from "../../types";
import ErplyIcon from "../elements/ErplyIcon";

interface LoginTypeSelectorProps {
  loginType: loginOptions;
  setLoginType: React.Dispatch<React.SetStateAction<loginOptions>>;
}

const LoginTypeSelector: React.FC<LoginTypeSelectorProps> = ({
  loginType,
  setLoginType,
}) => {
  return (
    <div className='flex space-x-3 mb-[20px]'>
      <div className='w-full'>
        <Button
          className={
            loginType === "username"
              ? "dark:bg-erply-dark-200 bg-erply-light-50 btn-block border-erply-light-200 hover:border-erply-light-200 dark:hover:border-2 dark:text-white text-gray-800 hover:text-white hover:border-0 border-2 sm:gap-1"
              : "dark:bg-erply-dark-200 bg-erply-light-50 btn-block border-0 dark:hover:border-erply-light-200 hover:border-2 hover:text-white dark:text-white text-gray-800 dark:hover:border-2 sm:gap-1"
          }
          onClick={() => setLoginType("username")}
          data-testid='username-login-type'
        >
          <ErplyIcon icon='Person-3-Default' />
          User name
        </Button>
      </div>
      <div className='w-full'>
        <Button
          className={
            loginType === "SSO"
              ? "dark:bg-erply-dark-200 bg-erply-light-50 btn-block border-erply-light-200 hover:border-erply-light-200 dark:hover:border-2 dark:text-white text-gray-800 hover:text-white hover:border-0 gap-1 border-2 sm:gap-1"
              : "dark:bg-erply-dark-200 bg-erply-light-50 btn-block border-0 dark:hover:border-erply-light-200 hover:border-2 hover:text-white dark:text-white text-gray-800 dark:hover:border-2 sm:gap-1"
          }
          onClick={() => setLoginType("SSO")}
          data-testid='sso-login-type'
        >
          <ErplyIcon icon='Key' />
          SSO
        </Button>
      </div>
    </div>
  );
};

export default LoginTypeSelector;
