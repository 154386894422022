import { requestERPLYData } from "../utils";
import {
  ErplyRequestData,
  SessionResponseI,
  SessionRequestI,
  UpdatePasswordRequestI,
  ChannelResponseI,
  ChannelRequestI,
  TwoFactorRequestI,
  GetResetPasswordTokenResponseI,
  ResetPasswordRequestI,
  SSORedirectURLResponseI,
  SSOValidateRequestI,
  SettingsResponseI,
  AppAuthenticationResponseI,
  SetEmailResponseI,
  GetLoginSettingsResponseI,
  FranchaiseAccountsResponseI,
  GetBannerResponseI,
  RenewPasswordRequestI,
  GetCAFAConfigResponseI,
  ConfigName,
} from "../types/index";
import axios from "axios";

export const partnerKey = "7c3ce29ce592541adaefa003f4ce8d1eddb63430";

const Server = {
  getServiceEndPoint: async (requestData: ErplyRequestData) => {
    return await requestERPLYData({ ...requestData, partnerKey });
  },

  getCompanyInfo: async (requestData: ErplyRequestData) => {
    return await requestERPLYData({ ...requestData, partnerKey });
  },

  getSession: async (requestData: { baseUrl: string; clientCode: string }) => {
    const { baseUrl, clientCode } = requestData;
    return await axios.get<SessionResponseI>(`${baseUrl}v2/session`, {
      headers: {
        clientCode,
      },
      withCredentials: true,
    });
  },

  createSession: async (requestData: SessionRequestI) => {
    const { value, baseUrl, clientCode } = requestData;
    return await axios.post<SessionResponseI>(`${baseUrl}v1/session`, value, {
      headers: { clientCode },
      withCredentials: true,
    });
  },

  updatePassword: async (requestData: UpdatePasswordRequestI) => {
    const { value, baseUrl, clientCode, sessionKey } = requestData;
    return await axios.put(`${baseUrl}v1/user/change-password`, value, {
      headers: { clientCode, sessionKey },
    });
  },

  renewPassword: async (requestData: RenewPasswordRequestI) => {
    const { value, baseUrl, clientCode } = requestData;
    return await axios.post<SessionResponseI>(
      `${baseUrl}v1/session/renew-password `,
      value,
      {
        headers: { clientCode },
        withCredentials: true,
      }
    );
  },

  setChannel: async (requestData: ChannelRequestI) => {
    const { value, baseUrl, clientCode } = requestData;
    return await axios.patch<ChannelResponseI>(
      `${baseUrl}v1/two-factor/select-channel`,
      value,
      {
        headers: { clientCode },
      }
    );
  },

  twoFactorAuth: async (requestData: TwoFactorRequestI) => {
    const { value, baseUrl, clientCode } = requestData;
    return await axios.post<SessionResponseI>(
      `${baseUrl}v1/two-factor`,
      value,
      {
        headers: { clientCode },
        withCredentials: true,
      }
    );
  },

  getRestPasswordToken: async (requestData: {
    baseUrl: string;
    clientCode: string;
    user: string;
  }) => {
    const { baseUrl, clientCode, user } = requestData;
    return await axios.get<GetResetPasswordTokenResponseI>(
      `${baseUrl}v1/user/reset-password`,
      {
        headers: { clientCode },
        params: { user },
      }
    );
  },

  resetPassword: async (requestData: ResetPasswordRequestI) => {
    const { value, baseUrl, clientCode } = requestData;
    return await axios.post(`${baseUrl}v1/user/reset-password`, value, {
      headers: { clientCode },
    });
  },

  getSSORedirectUrl: async (requestData: {
    baseUrl: string;
    clientCode: string;
    redirectUri: string;
  }) => {
    const { baseUrl, clientCode, redirectUri } = requestData;
    return await axios.get<SSORedirectURLResponseI>(
      `${baseUrl}v1/sso/redirect-uri`,
      {
        headers: { clientCode },
        params: { redirectUri },
      }
    );
  },

  validateSSOResponse: async (requestData: SSOValidateRequestI) => {
    const { value, baseUrl, clientCode } = requestData;
    return await axios.post<SessionResponseI>(
      `${baseUrl}v1/sso/validate-token-id`,
      value,
      {
        headers: { clientCode },
        withCredentials: true,
      }
    );
  },

  getSessionKeyUser: async (requestData: ErplyRequestData) => {
    return await requestERPLYData({ ...requestData, partnerKey });
  },

  logout: async (requestData: {
    baseUrl: string;
    clientCode: string;
    sessionKey: string;
  }) => {
    const { baseUrl, clientCode, sessionKey } = requestData;
    return await axios.delete(`${baseUrl}v1/session`, {
      headers: { clientCode, sessionKey },
    });
  },

  setTwoFactorEmail: async (requestData: {
    baseUrl: string;
    clientCode: string;
    sessionKey: string;
    email: string;
  }) => {
    const { baseUrl, clientCode, sessionKey, email } = requestData;
    return await axios.post<SetEmailResponseI>(
      `${baseUrl}v1/user/two-factor-email`,
      { email },
      {
        headers: { clientCode, sessionKey },
      }
    );
  },

  confirmEmail: async (requestData: TwoFactorRequestI) => {
    const { value, baseUrl, clientCode, sessionKey } = requestData;
    return await axios.patch(`${baseUrl}v1/user/two-factor-email`, value, {
      headers: { clientCode, sessionKey },
    });
  },

  getSettings: async (requestData: {
    baseUrl: string;
    clientCode: string;
    sessionKey: string;
  }) => {
    const { baseUrl, clientCode, sessionKey } = requestData;
    return await axios.get<SettingsResponseI>(`${baseUrl}v1/user/settings`, {
      headers: { clientCode, sessionKey },
    });
  },

  getLoginSettingsConfig: async (requestData: {
    baseUrl: string;
    clientCode: string;
    sessionKey: string;
  }) => {
    const { baseUrl, clientCode, sessionKey } = requestData;
    return await axios.get<GetLoginSettingsResponseI>(
      `${baseUrl}v1/configuration`,
      {
        headers: { clientCode, sessionKey },
      }
    );
  },

  updateUserSettings: async (requestData: {
    baseUrl: string;
    clientCode: string;
    sessionKey: string;
    value: any;
  }) => {
    const { baseUrl, clientCode, sessionKey, value } = requestData;
    return await axios.patch(`${baseUrl}v1/user/settings`, value, {
      headers: { clientCode, sessionKey },
    });
  },

  getGoogleQRCode: async (requestData: {
    baseUrl: string;
    clientCode: string;
    sessionKey: string;
  }) => {
    const { baseUrl, clientCode, sessionKey } = requestData;
    return await axios.post<AppAuthenticationResponseI>(
      `${baseUrl}v1/user/two-factor-google`,
      null,
      {
        headers: { clientCode, sessionKey },
      }
    );
  },

  confirmGoogleQR: async (requestData: TwoFactorRequestI) => {
    const { value, baseUrl, clientCode, sessionKey } = requestData;
    return await axios.patch(`${baseUrl}v1/user/two-factor-google`, value, {
      headers: { clientCode, sessionKey },
    });
  },

  deleteChannel: async (
    requestData: TwoFactorRequestI,
    type: "email" | "google" | "ms"
  ) => {
    const { value, baseUrl, clientCode, sessionKey } = requestData;
    return await axios.patch(`${baseUrl}v1/user/two-factor-${type}`, value, {
      headers: { clientCode, sessionKey },
    });
  },

  getMSQRCode: async (requestData: {
    baseUrl: string;
    clientCode: string;
    sessionKey: string;
  }) => {
    const { baseUrl, clientCode, sessionKey } = requestData;
    return await axios.post<AppAuthenticationResponseI>(
      `${baseUrl}v1/user/two-factor-ms`,
      null,
      {
        headers: { clientCode, sessionKey },
      }
    );
  },

  confirmMSQR: async (requestData: TwoFactorRequestI) => {
    const { value, baseUrl, clientCode, sessionKey } = requestData;
    return await axios.patch(`${baseUrl}v1/user/two-factor-ms`, value, {
      headers: { clientCode, sessionKey },
    });
  },

  removeAuthChannel: async (requestData: {
    baseUrl: string;
    clientCode: string;
    sessionKey: string;
    type: "email" | "google" | "ms";
  }) => {
    const { type, baseUrl, clientCode, sessionKey } = requestData;
    return await axios.delete<{
      data: {
        twoFactorToken: string;
      };
    }>(`${baseUrl}v1/user/two-factor-${type}`, {
      headers: { clientCode, sessionKey },
    });
  },

  getFranchaiseAccounts: async (requestData: {
    baseUrl: string;
    clientCode: string;
    sessionKey: string;
  }) => {
    const { baseUrl, clientCode, sessionKey } = requestData;
    return await axios.get<FranchaiseAccountsResponseI>(
      `${baseUrl}v1/user/accounts`,
      {
        headers: { clientCode, sessionKey },
      }
    );
  },
  getBanners: async (baseUrl: string) => {
    return await axios.get<GetBannerResponseI>(`${baseUrl}banner`);
  },
  getApplicationCafaConfig: async (
    baseUrl: string,
    clientCode: string,
    sessionKey: string
  ) => {
    return await axios.get<GetCAFAConfigResponseI[] | null>(
      `${baseUrl}/configuration/${ConfigName.LoginApp}`,
      {
        headers: { clientCode, sessionKey },
      }
    );
  },
};

export default Server;
