import React, { useState } from "react";
import { useAuthState } from "../../context/auth";
import { notify } from "../../utils";
import { iNotification } from "react-notifications-component";
import Server from "../../service/Server";
import {
  TwoFactorRequestI,
  UserSessionI,
  ChannelRequestI,
} from "../../types/index";
import TextInput from "../elements/TextInput";
import Button from "../elements/Button";
import classNames from "classnames";
import HelperText from "../elements/HelperText";
import ErplyIcon from "../elements/ErplyIcon";

interface SetEmailProps {
  setSelected: (value: React.SetStateAction<string>) => void;
  setTarget: (value: React.SetStateAction<boolean>) => void;
  twoFactorChannel: string;
  setLoading: (value: React.SetStateAction<boolean>) => void;
  setChannel: (value: React.SetStateAction<boolean>) => void;
  isLoading: boolean;
}

const SetEmail: React.FC<SetEmailProps> = ({
  setSelected,
  setTarget,
  twoFactorChannel,
  setLoading,
  setChannel,
  isLoading,
}) => {
  const [isToken] = useState(false);
  const [formData, setData] = useState({
    email: "",
    token: "",
  });

  const {
    session,
    baseUrl,
    clientCode,
    handleChange: setAuth,
  } = useAuthState();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setData({ ...formData, [name]: value });
  };

  const confirmEmail = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      setLoading(true);
      const requestData: TwoFactorRequestI = {
        value: {
          twoFactorKey: formData.token,
          twoFactorToken: session?.twoFactorToken!,
        },
        baseUrl,
        clientCode,
      };
      await Server.confirmEmail(requestData);
      handleTwoFactorAuth(twoFactorChannel, baseUrl, session!);
    } catch (err: any) {
      if (err.response) {
        const {
          response: { data },
        } = err;
        if (data) {
          notify({
            message: `${data.status.errorDescription}`,
            type: "danger",
          } as iNotification);
        } else {
          notify({
            message: "Error performing operation. Please try again",
            type: "danger",
          } as iNotification);
        }
      } else {
        notify({
          message: "Error performing operation. Please try again",
          type: "danger",
        } as iNotification);
      }
      setLoading(false);
    }
  };

  const setEmailTarget = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleTwoFactorAuth(twoFactorChannel, baseUrl, session!);
  };

  const handleTwoFactorAuth = async (
    twoFactorChannel: string,
    baseUrl: string,
    sessionData: UserSessionI
  ): Promise<void> => {
    try {
      setLoading(true);
      const requestData: ChannelRequestI = {
        value: {
          twoFactorChannel,
          twoFactorToken: sessionData.twoFactorToken!,
          target: formData.email,
        },
        baseUrl,
        clientCode,
      };
      await Server.setChannel(requestData);
      notify({
        message: `Two factor key have been sent to your email`,
        type: "success",
      } as iNotification);
      setChannel(true);
      setLoading(false);
      setTarget(true);
      setAuth({
        session: sessionData,
        clientCode,
        baseUrl,
        sessionKey: sessionData.session?.sessionKey
          ? sessionData.session?.sessionKey
          : "",
        isAuthenticated: false,
      });
    } catch (err: any) {
      if (err.response) {
        const {
          response: { data },
        } = err;
        if (data) {
          notify({
            message: `${data.status.errorDescription}`,
            type: "danger",
          } as iNotification);
        } else {
          notify({
            message: "Error performing operation. Please try again",
            type: "danger",
          } as iNotification);
        }
      } else {
        notify({
          message: "Error performing operation. Please try again",
          type: "danger",
        } as iNotification);
      }
      setLoading(false);
    }
  };

  const renderFormType = () => {
    if (isToken) {
      return (
        <>
          <HelperText
            message='Enter the code sent to your email'
            className='mb-4'
          />
          <TextInput
            placeholder='Confirmation token'
            value={formData.token}
            name='token'
            onChange={onChange}
            autoFocus
            className='mb-[30px]'
            required
          />
        </>
      );
    }

    return (
      <>
        <HelperText
          message={`Set up an email for "Email" two factor channel`}
          className='mb-4'
        />
        <TextInput
          placeholder='Email'
          name='email'
          value={formData.email}
          onChange={onChange}
          className='mb-[30px]'
          autoFocus
          required
        />
      </>
    );
  };

  const btnCls = classNames(
    "dark:bg-white btn-block bg-erply-success-200 dark:text-gray-900 text-white border-0 dark:hover:text-gray-900 dark:hover:bg-[#E6E6E6] hover:bg-[#4AD17A] active:bg-[#35A95F] dark:active:bg-erply-dark-100",
    isLoading && "btn-disabled loading"
  );

  return (
    <form
      className='sm:w-96 w-80'
      onSubmit={(event) => {
        if (isToken) {
          confirmEmail(event);
        } else {
          setEmailTarget(event);
        }
      }}
    >
      {renderFormType()}
      <div className='flex space-x-5'>
        <div>
          <Button
            className='btn-block bg-erply-dark-20 border-0 text-white dark:text-gray-900 dark:hover:text-white'
            onClick={() => setSelected("")}
            type='button'
          >
            <ErplyIcon icon='Arrow-Left' />
          </Button>
        </div>
        <div className='w-full'>
          <Button disabled={isLoading} className={btnCls} type='submit'>
            {isToken ? "Confirm Token" : "Set Email"}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default SetEmail;
