import { State } from "../context/confirmation/interface";
import {
  useConfirmation,
  useConfirmationDispatch,
} from "../context/confirmation/store";

const useConfirm = (): State & {
  handleConfirm: (
    st: string,
    title?: string,
    confirmButtonTitle?: string,
    cancelButtonTitle?: string
  ) => Promise<boolean>;
} => {
  const confirmation = useConfirmation();
  const setConfirmation = useConfirmationDispatch();

  const handleConfirm = async (
    prompt: string,
    title?: string,
    confirmButtonTitle?: string,
    cancelButtonTitle?: string
  ) => {
    const res = await new Promise<boolean>((resolve) => {
      setConfirmation({
        prompt,
        title: title ?? "Alert!",
        isOpen: true,
        proceed: resolve,
        confirmButtonTitle,
        cancelButtonTitle,
      });
    });

    setConfirmation({ ...confirmation, isOpen: false });
    return res;
  };
  return {
    ...confirmation,
    handleConfirm,
  };
};

export default useConfirm;
