import React, { useEffect, useState } from "react";
import { noAccountError, useAuthState } from "../context/auth";
import {
  ChannelDataI,
  TwoFactorRequestI,
  ChannelRequestI,
} from "../types/index";
import { useNavigate } from "react-router-dom";
import { notify } from "../utils";
import { iNotification } from "react-notifications-component";
import Select from "react-dropdown-select";
import Server from "../service/Server";
import Button from "../components/elements/Button";
import classNames from "classnames";
import MainLayout from "../components/layouts/MainLayout";
import FormLayout from "../components/layouts/FormLayout";
import SetEmail from "../components/sections/SetEmail";
import TwoFactorAuth from "../components/sections/TwoFactorAuth";
import ErplyIcon from "../components/elements/ErplyIcon";

const TwoFactorPage: React.FC = () => {
  const [selected, setSelected] = useState<string>("");
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [isUrl, setIsUrl] = useState(false);
  const [isTarget, setTarget] = useState(true);
  const [isChannelSet, setChannel] = useState(false);
  const [channelList, setList] = useState<{ name: string; value: string }[]>(
    []
  );
  const [channelResponse, setResponse] = useState<ChannelDataI | null>(null);
  const [selectedOpt, setSelectedOpt] = useState<
    { name: string; value: string }[]
  >([]);
  const [isPasswordRotation, setIsPasswordRotation] = useState(false);

  const {
    session,
    baseUrl,
    clientCode,
    handleChange,
    setAccountAdminBaseUrl,
    setUserAPIBaseUrl,
  } = useAuthState();

  const navigate = useNavigate();

  useEffect(() => {
    let urlSearchParams = new URLSearchParams(window.location.search);
    let clientCode = urlSearchParams.get("clientCode");
    let key = urlSearchParams.get("key");
    let token = urlSearchParams.get("token");
    if (token && clientCode && key) {
      setIsUrl(true);
      handleLogin(key, token, clientCode);
    } else {
      if (!session || !session.twoFactorEnabled) {
        navigate("/");
      }
    }
    // eslint-disable-next-line
  }, [session]);

  useEffect(() => {
    if (session && session.twoFactorChannels) {
      const lists: { name: string; value: string }[] = [];
      session.twoFactorChannels.forEach((channel) => {
        if (channel.type === "EMAIL") {
          lists.push({ name: "Email", value: channel.type });
        }
        if (channel.type === "GOOGLE_APP") {
          lists.push({
            name: "Google Authenticator App",
            value: channel.type,
          });
        }

        if (channel.type === "MS_APP") {
          lists.push({
            name: "Microsoft Authenticator App",
            value: channel.type,
          });
        }
      });

      setList(lists);
    }
    // eslint-disable-next-line
  }, [session]);

  useEffect(() => {
    const selector = document.querySelector(".react-dropdown-select");

    if (selector && !selected) {
      selector.classList.add("select-error");
    } else {
      if (selector && selected && selector.classList.contains("select-error")) {
        selector.classList.remove("select-error");
      }
    }

    if (selected) {
      const data = session?.twoFactorChannels?.find(
        (channel) => channel.type === selected
      );
      if (data) {
        setTarget(data.isTargetSet);
      }
    }
    // eslint-disable-next-line
  }, [selected]);

  const handleLogin = async (
    twoFactorKey: string,
    twoFactorToken: string,
    clientCode: string
  ): Promise<void> => {
    try {
      setLoading(true);
      const response = await Server.getServiceEndPoint({
        clientCode,
        request: "getServiceEndpoints",
      });
      if (response.data.status.responseStatus === "error") {
        notify({
          message: noAccountError(),
          type: "danger",
        } as iNotification);

        setError(true);
        setLoading(false);
      } else {
        const url: string = response.data.records[0].auth.url
          ? response.data.records[0].auth.url
          : "";
        const accAdminBaseUrl: string = response.data.records[0][
          "account-admin"
        ].url
          ? response.data.records[0]["account-admin"].url
          : "";

        setAccountAdminBaseUrl(accAdminBaseUrl);
        const userBaseUrl: string = response.data.records[0]["user"].url
          ? response.data.records[0]["user"].url
          : "";
        setUserAPIBaseUrl(userBaseUrl);

        try {
          const requestData: TwoFactorRequestI = {
            value: {
              twoFactorKey,
              twoFactorToken,
            },
            baseUrl: url,
            clientCode,
          };

          const { data } = await Server.twoFactorAuth(requestData);
          handleChange({
            session: data.data,
            clientCode,
            baseUrl: url,
            sessionKey: data.data.session?.sessionKey
              ? data.data.session?.sessionKey
              : "",
            isAuthenticated: true,
          });
          setLoading(false);
          // localStorage.setItem("CLIENT", clientCode);
          navigate(`/${clientCode}/apps`);
          return;
        } catch (err: any) {
          if (err.response) {
            const {
              response: { data },
            } = err;
            if (data) {
              notify({
                message: `${data.status.errorDescription}`,
                type: "danger",
              } as iNotification);
            } else {
              notify({
                message: "Error performing operation. Please try again",
                type: "danger",
              } as iNotification);
            }
          } else {
            notify({
              message: "Error performing operation. Please try again",
              type: "danger",
            } as iNotification);
          }
          setLoading(false);
          setError(true);
        }
      }
    } catch (error) {
      notify({
        message: noAccountError(),
        type: "danger",
      } as iNotification);
      setError(true);
      setLoading(false);
    }
  };

  const handleSetChannel = async (): Promise<void> => {
    setLoading(true);
    try {
      const requestData: ChannelRequestI = {
        value: {
          twoFactorChannel: selected,
          twoFactorToken: session?.twoFactorToken!,
        },
        baseUrl,
        clientCode,
      };
      const { data } = await Server.setChannel(requestData);
      if (selected === "EMAIL") {
        notify({
          message: `Two factor key have been sent to your email`,
          type: "success",
        } as iNotification);
      }
      setResponse(data.data);
      setChannel(true);
      setLoading(false);
    } catch (err: any) {
      if (err.response) {
        const {
          response: { data },
        } = err;
        if (data) {
          notify({
            message: `${data.status.errorDescription}`,
            type: "danger",
          } as iNotification);
        } else {
          notify({
            message: "Error performing operation. Please try again",
            type: "danger",
          } as iNotification);
        }
      } else {
        notify({
          message: "Error performing operation. Please try again",
          type: "danger",
        } as iNotification);
      }
      setLoading(false);
    }
  };

  const btnCls = classNames(
    "btn-block bg-erply-success-200 dark:text-gray-900 text-white border-0 dark:hover:text-gray-900 dark:hover:bg-[#E6E6E6] hover:bg-[#4AD17A] active:bg-[#35A95F] dark:active:bg-erply-dark-100",
    isLoading && "btn-disabled loading dark:bg-erply-light-10",
    selected && "dark:bg-white"
  );

  const channelSelector = () => {
    return (
      <div className='sm:w-96 w-80'>
        <span className=' dark:text-white text-gray-900 mb-2 inline-block'>
          Second Authentication Channel{" "}
        </span>
        {/* <SelectSearch
          placeholder='Pick an authentication channel to continue'
          options={channelList}
          value={selected}
          onChange={(value) => {
            const opt: any = value as SelectedOptionValue;
            setSelected(opt?.value || opt);
          }}
        /> */}
        <Select
          options={channelList}
          values={selectedOpt}
          required
          labelField='name'
          valueField='value'
          searchBy='name'
          onChange={(value) => {
            setSelectedOpt(value);
            setSelected(value[0].value);
          }}
          dropdownHandleRenderer={({ state }) => {
            return (
              <ErplyIcon
                icon={
                  state.dropdown ? "Chevron-Up-Closed" : "Chevron-Down-Closed"
                }
              />
            );
          }}
        />

        <div className='mt-[10px] flex space-x-5'>
          <div>
            <Button
              className='btn-block bg-erply-dark-20 border-0 text-white dark:text-gray-900 dark:hover:text-white'
              onClick={() => {
                handleChange({
                  session: null,
                  clientCode,
                  sessionKey: "",
                  isAuthenticated: false,
                  baseUrl,
                });
                navigate("/");
              }}
              type='button'
            >
              <ErplyIcon icon='Arrow-Left' />
            </Button>
          </div>
          {selected && selected === "EMAIL" && !isTarget ? null : (
            <div className='w-full'>
              <Button
                className={btnCls}
                disabled={!selected || isLoading}
                onClick={handleSetChannel}
              >
                Next
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  };

  const togglRotatePassword = (state: boolean): void => {
    setIsPasswordRotation(state);
  };

  return (
    <MainLayout isSignUp={false}>
      <FormLayout
        showSpinner={isLoading && isUrl}
        title={
          isPasswordRotation ? "Change password" : "Two Factor Authentication"
        }
      >
        {isUrl ? (
          <>
            {isError ? (
              <div className='flex sm:w-96 w-80'>
                <Button
                  className='btn-block gap-2'
                  onClick={() => {
                    setError(false);
                    navigate("/");
                  }}
                >
                  <ErplyIcon icon='Arrow-Left' />
                  Back
                </Button>
              </div>
            ) : null}
          </>
        ) : (
          <>
            {selected && selected === "EMAIL" && !isTarget ? (
              <SetEmail
                setSelected={setSelected}
                setTarget={setTarget}
                twoFactorChannel={selected}
                setLoading={setLoading}
                setChannel={setChannel}
                isLoading={isLoading}
              />
            ) : (
              <>
                {selected && isChannelSet ? (
                  <TwoFactorAuth
                    setLoading={setLoading}
                    isChannelSet={isChannelSet}
                    twoFactorChannel={selected}
                    isTarget={isTarget}
                    channelResponse={channelResponse}
                    isLoading={isLoading}
                    togglRotatePassword={togglRotatePassword}
                  />
                ) : (
                  channelSelector()
                )}
              </>
            )}
          </>
        )}
      </FormLayout>
    </MainLayout>
  );
};

export default TwoFactorPage;
