import React from "react";
import { ReactNotifications } from "react-notifications-component";
import { Outlet } from "react-router-dom";
import useConfirm from "./hooks/useConfirm";
import Modal from "./components/elements/Modal";
import Button from "./components/elements/Button";
import ThemeSwitcher from "./components/theme/ThemeSwitcher";

import "./styles/App.css";
import "./styles/form.css";
import "./styles/helpers.css";
import "./styles/appselector.css";
import "./styles/sidebar.css";
import "./styles/select.css";

const ConfirmWindow: React.FC = () => {
  const {
    prompt = "",
    isOpen = false,
    proceed,
    title,
    confirmButtonTitle,
    cancelButtonTitle,
  } = useConfirm();
  return (
    <Modal
      isOpen={isOpen}
      title={title && "Alert"}
      onClose={() => proceed(false)}
    >
      <p className='py-4 dark:text-white text-gray-900'>{prompt}</p>
      <div className='modal-action space-x-3'>
        <div>
          <Button
            className='btn-block bg-erply-dark-20 border-0 text-white dark:text-gray-900 dark:hover:text-white border-none'
            onClick={() => proceed(false)}
          >
            {cancelButtonTitle ?? "Cancel"}
          </Button>
        </div>
        <div>
          <Button
            className='bg-erply-brand-danger-100 text-white gap-2 border-none hover:bg-[#e3242b] active:bg-[#d21404]'
            onClick={() => proceed(true)}
          >
            {confirmButtonTitle ?? "Remove Channel"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

// eslint-disable-next-line
export default function () {
  return (
    <div className='lg:h-screen dark:bg-erply-dark-100 bg-erply-light-20 relative '>
      <ThemeSwitcher />
      <ConfirmWindow />
      <ReactNotifications isMobile={true} />
      <Outlet />
    </div>
  );
}
