import React from "react";
import classNames from "classnames";

const Button: React.FC<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
> = ({ className, children, ...props }) => {
  const cls = classNames(
    "btn capitalize no-animation rounded-md text-base btn-styled",
    className
  );

  return (
    <button className={cls} {...props}>
      {children}
    </button>
  );
};

export default Button;
