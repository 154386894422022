import React, { useState } from "react";
import Server from "../../../service/Server";
import { TwoFactorRequestI } from "../../../types/index";
import { notify } from "../../../utils";
import { iNotification } from "react-notifications-component";
import { useAuthState } from "../../../context/auth";
import Spinner from "../../elements/Spinner";
import HelperText from "../../elements/HelperText";
import TextInput from "../../elements/TextInput";
import Modal from "../../elements/Modal";
import classNames from "classnames";
import Button from "../../elements/Button";

interface DeleteModalProps {
  isOpen: boolean;
  onClose: () => void;
  type: "email" | "google" | "ms" | "";
  twoFactorToken: string;
  getSettings: () => Promise<void>;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  isOpen,
  onClose,
  type,
  twoFactorToken,
  getSettings,
}) => {
  const [twoFactorKey, setToken] = useState("");
  const [isLoading, setLoading] = useState(false);

  const { baseUrl, clientCode, sessionKey } = useAuthState();

  const handleClose = () => {
    setToken("");
    setLoading(false);
    onClose();
  };

  const getHeading = () => {
    switch (type) {
      case "email":
        return "Enter the code sent to your email";

      case "google":
        return "Enter the code from you google authenticator app";

      default:
        return "Enter the code from you microsoft authenticator app";
    }
  };

  const confirmDelete = async () => {
    if (!twoFactorKey || !twoFactorToken || !type) return;
    try {
      setLoading(true);
      const requestData: TwoFactorRequestI = {
        baseUrl,
        sessionKey,
        clientCode,
        value: {
          twoFactorKey,
          twoFactorToken,
        },
      };
      await Server.deleteChannel(requestData, type);
      getSettings();
      notify({
        message: "Channel removed successfully",
        type: "success",
      } as iNotification);
      setLoading(false);
      handleClose();
    } catch (err: any) {
      if (err.response) {
        const {
          response: { data },
        } = err;
        if (data && data.status) {
          notify({
            message: `${data.status.errorDescription}`,
            type: "danger",
          } as iNotification);
        } else {
          notify({
            message: "Error performing operation. Please try again",
            type: "danger",
          } as iNotification);
        }
      } else {
        notify({
          message: "Error performing operation. Please try again",
          type: "danger",
        } as iNotification);
      }

      setLoading(false);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!twoFactorKey || !twoFactorToken) return;

    confirmDelete();
  };

  const renderFormType = () => {
    if (twoFactorToken) {
      return (
        <form onSubmit={handleSubmit}>
          <HelperText message={getHeading()} />
          <div className='mt-5'>
            <TextInput
              placeholder='Confirmation token'
              value={twoFactorKey}
              name='token'
              required
              onChange={(e) => setToken(e.target.value)}
              autoFocus
            />
          </div>
        </form>
      );
    }

    return (
      <div className='flex justify-center'>
        <Spinner />
      </div>
    );
  };

  const btnCls = classNames(
    "dark:bg-white btn-block bg-erply-success-200 dark:text-gray-900 text-white border-0 dark:hover:text-gray-900 dark:hover:bg-[#E6E6E6] hover:bg-[#4AD17A] active:bg-[#35A95F] dark:active:bg-erply-dark-100",
    isLoading && "btn-disabled loading"
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title='Two-step Channel Removal'
    >
      <div className='py-5'>
        {isLoading ? (
          <div className='flex justify-center'>
            <Spinner />
          </div>
        ) : null}
        {renderFormType()}
      </div>

      <div className='modal-action justify-center space-x-3'>
        <div>
          <Button
            className='btn-block bg-erply-dark-20 border-0 text-white dark:text-gray-900 dark:hover:text-white'
            onClick={handleClose}
          >
            Cancel
          </Button>
        </div>
        <div>
          <Button
            className={btnCls}
            disabled={!twoFactorKey}
            onClick={confirmDelete}
          >
            Confirm Token
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
