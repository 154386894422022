import React, { useState } from "react";
import MainLayout from "../components/layouts/MainLayout";
import FullAccount from "../components/sections/CreateAccount/FullAccount";
import UpdatePassword from "../components/sections/UpdatePassword";

const SignUpPage: React.FC = () => {
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [isPasswordRotation, setIsPasswordRotation] = useState(false);
  const [passwordResetToken, setPasswordResetToken] = useState("");

  const toggleChangePassword = (state: boolean): void => {
    setIsChangePassword(state);
  };

  const togglRotatePassword = (state: boolean): void => {
    setIsPasswordRotation(state);
  };

  return (
    <MainLayout isSignUp>
      {isChangePassword ? (
        <UpdatePassword
          toggleChangePassword={toggleChangePassword}
          isPasswordRotation={isPasswordRotation}
          passwordRenewalToken={passwordResetToken}
          isHome
        />
      ) : (
        <FullAccount
          toggleChangePassword={toggleChangePassword}
          setPasswordResetToken={setPasswordResetToken}
          togglRotatePassword={togglRotatePassword}
        />
      )}
    </MainLayout>
  );
};

export default SignUpPage;
