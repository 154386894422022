export type loginOptions = "email" | "mobile-id" | "SSO" | "username";
export type inputTypes =
  | "textarea"
  | "text"
  | "email"
  | "tel"
  | "password"
  | "number"
  | "search"
  | "color"
  | "date"
  | "time"
  | "datetime-local";

export type formStatus = "success" | "error" | "warning" | "info";
export type responseType = "TWO-FACTOR-TOKEN" | "RENEW-PASSWORD-TOKEN";

export interface FormStateI {
  username: string;
  password: string;
  clientCode: string;
  sessionKey: string;
  newPassword: string;
  oldPassword: string;
  twoFactorKey: string;
}

export interface ErplyRequestData {
  clientCode?: string;
  sessionKey?: string;
  [x: string]: any;
}

export interface SessionRequestI {
  value: {
    password: string;
    username: string;
    sessionLength: number;
  };
  baseUrl: string;
  clientCode: string;
}

export interface RenewPasswordRequestI {
  value: {
    newPassword: string;
    token: string;
  };
  baseUrl: string;
  clientCode: string;
}

export interface SSOValidateRequestI {
  value: {
    idToken: string;
    sessionLength: number;
    state: string;
  };
  baseUrl: string;
  clientCode: string;
}

export interface UpdatePasswordRequestI {
  value: {
    newPassword: string;
    oldPassword: string;
  };
  baseUrl: string;
  clientCode: string;
  sessionKey: string;
}

export interface UserSessionI {
  twoFactorChannels?: TwoFactorChannelsI[];
  twoFactorEnabled?: boolean;
  twoFactorToken?: string;
  backOfficeURL?: string;
  brazilPosURL?: string;
  serviceAppURL?: string;
  responseType?: responseType;
  passwordRenewalToken?: string;
  misc?: {
    supportEmail: string;
    supportPhone: string;
  };
  session?: {
    createdAt: number;
    expiresAt: number;
    length: number;
    sessionKey: string;
    user: {
      employeeId: number;
      employeeName: string;
      groupId: number;
      id: number;
      isPasswordExpired: boolean;
      username: string;
    };
  };
}

export interface ResponseStatus {
  errorCode: string;
  errorDescription: string;
  requestUnixTime: number;
  responseStatus: string;
}

export interface SessionResponseI {
  data: UserSessionI;
  status: ResponseStatus;
}

export interface TwoFactorChannelsI {
  isTargetSet: boolean;
  type: string;
}

export interface StateI {
  session: UserSessionI | null;
  clientCode: string;
  baseUrl: string;
  sessionKey: string;
  username: string;
  isAuthenticated: boolean;
}

export interface ChannelResponseI {
  data: ChannelDataI;
  status: ResponseStatus;
}

export interface ChannelDataI {
  channel: string;
  twoFactorToken: string;
  qr?: string;
}

export interface ChannelRequestI {
  value: {
    twoFactorToken: string;
    twoFactorChannel: string;
    target?: string;
  };
  baseUrl: string;
  clientCode: string;
}

export interface TwoFactorRequestI {
  value: {
    twoFactorToken: string;
    twoFactorKey: string;
  };
  baseUrl: string;
  clientCode: string;
  sessionKey?: string;
}

export interface GetResetPasswordTokenResponseI {
  data: {
    token: string;
  };
  status: ResponseStatus;
}

export interface ResetPasswordRequestI {
  value: {
    key: string;
    newPassword: string;
    token: string;
  };
  baseUrl: string;
  clientCode: string;
}

export interface SSORedirectURLResponseI {
  data: {
    uri: string;
  };
  status: ResponseStatus;
}

export interface SettingsI {
  channels?: {
    email?: {
      isSet: boolean;
      value: string;
      isEditable: boolean;
    };
    googleAuthApp?: {
      isSet: boolean;
      isEditable: boolean;
    };
    msAuthApp?: {
      isSet: boolean;
      isEditable: boolean;
    };
  };
  twoFactorEnabled: boolean;
}

export interface SettingsResponseI {
  data: SettingsI;
  status: ResponseStatus;
}

export interface AppAuthenticationI {
  qr: string;
  twoFactorToken: string;
}

export interface AppAuthenticationResponseI {
  data: AppAuthenticationI;
  status: ResponseStatus;
}

export interface SetEmailResponseI {
  data: {
    twoFactorToken: string;
  };
  status: ResponseStatus;
}

export interface GetLoginSettingsResponseI {
  data: {
    [key: string]: {
      name: string;
      value: any;
    };
  };
  status: ResponseStatus;
}

export interface FranchaiseAccount {
  code: string;
  id: number;
  name: string;
  number: string;
  display?: string;
}

export interface FranchaiseAccountsResponseI {
  data: FranchaiseAccount[];
  status: ResponseStatus;
}

export interface BannerI {
  desktopImageUrl: string;
  linkUrl: string;
  mobileImageUrl: string;
}

export interface GetBannerResponseI {
  data: BannerI[];
  status: ResponseStatus;
}

export type AppType = "backOffice" | "pointOfSale" | "serviceApp" | "generic";

export type Layout = "landingPage" | "generic";

export interface GetCAFAConfigResponseI {
  application: string;
  id: string;
  level: string;
  level_id: string;
  name: string;
  type: string;
  value: string[];
}

export enum ConfigName {
  LoginApp = "loginapp",
}

export type AppNames =
  | "backoffice"
  | "pointofsale"
  | "servicebooking"
  | "retrycrm";
