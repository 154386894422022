import React from "react";
import { useAuthState } from "../context/auth";
import classNames from "classnames";
import Applications from "../components/sections/Authenticated/Applications";

// sm:ml-20 ml-10

const ApplicationPage: React.FC = () => {
  const { isDarkTheme } = useAuthState();

  const cls = classNames(
    "h-full overflow-x-hidden w-screen",
    isDarkTheme ? "app-dark text-white" : "app-light"
  );

  return (
    <div className={cls}>
      <div className='pt-10 overflow-x-hidden md:ml-56 lg:mr-[120px] md:landscape:ml-72 md:landscape:mr-6'>
        <Applications />
      </div>
    </div>
  );
};

export default ApplicationPage;
