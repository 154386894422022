import React from "react";
import { AuthProvider } from "../context/auth";
import { ConfirmationProvider } from "../context/confirmation/store";

interface Props {
  children: React.ReactNode;
}

const AppProviders: React.FC<Props> = ({ children }) => {
  return (
    <ConfirmationProvider>
      <AuthProvider>{children}</AuthProvider>
    </ConfirmationProvider>
  );
};

export default AppProviders;
