import React, { useState, useEffect } from "react";
import MainLayout from "../components/layouts/MainLayout";
import { useAuthState } from "../context/auth";
import { useNavigate } from "react-router-dom";
import { notify, getParamsAfterHash } from "../utils";
import { iNotification } from "react-notifications-component";
import { SSOValidateRequestI } from "../types/index";
import Server from "../service/Server";
import FormLayout from "../components/layouts/FormLayout";
import Button from "../components/elements/Button";
import ErplyIcon from "../components/elements/ErplyIcon";

const SSOPage: React.FC = () => {
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const { handleChange, tokenHash, setAccountAdminBaseUrl, setUserAPIBaseUrl } =
    useAuthState();

  const navigate = useNavigate();

  useEffect(() => {
    let hash = window.location.hash;
    if (hash) {
      const { id_token, session_state, state, error, error_description } =
        getParamsAfterHash(hash);
      if (id_token && state && session_state) {
        signInUser(id_token, state);
      } else if (error && error_description) {
        let e_message: string | null = null;
        let errorm: string[] = decodeURIComponent(error_description).split("+");
        if (errorm.length) {
          e_message = errorm.join(" ");
        }
        setError(true);
        notify({
          message: e_message
            ? e_message
            : decodeURIComponent(error_description),
          type: "danger",
        } as iNotification);
      } else {
        setError(true);
        notify({
          message: "Authentication failed. Please try again",
          type: "danger",
        } as iNotification);
      }
      return;
    } else if (tokenHash) {
      const { id_token, session_state, state, error, error_description } =
        getParamsAfterHash(tokenHash);
      if (id_token && state && session_state) {
        signInUser(id_token, state);
      } else if (error && error_description) {
        let e_message: string | null = null;
        let errorm: string[] = decodeURIComponent(error_description).split("+");
        if (errorm.length) {
          e_message = errorm.join(" ");
        }
        setError(true);
        notify({
          message: e_message
            ? e_message
            : decodeURIComponent(error_description),
          type: "danger",
        } as iNotification);
      } else {
        setError(true);
        notify({
          message: "Authentication failed. Please try again",
          type: "danger",
        } as iNotification);
      }
    } else {
      setError(true);
      notify({
        message: "Authentication failed. Please try again",
        type: "danger",
      } as iNotification);
    }
    // eslint-disable-next-line
  }, [tokenHash]);

  const signInUser = async (idToken: string, state: string): Promise<void> => {
    setLoading(true);
    let clientCode = "";
    const code = localStorage.getItem("SSO_CLIENT");
    if (code) {
      clientCode = code;
    }

    try {
      const response = await Server.getServiceEndPoint({
        clientCode,
        request: "getServiceEndpoints",
      });

      if (response.data.status.responseStatus === "error") {
        notify({
          message:
            "Error getting service point. Please check the Account number and try again",
          type: "danger",
        } as iNotification);

        setError(true);
        setLoading(false);
      } else {
        const url: string = response.data.records[0].auth.url
          ? response.data.records[0].auth.url
          : "";
        const accAdminBaseUrl: string = response.data.records[0][
          "account-admin"
        ].url
          ? response.data.records[0]["account-admin"].url
          : "";

        setAccountAdminBaseUrl(accAdminBaseUrl);
        const userBaseUrl: string = response.data.records[0]["user"].url
          ? response.data.records[0]["user"].url
          : "";
        setUserAPIBaseUrl(userBaseUrl);

        try {
          const requestData: SSOValidateRequestI = {
            baseUrl: url,
            clientCode,
            value: {
              idToken,
              state,
              sessionLength: 43200,
            },
          };

          const { data } = await Server.validateSSOResponse(requestData);

          handleChange({
            session: data.data,
            clientCode,
            baseUrl: url,
            sessionKey: data.data.session?.sessionKey
              ? data.data.session?.sessionKey
              : "",
            isAuthenticated: true,
          });
          setLoading(false);
          navigate(`/${clientCode}/apps`);
        } catch (err: any) {
          if (err.response) {
            const {
              response: { data },
            } = err;
            if (data) {
              notify({
                message: `${data.status.errorDescription}`,
                type: "danger",
              } as iNotification);
            } else {
              notify({
                message: "Error performing operation. Please try again",
                type: "danger",
              } as iNotification);
            }
          } else {
            notify({
              message: "Error performing operation. Please try again",
              type: "danger",
            } as iNotification);
          }
          setLoading(false);
          setError(true);
        }
      }
    } catch (error) {
      notify({
        message:
          "Error getting service point. Please check the Account number and try again",
        type: "danger",
      } as iNotification);
      setError(true);
      setLoading(false);
    }
  };

  return (
    <MainLayout>
      <FormLayout showSpinner={isLoading} title='SSO Authentication'>
        {isError ? (
          <div className='flex sm:w-96 w-80'>
            <Button
              className='btn-block gap-2'
              onClick={() => {
                setError(false);
                navigate("/");
              }}
            >
              <ErplyIcon icon='Arrow-Left' />
              Back
            </Button>
          </div>
        ) : null}
      </FormLayout>
    </MainLayout>
  );
};

export default SSOPage;
