import { useEffect } from "react";

const useIsIntersectingWithRoot = (
  elRef: React.RefObject<any>,
  rootRef: React.RefObject<any>,
  threshold: number,
  onIntersecting: () => void
) => {
  useEffect(() => {
    if (!("IntersectionObserver" in window)) {
      return () => {};
    }

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            onIntersecting();
          }
        });
      },
      {
        root: rootRef.current,
        threshold,
      }
    );

    observer.observe(elRef.current);

    return () => {
      observer.disconnect();
    };
  }, [rootRef, threshold, onIntersecting, elRef]);
};

export default useIsIntersectingWithRoot;
