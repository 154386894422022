import React, { useState, useEffect } from "react";
import { useAuthenticatedContext } from "../../../views/AuthenticatedPage";
import Alert from "../../elements/Alert";
import Button from "../../elements/Button";
import ErplyIcon from "../../elements/ErplyIcon";
import { AppAuthenticationI } from "../../../types/index";
import { useAuthState } from "../../../context/auth";
import Server from "../../../service/Server";
import { notify } from "../../../utils";
import { iNotification } from "react-notifications-component";
import Spinner from "../../elements/Spinner";
import EmailSettingsModal from "./EmailSettingsModal";
import MSAuthenticationModal from "./MSAuthenticationModal";
import GoogleAuthenticationModal from "./GoogleAuthenticationModal";
import useConfirm from "../../../hooks/useConfirm";
import DeleteModal from "./DeleteModal";
import useMatchMediaMatches from "../../../hooks/useMatchMediaMatches";

const TwoFactorSettings: React.FC = () => {
  const [isGoogle, setIsGoogle] = useState(false);
  const [isMs, setMs] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [otherConfigs, setOtherConfigs] = useState({
    email: "",
    twoFactorEnabled: false,
  });
  const [qrCode, setQrCode] = useState<AppAuthenticationI | null>(null);
  const [twoFactorToken, setTwoFactorToken] = useState("");
  const [deleteData, setDeleteData] = useState<{
    isOpen: boolean;
    type: "google" | "email" | "ms" | "";
  }>({
    isOpen: false,
    type: "",
  });

  const { settings, isTwoFactorEnabled, getSettings } =
    useAuthenticatedContext();
  const { clientCode, sessionKey, baseUrl } = useAuthState();

  const { handleConfirm } = useConfirm();

  useEffect(() => {
    if (settings) {
      const newData = {
        email: settings.channels?.email?.value
          ? settings.channels.email.value
          : "",
        twoFactorEnabled: settings.twoFactorEnabled,
      };

      setOtherConfigs(newData);
    }
  }, [settings]);

  const closeGoogle = () => {
    setIsGoogle(false);
    setQrCode(null);
  };

  const closeMS = () => {
    setMs(false);
    setQrCode(null);
  };

  const closeEmailModal = () => {
    setIsEmail(false);
  };

  const closeDeleteModal = () => {
    setDeleteData({ isOpen: false, type: "" });
    setTwoFactorToken("");
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked, name } = event.target;

    if (name === "twoFactorEnabled") {
      setOtherConfigs({ ...otherConfigs, [name]: checked });
    } else {
      setOtherConfigs({ ...otherConfigs, [name]: value });
    }
  };

  const getGoogleCode = async () => {
    try {
      setLoading(true);
      const request = {
        clientCode,
        sessionKey,
        baseUrl,
      };
      const { data } = await Server.getGoogleQRCode(request);
      setQrCode(data.data);
      setLoading(false);
      setIsGoogle(true);
    } catch (err: any) {
      if (err.response) {
        const {
          response: { data },
        } = err;
        if (data && data.status) {
          notify({
            message: `${data.status.errorDescription}`,
            type: "danger",
          } as iNotification);
        } else {
          notify({
            message: "Error performing operation. Please try again",
            type: "danger",
          } as iNotification);
        }
      } else {
        notify({
          message: "Error performing operation. Please try again",
          type: "danger",
        } as iNotification);
      }
      setQrCode(null);
      setLoading(false);
      setIsGoogle(false);
    }
  };

  const getMsCode = async () => {
    try {
      setLoading(true);
      const request = {
        clientCode,
        sessionKey,
        baseUrl,
      };
      const { data } = await Server.getMSQRCode(request);
      setQrCode(data.data);
      setLoading(false);
      setMs(true);
    } catch (err: any) {
      if (err.response) {
        const {
          response: { data },
        } = err;
        if (data && data.status) {
          notify({
            message: `${data.status.errorDescription}`,
            type: "danger",
          } as iNotification);
        } else {
          notify({
            message: "Error performing operation. Please try again",
            type: "danger",
          } as iNotification);
        }
      } else {
        notify({
          message: "Error performing operation. Please try again",
          type: "danger",
        } as iNotification);
      }
      setQrCode(null);
      setLoading(false);
    }
  };

  const removeChannel = async (type: "google" | "email" | "ms") => {
    const confirm = await handleConfirm(
      "Are you sure you want to remove this channel?"
    );

    if (confirm) {
      try {
        setLoading(true);
        const {
          data: { data },
        } = await Server.removeAuthChannel({
          baseUrl,
          clientCode,
          sessionKey,
          type,
        });
        setTwoFactorToken(data.twoFactorToken);
        setDeleteData({ isOpen: true, type });
        setLoading(false);
      } catch (err: any) {
        getSettings();
        if (err.response) {
          const {
            response: { data },
          } = err;
          if (data && data.status) {
            notify({
              message: `${data.status.errorDescription}`,
              type: "danger",
            } as iNotification);
          } else {
            notify({
              message: "Error performing operation. Please try again",
              type: "danger",
            } as iNotification);
          }
        } else {
          notify({
            message: "Error performing operation. Please try again",
            type: "danger",
          } as iNotification);
        }

        setLoading(false);
      }
    }
  };

  const isDesktopOrLaptop =
    useMatchMediaMatches("(min-width: 1224px)") === true;

  return (
    <div
      className={
        isDesktopOrLaptop
          ? "lg:w-[800px] mr-auto md:w-4/6"
          : "md:landscape:w-full md:ml-auto mr-auto md:w-4/6"
      }
      data-testid='two-step-authentication-form'
    >
      {settings && isTwoFactorEnabled ? (
        <Alert className='dark:bg-erply-dark-500 dark:text-white rounded-[5px]'>
          Here you can manage two-step authentication settings and also set up
          two-step authentication channels.
        </Alert>
      ) : (
        <Alert className='dark:bg-erply-dark-500 dark:text-white rounded-[5px]'>
          Two-factor authentication is disabled. Please ask your account
          administrator to turn it on from{" "}
          <a
            href={`https://conf-admin-ui-sb.erply.com/?clientCode=${clientCode}#/login-security`}
            target='_blank'
            rel='noopener noreferrer'
            className='text-blue-600 hover:underline'
          >
            {" "}
            Settings {" >"} Configuration Admin.
          </a>
        </Alert>
      )}
      {isLoading ? (
        <div className='flex justify-center'>
          <Spinner />
        </div>
      ) : null}

      {settings && isTwoFactorEnabled ? (
        <>
          {settings.channels ? (
            <div className='sm:bg-erply-alert-50 dark:bg-erply-dark-500 p-8 mt-7 w-full rounded-[5px]'>
              <span
                className='text-base font-bold block mb-8 dark:text-white text-gray-900'
                data-testid='two-step-authentication-header-text'
              >
                Two-step Authentication Channels
              </span>
              <table className='w-full lg:block hidden'>
                <thead className='bg-transparent border border-x-0 border-t-0 text-left dark:border-slate-600'>
                  <tr>
                    <th className='py-2 pl-3 font-bold'>Channel</th>
                    <th className='py-2 font-bold'>Status</th>
                    <th className='py-2'></th>
                  </tr>
                </thead>
                <tbody>
                  {/* <!-- row  --> */}
                  {settings?.channels?.email && (
                    <tr className='text-left border border-x-0 dark:border-slate-600'>
                      <td className='w-2/5 py-1 pl-3'>
                        {settings?.channels?.email?.value
                          ? `Email (${settings?.channels?.email?.value})`
                          : "Email (No Email Set)"}
                      </td>
                      <td className='py-1 w-full'>
                        {settings?.channels?.email.isSet
                          ? "Enabled"
                          : "Not enabled"}
                      </td>
                      {settings.channels?.email?.isEditable && (
                        <td className='py-1 pr-3'>
                          <div className='flex justify-end space-x-3'>
                            {!settings?.channels?.email?.value && (
                              <Button
                                className='bg-erply-success-200 text-white gap-2 border-none  hover:bg-[#4AD17A] active:bg-[#35A95F] '
                                onClick={() => setIsEmail(true)}
                                data-testid='email-add-channel-button'
                              >
                                <ErplyIcon icon='Plus' />
                                <span>Add Channel</span>
                              </Button>
                            )}
                            {/* <Button className='bg-erply-light-200 gap-2 text-white'>
                              <ErplyIcon icon='Edit-Box' />
                              <span>Edit Channel</span>
                            </Button> */}
                            {settings?.channels?.email?.value && (
                              <Button
                                className='bg-erply-brand-danger-100 text-white gap-2 border-none  hover:bg-[#e3242b] active:bg-[#d21404]'
                                onClick={() => removeChannel("email")}
                              >
                                <ErplyIcon icon='Minus' />
                                <span>Remove Channel</span>
                              </Button>
                            )}
                          </div>
                        </td>
                      )}
                    </tr>
                  )}
                  {settings?.channels?.googleAuthApp && (
                    <tr className='text-left border border-x-0 dark:border-slate-600'>
                      <td className='w-2/5 py-1 pl-3'>
                        Google Authenticator App
                      </td>
                      <td className='py-1 w-full'>
                        {settings?.channels?.googleAuthApp?.isSet
                          ? "Enabled"
                          : "Not enabled"}
                      </td>
                      <td className='py-1 pr-3'>
                        <div className='flex justify-end space-x-3'>
                          {!settings?.channels?.googleAuthApp?.isSet ? (
                            <Button
                              className=' bg-erply-success-200 text-white gap-2 border-none  hover:bg-[#4AD17A] active:bg-[#35A95F] '
                              onClick={getGoogleCode}
                              data-testid='google-add-channel-button'
                            >
                              <ErplyIcon icon='Plus' />
                              <span>Add Channel</span>
                            </Button>
                          ) : (
                            <Button
                              className='bg-erply-brand-danger-100 text-white gap-2 border-none hover:bg-[#e3242b] active:bg-[#d21404]'
                              onClick={() => removeChannel("google")}
                            >
                              <ErplyIcon icon='Minus' />
                              <span>Remove Channel</span>
                            </Button>
                          )}
                        </div>
                      </td>
                    </tr>
                  )}
                  {settings?.channels?.msAuthApp && (
                    <tr className='text-left'>
                      <td className='w-2/5 py-1 pl-3'>
                        Microsoft Authenticator App
                      </td>
                      <td className='py-1 w-full'>
                        {settings?.channels?.msAuthApp?.isSet
                          ? "Enabled"
                          : "Not enabled"}
                      </td>
                      <td className='py-1 pr-3'>
                        <div className='flex justify-end'>
                          {!settings?.channels?.msAuthApp?.isSet ? (
                            <Button
                              className=' bg-erply-success-200 text-white gap-2 border-none  hover:bg-[#4AD17A] active:bg-[#35A95F] '
                              onClick={getMsCode}
                              data-testid='microsoft-add-channel-button'
                            >
                              <ErplyIcon icon='Plus' />
                              <span>Add Channel</span>
                            </Button>
                          ) : (
                            <Button
                              className='bg-erply-brand-danger-100 text-white gap-2 border-none hover:bg-[#e3242b] active:bg-[#d21404]'
                              onClick={() => removeChannel("ms")}
                            >
                              <ErplyIcon icon='Minus' />
                              <span>Remove Channel</span>
                            </Button>
                          )}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className='flex flex-col lg:hidden'>
                {settings?.channels?.email && (
                  <div className='border rounded-sm px-5 py-3 flex flex-col items-center mb-5 shadow-sm dark:border-slate-600'>
                    <span className='font-bold'>Channel</span>
                    <span className='block mb-4'>
                      {settings?.channels?.email?.value
                        ? `Email (${settings?.channels?.email?.value})`
                        : "Email (No Email Set)"}
                    </span>
                    <span className='font-bold'>Status</span>
                    <span className='block mb-4'>
                      {settings?.channels?.email.isSet
                        ? "Enabled"
                        : "Not enabled"}
                    </span>
                    {settings.channels?.email?.isEditable && (
                      <div className='flex flex-col space-y-3 '>
                        {!settings?.channels?.email?.value && (
                          <Button
                            className=' bg-erply-success-200 text-white gap-2 border-none dark:hover:text-gray-900 dark:hover:bg-[#E6E6E6] hover:bg-[#4AD17A] active:bg-[#35A95F] dark:active:bg-erply-dark-100'
                            onClick={() => setIsEmail(true)}
                          >
                            <ErplyIcon icon='Plus' />
                            <span>Add Channel</span>
                          </Button>
                        )}
                        {settings?.channels?.email?.value && (
                          <Button
                            className='bg-erply-brand-danger-100 text-white gap-2 border-none hover:text-gray-900  hover:!bg-[#E6E6E6]'
                            onClick={() => removeChannel("email")}
                          >
                            <ErplyIcon icon='Minus' />
                            <span>Remove Channel</span>
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                )}
                {settings?.channels?.googleAuthApp && (
                  <div className='border rounded-sm px-5 py-3 flex flex-col items-center mb-5 shadow-sm dark:border-slate-600'>
                    <span className='font-bold'>Channel</span>
                    <span className='block mb-4'>Google Authenticator App</span>
                    <span className='font-bold'>Status</span>
                    <span className='block mb-4'>
                      {settings?.channels?.googleAuthApp?.isSet
                        ? "Enabled"
                        : "Not enabled"}
                    </span>
                    <div className='flex flex-col space-y-3 '>
                      {!settings?.channels?.googleAuthApp?.isSet ? (
                        <Button
                          className='bg-erply-success-200 text-white gap-2 border-none dark:hover:text-gray-900 dark:hover:bg-[#E6E6E6] hover:bg-[#4AD17A] active:bg-[#35A95F] dark:active:bg-erply-dark-100'
                          onClick={getGoogleCode}
                        >
                          <ErplyIcon icon='Plus' />
                          <span>Add Channel</span>
                        </Button>
                      ) : (
                        <Button
                          className='bg-erply-brand-danger-100 text-white gap-2 border-none hover:text-gray-900  hover:!bg-[#E6E6E6]'
                          onClick={() => removeChannel("google")}
                        >
                          <ErplyIcon icon='Minus' />
                          <span>Remove Channel</span>
                        </Button>
                      )}
                    </div>
                  </div>
                )}
                {settings?.channels?.msAuthApp && (
                  <div className='border rounded-sm px-5 py-3 flex flex-col items-center mb-5 shadow-sm dark:border-slate-600'>
                    <span className='font-bold'>Channel</span>
                    <span className='block mb-4'>
                      Microsoft Authenticator App
                    </span>
                    <span className='font-bold'>Status</span>
                    <span className='block mb-4'>
                      {settings?.channels?.msAuthApp?.isSet
                        ? "Enabled"
                        : "Not enabled"}
                    </span>
                    <div className='flex flex-col space-y-3 '>
                      {!settings?.channels?.msAuthApp?.isSet ? (
                        <Button
                          className=' bg-erply-success-200 text-white gap-2 border-none dark:hover:text-gray-900 dark:hover:bg-[#E6E6E6] hover:bg-[#4AD17A] active:bg-[#35A95F] dark:active:bg-erply-dark-100'
                          onClick={getMsCode}
                        >
                          <ErplyIcon icon='Plus' />
                          <span>Add Channel</span>
                        </Button>
                      ) : (
                        <Button
                          className='bg-erply-brand-danger-100 text-white gap-2 border-none hover:text-gray-900  hover:!bg-[#E6E6E6]'
                          onClick={() => removeChannel("ms")}
                        >
                          <ErplyIcon icon='Minus' />
                          <span>Remove Channel</span>
                        </Button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </>
      ) : null}
      <EmailSettingsModal
        isOpen={isEmail}
        onClose={closeEmailModal}
        getSettings={getSettings}
        onChange={onChange}
        email={otherConfigs.email}
      />
      <MSAuthenticationModal
        isOpen={isMs}
        onClose={closeMS}
        getSettings={getSettings}
        qrCode={qrCode}
      />
      <GoogleAuthenticationModal
        isOpen={isGoogle}
        onClose={closeGoogle}
        qrCode={qrCode}
        getSettings={getSettings}
      />
      <DeleteModal
        isOpen={deleteData.isOpen}
        type={deleteData.type}
        getSettings={getSettings}
        onClose={closeDeleteModal}
        twoFactorToken={twoFactorToken}
      />
    </div>
  );
};

export default TwoFactorSettings;
