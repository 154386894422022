import React, { RefObject, useCallback, useRef, useState } from "react";
import useIsIntersectingWithRoot from "../../hooks/useIsIntersectingWithRoot";
import { fillArray } from "../../utils";
import { BannerI } from "../../types/index";
import useMatchMediaMatches from "../../hooks/useMatchMediaMatches";

interface CarouselProps {
  banners: BannerI[];
}

const Image = ({
  image,
  index,
  root,
  onVisibe,
}: {
  image: BannerI;
  index: number;
  root: RefObject<any>;
  onVisibe: (index: number) => void;
}) => {
  const ref = useRef<HTMLAnchorElement>(null);

  const isDeskTop = useMatchMediaMatches("(min-width: 640px)") === true;

  useIsIntersectingWithRoot(ref, root, 0.51, () => onVisibe(index));

  return (
    <a
      href={image.linkUrl}
      ref={ref}
      target='_blank'
      rel='noopener noreferrer'
      id={index.toString()}
      className='carousel-item w-full'
    >
      <img
        src={isDeskTop ? image.desktopImageUrl : image.mobileImageUrl}
        className='w-full'
        alt='AD'
        loading='lazy'
      />
    </a>
  );
};

const PaginationDots = ({
  count,
  active,
  onClick,
}: {
  count: number;
  active: number;
  onClick: (index: number) => void;
}) => {
  return (
    <div className='flex justify-center w-full py-2 gap-2'>
      {fillArray(count, (index) => (
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <a
          href={`#${index.toString()}`}
          className={`w-[10px] h-[10px]  rounded-full ${
            index === active
              ? "dark:bg-[#8793A3] bg-[#999999]"
              : "dark:bg-[#4B5D74] bg-[#E6E6E6]"
          }`}
          onClick={() => onClick(index)}
          key={index}
        ></a>
      ))}
    </div>
  );
};

// const testItems = [
//   "https://api.lorem.space/image/car?w=800&h=200&hash=8B7BCDC2",
//   "https://api.lorem.space/image/car?w=800&h=200&hash=500B67FB",
//   "https://api.lorem.space/image/car?w=800&h=200&hash=A89D0DE6",
//   "https://api.lorem.space/image/car?w=800&h=200&hash=225E6693",
// ];

const Carousel: React.FC<CarouselProps> = ({ banners }) => {
  const [selected, setSelected] = useState(0);

  const ref = useRef<HTMLDivElement>(null);

  const onVisibe = useCallback(
    (index: number) => {
      setSelected(index);
    },
    [setSelected]
  );

  return (
    <>
      {banners.length > 1 ? (
        <PaginationDots
          count={banners.length}
          active={selected}
          onClick={onVisibe}
        />
      ) : null}
      <div className='carousel w-full' ref={ref}>
        {banners.map((item, index) => (
          <Image
            image={item}
            index={index}
            root={ref}
            onVisibe={onVisibe}
            key={index}
          />
        ))}
      </div>
    </>
  );
};

export default Carousel;
