import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "../../context/auth";
import useFormHandler from "../../hooks/useFormHandler";
import { ChannelDataI, TwoFactorRequestI } from "../../types/index";
import { notify } from "../../utils";
import { iNotification } from "react-notifications-component";
import Server from "../../service/Server";
import Alert from "../elements/Alert";
import TextInput from "../elements/TextInput";
import Button from "../elements/Button";
import HelperText from "../elements/HelperText";
import classNames from "classnames";
import ErplyIcon from "../elements/ErplyIcon";
import UpdatePassword from "./UpdatePassword";

interface TwoFactorAuthProps {
  setLoading: (value: React.SetStateAction<boolean>) => void;
  isChannelSet: boolean;
  twoFactorChannel: string;
  isTarget: boolean;
  channelResponse: ChannelDataI | null;
  isLoading: boolean;
  togglRotatePassword: (state: boolean) => void;
}

const TwoFactorAuth: React.FC<TwoFactorAuthProps> = ({
  setLoading,
  isChannelSet,
  twoFactorChannel,
  isTarget,
  channelResponse,
  isLoading,
  togglRotatePassword,
}) => {
  const [isPasswordRotation, setIsPasswordRotation] = useState(false);
  const [passwordResetToken, setPasswordResetToken] = useState("");

  const { twoFactorKey, handleChange } = useFormHandler({
    username: "",
    clientCode: "",
    sessionKey: "",
    password: "",
    oldPassword: "",
    newPassword: "",
    twoFactorKey: "",
  });
  const {
    session,
    baseUrl,
    clientCode,
    handleChange: setAuth,
  } = useAuthState();

  const navigate = useNavigate();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    handleChange(value, name);
  };

  const handleLogin = async (): Promise<void> => {
    if (!twoFactorKey) return;
    try {
      setLoading(true);
      const requestData: TwoFactorRequestI = {
        value: {
          twoFactorKey,
          twoFactorToken: channelResponse
            ? channelResponse.twoFactorToken
            : session?.twoFactorToken!,
        },
        baseUrl,
        clientCode,
      };

      const { data } = await Server.twoFactorAuth(requestData);

      if (
        data.data.responseType &&
        data.data.responseType === "RENEW-PASSWORD-TOKEN" &&
        data.data.passwordRenewalToken
      ) {
        setIsPasswordRotation(true);
        togglRotatePassword(true);
        setPasswordResetToken(data.data.passwordRenewalToken);
        setLoading(false);
      } else {
        setIsPasswordRotation(false);
        togglRotatePassword(false);
        setAuth({
          session: data.data,
          clientCode,
          baseUrl,
          sessionKey: data.data.session?.sessionKey
            ? data.data.session?.sessionKey
            : "",
          isAuthenticated: true,
        });
        setLoading(false);
        // localStorage.setItem("CLIENT", clientCode);
        navigate(`/${clientCode}/apps`);
      }
    } catch (err: any) {
      if (err.response) {
        const {
          response: { data },
        } = err;
        if (data) {
          notify({
            message: `${data.status.errorDescription}`,
            type: "danger",
          } as iNotification);
        } else {
          notify({
            message: "Error performing operation. Please try again",
            type: "danger",
          } as iNotification);
        }
      } else {
        notify({
          message: "Error performing operation. Please try again",
          type: "danger",
        } as iNotification);
      }
      setLoading(false);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleLogin();
  };

  const btnCls = classNames(
    "dark:bg-white btn-block bg-erply-success-200 dark:text-gray-900 text-white border-0 dark:hover:text-gray-900 dark:hover:bg-[#E6E6E6] hover:bg-[#4AD17A] active:bg-[#35A95F] dark:active:bg-erply-dark-100",
    isLoading && "btn-disabled loading"
  );

  const renderTwoFactorSettings = () => (
    <>
      {!isTarget && twoFactorChannel !== "EMAIL" && channelResponse ? (
        <div className='sm:w-96 w-80'>
          {twoFactorChannel === "GOOGLE_APP" ? (
            <Alert
              message={`Install Google Authenticator App from your application store and
        use the app to scan this QR code.`}
            />
          ) : (
            <Alert
              message={`Install Microsoft Authenticator App from your application store
        and use the app to scan this QR code.`}
            />
          )}
          <div className='mt-4 flex justify-center'>
            <img
              src={`data:image/png;base64,${channelResponse.qr}`}
              alt='code'
              className='w-60'
            />
          </div>
        </div>
      ) : null}
      <form
        className={
          !isTarget && twoFactorChannel !== "EMAIL" && channelResponse
            ? "mt-5 sm:w-96 w-80"
            : "sm:w-96 w-80"
        }
        onSubmit={handleSubmit}
      >
        <TextInput
          name='twoFactorKey'
          placeholder='Two Factor Key'
          value={twoFactorKey}
          onChange={onChange}
          required
          autoFocus
        />
        {twoFactorChannel === "EMAIL" ? (
          <HelperText
            message='Enter the code sent to your email'
            className='mb-[30px]'
          />
        ) : (
          <HelperText
            message='Enter the code from your app'
            className='mb-[30px]'
          />
        )}
        <div className='flex space-x-5'>
          <div>
            <Button
              className='btn-block bg-erply-dark-20 border-0 text-white dark:text-gray-900 dark:hover:text-white'
              onClick={() => {
                setAuth({
                  session: null,
                  clientCode,
                  sessionKey: "",
                  isAuthenticated: false,
                  baseUrl,
                });
                navigate("/");
              }}
              type='button'
            >
              <ErplyIcon icon='Arrow-Left' />
            </Button>
          </div>
          <div className='w-full'>
            <Button disabled={isLoading} className={btnCls} type='submit'>
              Login
            </Button>
          </div>
        </div>
      </form>
    </>
  );

  return (
    <>
      {isPasswordRotation ? (
        <UpdatePassword
          isPasswordRotation
          passwordRenewalToken={passwordResetToken}
          toggleChangePassword={(value) => {
            setIsPasswordRotation(value);
            togglRotatePassword(value);
          }}
        />
      ) : (
        renderTwoFactorSettings()
      )}
    </>
  );
};

export default TwoFactorAuth;
