import axios, { AxiosRequestConfig } from "axios";
import { Store, iNotification } from "react-notifications-component";
import { SelectSearchOption } from "react-select-search";
import { AppNames, ErplyRequestData, UserSessionI } from "../types";
import settings from "../../package.json";
// eslint-disable-next-line import/no-extraneous-dependencies
import Fuse from "fuse.js";
import { IconValueType } from "../styles/iconValues";

export const requestERPLYData = (
  requestData: ErplyRequestData,
  isSignUp?: boolean
) => {
  const requestFormData = new FormData();
  Object.entries(requestData).forEach((fieldValue) => {
    const [key, value] = fieldValue;
    requestFormData.set(`${key}`, `${value}`);
  });

  const options: AxiosRequestConfig = {
    method: "post",
    headers: { "Content-type": "multipart/form-data" },
    url: isSignUp
      ? `https://api-us.erply.com/api/`
      : `https://${requestData.clientCode}.erply.com/api/`,
    data: requestFormData,
  };
  return axios(options);
};

export const notify = (options: iNotification) => {
  Store.addNotification({
    message: options.message || "Error performing operation. Please try again",
    type: options.type,
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 6000,
      onScreen: false,
      showIcon: true,
    },
  });
};

//get's a query parameter by name from current url
export const getParamsAfterHash = (url: string) => {
  if (typeof url !== "string" || !url) url = window.location.href;
  url = url.split("#")[1];
  if (!url) return {};
  // url = url.split("?")[1];
  if (!url) return {};
  return url.split("&").reduce((result: any, param) => {
    const [key, value] = param.split("=");
    result[key] = value;
    return result;
  }, {});
};

export const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const getErrorMessage = (errorCode: number): string => {
  switch (errorCode) {
    case 1100:
      return "New password must contain at least 8 characters.";

    case 1101:
      return "New password may only contain Latin letters and digits.";

    case 1102:
      return "New password must contain at least one small letter, one capital letter and one digit.";

    default:
      return "Error performing operation. Please try again";
  }
};

export const isObjectEmpty = (obj: Record<string, any>): boolean => {
  for (const _ in obj) return false;

  return true;
};

export const checkIfAnyChannelIsEnrolled = (
  obj: Record<string, any>
): boolean => {
  let result: boolean = false;

  // eslint-disable-next-line
  for (const [_, value] of Object.entries(obj)) {
    if (value.isSet) {
      result = true;
      break;
    }
  }

  return result;
};

export const fuzzySearch = (options: SelectSearchOption[]) => {
  const fuse = new Fuse(options, {
    keys: ["name", "groupName", "items.name"],
    threshold: 0.3,
  });

  return (value: any) => {
    if (!value.length) {
      return options;
    }

    return fuse.search(value).map((e) => ({ ...e.item }));
  };
};

export const getFirstTwoChars = (value: string) => {
  return value.substring(0, 2).toUpperCase();
};

export const colors = [
  "#FF6948",
  "#8FB8E5",
  "#05d24b",
  "#2196F3",
  "#00D1B2",
  "#D1C51B",
  "#FF3860",
  "#BA2F2A",
  "#600B6C",
  "#DD6EDD",
  "#70A14F",
  "#507181",
  "#AA5083",
  "#20CDD3",
  "#33E129",
  "#BF4680",
  "#50D2F0",
  "#F03272",
  "#5A69A2",
  "#EA286A",
  "#5AC688",
  "#B9975B",
  "#282528",
  "#FFDD57",
  "#B524D5",
  "#AA349F",
  "#30885A",
  "#572571",
  "#143A21",
  "#0E8DF0",
  "#7E4D5D",
  "#827146",
  "#7B4E83",
  "#7B05A4",
  "#292772",
  "#B34266",
  "#9B06D1",
  "#FB3130",
  "#346568",
  "#13182D",
  "#32052B",
  "#C5628B",
];

export const removeFirstAndLastQuotes = (str: string) => {
  var firstChar = str.charAt(0);
  var lastChar = str[str.length - 1];
  //double quotes
  if (firstChar && lastChar === String.fromCharCode(34)) {
    str = str.slice(1, -1);
  }
  //single quotes
  if (firstChar && lastChar === String.fromCharCode(39)) {
    str = str.slice(1, -1);
  }
  return str;
};

export const getReleaseInfo = () => {
  const { date, version } = settings;
  if (date === "DATE") {
    return {
      time: null,
      version,
    };
  }
  return {
    time: new Date(date).toDateString(),
    version,
  };
};

export const fillArray = (size: number, itemCallback: (el: number) => any) =>
  new Array(size).fill("").map((item, index) => itemCallback(index));

export const isInRange = (value: number, min: number, max: number) =>
  value >= min && value <= max;

export const getAppInfo = (
  appName: AppNames,
  clientCode: string,
  session: UserSessionI | null
) => {
  switch (appName) {
    case "backoffice":
      return {
        title: "Back Office",
        description: "Full-Lifecycle Warehouse and Product Management",
        type: "backoffice" as AppNames,
        icon: "Office" as IconValueType,
        href: session?.backOfficeURL || "",
      };

    case "pointofsale":
      return {
        title: "Point Of Sale",
        description:
          "Cloud-based POS with Integrated Payments and Offline Support",
        type: "pointofsale" as AppNames,
        icon: "POS-Terminal" as IconValueType,
        href: `${session?.brazilPosURL}?clientCode=${clientCode}&auth=cookie`,
      };

    case "servicebooking":
      return {
        title: "Service Booking",
        description:
          "All-in-one Service Shop Software with Work Orders and Booking Calendars",
        type: "servicebooking" as AppNames,
        icon: "Calendar" as IconValueType,
        href: `${session?.serviceAppURL}?clientCode=${clientCode}&auth=cookie`,
      };

    case "retrycrm":
      return {
        title: "Retry CRM",
        description: "Manage incoming mail and assign tasks",
        type: "retrycrm" as AppNames,
        icon: "Person-CRM" as IconValueType,
        href: "https://ems.retry.com/",
      };

    default:
      return null;
  }
};
