import classNames from "classnames";
import React from "react";

interface HelperTextProps {
  message: string;
  className?: string;
}

const HelperText: React.FC<HelperTextProps> = ({ message, className }) => {
  const cls = classNames(
    "dark:text-white text-gray-900 inline-block",
    className
  );

  return <small className={cls}>{message}</small>;
};

export default HelperText;
