import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FormLayout from "../components/layouts/FormLayout";
import MainLayout from "../components/layouts/MainLayout";
import { useAuthState } from "../context/auth";
import Server from "../service/Server";

const AccountSwitchPage: React.FC = () => {
  const { handleChange, setAccountAdminBaseUrl, baseUrl, setUserAPIBaseUrl } =
    useAuthState();

  const { clientCode } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (clientCode) {
      getCurrentSession(clientCode);
    }

    // eslint-disable-next-line
  }, [clientCode]);

  const getCurrentSession = async (clientCode: string): Promise<void> => {
    try {
      const { data } = await Server.getServiceEndPoint({
        clientCode,
        request: "getServiceEndpoints",
      });
      if (data.status.responseStatus === "error") {
        handleChange({
          baseUrl,
          clientCode,
          session: null,
          sessionKey: "",
          isAuthenticated: false,
        });
        navigate("/");
      } else {
        const baseUrl: string = data.records[0].auth.url
          ? data.records[0].auth.url
          : "";
        const adminBaseUrl: string = data.records[0]["account-admin"].url
          ? data.records[0]["account-admin"].url
          : "";
        setAccountAdminBaseUrl(adminBaseUrl);
        const userBaseUrl: string = data.records[0]["user"].url
          ? data.records[0]["user"].url
          : "";
        setUserAPIBaseUrl(userBaseUrl);

        try {
          const { data } = await Server.getSession({ baseUrl, clientCode });
          handleChange({
            session: data.data,
            clientCode,
            baseUrl,
            sessionKey: data.data.session?.sessionKey
              ? data.data.session?.sessionKey
              : "",
            isAuthenticated: true,
          });
          navigate(`/${clientCode}/apps`);
        } catch (error) {
          handleChange({
            baseUrl,
            clientCode,
            session: null,
            sessionKey: "",
            isAuthenticated: false,
          });
          navigate("/");
        }
      }
    } catch (error) {
      handleChange({
        baseUrl,
        clientCode,
        session: null,
        sessionKey: "",
        isAuthenticated: false,
      });
      navigate("/");
    }
  };

  return (
    <MainLayout>
      <FormLayout showSpinner />
    </MainLayout>
  );
};

export default AccountSwitchPage;
