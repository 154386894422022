import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { noAccountError, useAuthState } from "../../context/auth";
import useFormHandler from "../../hooks/useFormHandler";
import FormLayout from "../layouts/FormLayout";
import { iNotification } from "react-notifications-component";
import { notify } from "../../utils";
import Server from "../../service/Server";
import { ResetPasswordRequestI } from "../../types/index";
import TextInput from "../elements/TextInput";
import classNames from "classnames";
import Button from "../elements/Button";
import ErplyIcon from "../elements/ErplyIcon";
import Alert from "../elements/Alert";

const ResetPassword: React.FC = () => {
  const [clientCode, setCode] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isToken, setIsToken] = useState(false);
  const [resetCode, setResetCode] = useState("");
  const [token, setToken] = useState("");
  const [username, setUsername] = useState("");

  const navigate = useNavigate();

  const {
    clientCode: userCode,
    baseUrl,
    isAuthenticated,
    session,
    sessionKey,
    handleChange: setAuth,
    username: user,
    setLoginAttemptCount,
  } = useAuthState();

  const { handleChange, newPassword } = useFormHandler({
    username,
    clientCode: "",
    sessionKey: "",
    password: "",
    oldPassword: "",
    newPassword: "",
    twoFactorKey: "",
  });

  useEffect(() => {
    setCode(userCode);
  }, [userCode]);

  useEffect(() => {
    setUsername(user);
  }, [user]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;

    if (name === "newPassword") {
      handleChange(value, name);
    }

    if (name === "username") {
      setUsername(value);
    }

    if (name === "clientCode") {
      setCode(value);
    }

    if (name === "resetCode") {
      setResetCode(value);
    }
  };

  const handleGetResetPasswordToken = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    setLoading(true);
    setLoginAttemptCount(0);

    try {
      const { data } = await Server.getServiceEndPoint({
        clientCode,
        request: "getServiceEndpoints",
      });
      if (data.status.responseStatus === "error") {
        notify({
          message: noAccountError(),
          type: "danger",
        } as iNotification);
        setLoading(false);
        setIsToken(false);
      } else {
        const url: string = data.records[0].auth.url
          ? data.records[0].auth.url
          : "";
        try {
          const {
            data: { data },
          } = await Server.getRestPasswordToken({
            baseUrl: url,
            clientCode,
            user: username,
          });
          setToken(data.token);
          setAuth({
            session,
            clientCode,
            sessionKey,
            isAuthenticated,
            baseUrl: url,
          });
          notify({
            message: `Reset Code have been sent to your email`,
            type: "success",
          } as iNotification);
          setLoading(false);
          setIsToken(true);
        } catch (err: any) {
          if (err.response) {
            const {
              response: { data },
            } = err;
            if (data) {
              notify({
                message: `${data.status.errorDescription}`,
                type: "danger",
              } as iNotification);
            } else {
              notify({
                message: "Error performing operation. Please try again",
                type: "danger",
              } as iNotification);
            }
          } else {
            notify({
              message: "Error performing operation. Please try again",
              type: "danger",
            } as iNotification);
          }
          setLoading(false);
          setIsToken(false);
        }
      }
    } catch (error) {
      notify({
        message: noAccountError(),
        type: "danger",
      } as iNotification);
      setLoading(false);
      setIsToken(false);
    }
  };

  const resetPassword = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    setLoading(true);
    try {
      const requestData: ResetPasswordRequestI = {
        value: {
          token,
          key: resetCode,
          newPassword,
        },
        baseUrl,
        clientCode,
      };

      await Server.resetPassword(requestData);
      setLoading(false);
      setIsToken(false);
      setResetCode("");
      handleChange("", "newPassword");
      notify({
        message: `Password reset successful`,
        type: "success",
      } as iNotification);
      navigate("/");
    } catch (err: any) {
      if (err.response) {
        const {
          response: { data },
        } = err;
        if (data) {
          notify({
            message: `${data.status.errorDescription}`,
            type: "danger",
          } as iNotification);
        } else {
          notify({
            message: "Error performing operation. Please try again",
            type: "danger",
          } as iNotification);
        }
      } else {
        notify({
          message: "Error performing operation. Please try again",
          type: "danger",
        } as iNotification);
      }
      setLoading(false);
    }
  };

  const btnCls = classNames(
    "dark:bg-white btn-block bg-erply-success-200 dark:text-gray-900 text-white border-0 dark:hover:text-gray-900 dark:hover:bg-[#E6E6E6] hover:bg-[#4AD17A] active:bg-[#35A95F] dark:active:bg-erply-dark-100",
    isLoading && "btn-disabled loading"
  );

  return (
    <FormLayout isLoading={isLoading} title={"Reset Password"}>
      <>
        {!isToken ? (
          <form onSubmit={handleGetResetPasswordToken} className='sm:w-96 w-80'>
            <TextInput
              value={clientCode}
              onChange={onChange}
              placeholder='Client code'
              name='clientCode'
              className='mb-5'
              required
            />
            <TextInput
              name='username'
              value={username}
              onChange={onChange}
              placeholder='Email/Username'
              className='mb-[30px]'
              required
            />
            <div className='flex space-x-5'>
              <div className=''>
                <Button
                  className='btn-block bg-erply-dark-20 border-0 text-white dark:text-gray-900 dark:hover:text-white'
                  onClick={() => navigate("/")}
                  type='button'
                >
                  <ErplyIcon icon='Arrow-Left' />
                </Button>
              </div>
              <div className='w-full'>
                <Button className={btnCls} type='submit' disabled={isLoading}>
                  Confirm
                </Button>
              </div>
            </div>
          </form>
        ) : (
          <form onSubmit={resetPassword} className='sm:w-96 w-80'>
            <Alert
              className=' bg-[#e3fcef] mb-5'
              icon='Checkmark-Round'
              iconColor='text-green-800'
              centered={false}
            >
              <div className='flex flex-col gap-2'>
                <span>
                  If we find user {username} in account {clientCode}, we will
                  email you a reset code.
                </span>
                <span>
                  Please check your mailbox. Click the link in the email, or
                  copy and paste the reset code.
                </span>
                <span>
                  If you do not get an email in 5 minutes, please make sure that
                  you have entered your username and account number correctly.
                </span>
              </div>
            </Alert>
            <TextInput
              name='resetCode'
              placeholder='Reset code'
              autoComplete='off'
              required
              value={resetCode}
              onChange={onChange}
              className='mb-5'
            />
            <TextInput
              name='newPassword'
              placeholder='New password'
              autoComplete='off'
              type='password'
              value={newPassword}
              onChange={onChange}
              className='mb-[30px]'
              required
            />
            <div className='flex space-x-5'>
              <div className=''>
                <Button
                  className='btn-block bg-erply-dark-20 border-0 text-white dark:text-gray-900 dark:hover:text-white'
                  onClick={() => navigate("/")}
                  type='button'
                >
                  <ErplyIcon icon='Arrow-Left' />
                </Button>
              </div>
              <div className='w-full'>
                <Button className={btnCls} type='submit' disabled={isLoading}>
                  Reset Password
                </Button>
              </div>
            </div>
          </form>
        )}
      </>
    </FormLayout>
  );
};

export default ResetPassword;
