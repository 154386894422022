import React, { useMemo, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../elements/Button";
import TextInput from "../../elements/TextInput";
import FormLayout from "../../layouts/FormLayout";
import { iNotification } from "react-notifications-component";
import countryList from "react-select-country-list";
import { getErrorMessage, notify, requestERPLYData } from "../../../utils";
import { noAccountError, useAuthState } from "../../../context/auth";
import Server, { partnerKey } from "../../../service/Server";
import { ErplyRequestData, SessionRequestI } from "../../../types/index";
import classNames from "classnames";
import Select from "react-dropdown-select";
import ErplyIcon from "../../elements/ErplyIcon";

interface FormState {
  country: {
    name: string;
    value: string;
  }[];
  email: string;
}

const initialState: FormState = {
  country: [{ name: "United States", value: "US" }],
  email: "",
};

const DemoAccount: React.FC = () => {
  const [formState, setState] = useState<FormState>(initialState);
  const [errors, setErrors] = useState<FormState>(initialState);
  const [isLoading, setLoading] = useState(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);

  const {
    handleChange,
    isAuthenticated,
    session,
    sessionKey,
    baseUrl,
    setAccountAdminBaseUrl,
    setUserAPIBaseUrl,
  } = useAuthState();

  const navigate = useNavigate();

  const countryOptions = useMemo(
    () =>
      countryList()
        .getData()
        .map((e) => ({ name: e.label, value: e.value })),
    []
  );

  useEffect(() => {
    const dropdownContent = document.querySelector(
      ".react-dropdown-select-content"
    );

    if (dropdownContent) {
      dropdownContent.setAttribute("data-testid", "demo-account-country");
    }
  }, []);

  useEffect(() => {
    if (isDropDownOpen) {
      const dropdownItems = document.querySelectorAll(
        ".react-dropdown-select-item"
      );

      if (dropdownItems.length) {
        dropdownItems.forEach((e) => {
          const country = e.getAttribute("aria-label");
          if (country) {
            e.setAttribute("data-test-key", country);
          }
        });
      }
    }
  }, [isDropDownOpen]);

  const onChange = (value: string | number, name: string) => {
    if (value) {
      setErrors({ ...errors, [name]: "" });
    } else {
      setErrors({ ...errors, [name]: "This field is required" });
    }

    setState({ ...formState, [name]: value });
  };

  const isErrorInData = (): boolean => {
    let isError = false;

    const errorsObject = JSON.parse(JSON.stringify(errors)) as FormState;

    if (!formState.email) {
      isError = true;
      errorsObject.email = "Email is required";
    }

    if (!formState.country.length) {
      isError = true;
      // errorsObject.country = "Country is required";
    }

    if (isError) {
      setErrors({ ...errors, ...errorsObject });
    }

    return isError;
  };

  const handleLogin = async (
    clientCode: string,
    username: string,
    password: string
  ): Promise<void> => {
    localStorage.setItem("SSO_CLIENT", "");
    try {
      setLoading(true);
      const { data } = await Server.getServiceEndPoint({
        clientCode,
        request: "getServiceEndpoints",
      });
      if (data.status.responseStatus === "error") {
        notify({
          message: noAccountError(),
          type: "danger",
        } as iNotification);
        setLoading(false);
        navigate("/");
      } else {
        const baseUrl: string = data.records[0].auth.url
          ? data.records[0].auth.url
          : "";
        const accAdminBaseUrl: string = data.records[0]["account-admin"].url
          ? data.records[0]["account-admin"].url
          : "";
        setAccountAdminBaseUrl(accAdminBaseUrl);
        const userBaseUrl: string = data.records[0]["user"].url
          ? data.records[0]["user"].url
          : "";
        setUserAPIBaseUrl(userBaseUrl);
        try {
          const request: SessionRequestI = {
            value: {
              password,
              username,
              sessionLength: 43200,
            },
            clientCode,
            baseUrl,
          };
          const { data } = await Server.createSession(request);

          handleChange({
            session: data.data,
            clientCode,
            baseUrl,
            sessionKey: data.data.session?.sessionKey
              ? data.data.session?.sessionKey
              : "",
            isAuthenticated: true,
          });
          setLoading(false);
          navigate(`/${clientCode}/apps`);
        } catch (err: any) {
          navigate("/");
          if (err.response) {
            const {
              response: { data },
            } = err;
            if (data) {
              notify({
                message: `${data.status.errorDescription}`,
                type: "danger",
              } as iNotification);
            } else {
              notify({
                message: "Error logging to account. Please try again",
                type: "danger",
              } as iNotification);
            }
          } else {
            notify({
              message: "Error logging to account. Please try again",
              type: "danger",
            } as iNotification);
          }
          setLoading(false);
        }
      }
    } catch (error) {
      notify({
        message: noAccountError(),
        type: "danger",
      } as iNotification);
      setLoading(false);
    }
  };

  const handleSignUp = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isErrorInData()) return;

    const request: ErplyRequestData = {
      companyName: "Demo Company",
      firstName: "Demo",
      lastName: "User",
      phone: "555-555-555",
      email: formState.email,
      countryCode: formState.country[0].value,
      password: "demo1234",
      demo: true,
      username: formState.email,
      request: "createInstallation",
      sendEmail: 1,
      partnerKey,
    };

    setLoading(true);

    try {
      const { data } = await requestERPLYData(request, true);
      if (data.status.responseStatus === "error") {
        notify({
          message: getErrorMessage(data.status.errorCode),
          type: "danger",
        } as iNotification);
        setLoading(false);
      } else {
        const userInfo = {
          clientCode: data.records[0].clientCode,
          username: data.records[0].username,
        };

        handleChange({
          session,
          sessionKey,
          isAuthenticated,
          baseUrl,
          ...userInfo,
        });
        handleLogin(userInfo.clientCode, userInfo.username, request.password);
        notify({
          message:
            "Demo account created successfully. Check your email for login details",
          type: "success",
        } as iNotification);
      }
    } catch (error) {
      notify({
        message: "Error performing operation. Please try again",
        type: "danger",
      } as iNotification);
      setLoading(false);
    }
  };

  const btnCls = classNames(
    "dark:bg-white btn-block bg-erply-success-200 dark:text-gray-900 text-white border-0 dark:hover:text-gray-900 dark:hover:bg-[#E6E6E6] hover:bg-[#4AD17A] active:bg-[#35A95F] dark:active:bg-erply-dark-100 mb-[30px]",
    isLoading && "btn-disabled loading"
  );

  return (
    <FormLayout
      title='Try our demo'
      isLoading={isLoading}
      testIdForm='try-our-demo-form'
      testIdFormHeader='try-our-demo-hearder'
    >
      <form onSubmit={handleSignUp} className='sm:w-96 w-80'>
        <div className='relative'>
          {formState.country ? (
            <label
              htmlFor='country'
              className='absolute left-4 top-[-17px] label-text text-[10px] text-[#7A838E] uppercase z-50'
            >
              Country
            </label>
          ) : null}
          {/* <SelectSearch
            onChange={(seleceted) => {
              const opt: any = seleceted as SelectedOptionValue;
              onChange(opt?.value || opt, "country");
            }}
            options={countryOptions}
            value={formState.country}
            search
            emptyMessage='Not found'
            filterOptions={fuzzySearch}
            id='country'
          /> */}
          <Select
            options={countryOptions}
            values={formState.country}
            required
            labelField='name'
            searchBy='name'
            onChange={(value) => {
              setState({ ...formState, country: value });
            }}
            dropdownHandleRenderer={({ state }) => {
              return (
                <ErplyIcon
                  icon={
                    state.dropdown ? "Chevron-Up-Closed" : "Chevron-Down-Closed"
                  }
                />
              );
            }}
            onDropdownClose={() => setIsDropDownOpen((prev) => !prev)}
            onDropdownOpen={() => setIsDropDownOpen((prev) => !prev)}
          />
        </div>
        <TextInput
          placeholder='Email'
          type='email'
          value={formState.email}
          onChange={(e) => {
            const { value } = e.target;
            onChange(value, "email");
          }}
          className='mb-[30px]'
          error={!!errors.email}
          data-testid='demo-account-email'
          required
        />
        <Button
          className={btnCls}
          type='submit'
          disabled={isLoading}
          data-testid='quick-demo-button'
        >
          Quick Demo
        </Button>

        <p className='text-sm dark:text-white text-gray-900 '>
          Already have an account?{" "}
          <Link
            to='/'
            className='text-erply-light-200 hover:underline cursor-pointer'
            data-testid='sign-in-here-link'
          >
            Sign in here
          </Link>{" "}
        </p>
      </form>
    </FormLayout>
  );
};

export default DemoAccount;
