import React from "react";
import { useAuthState } from "../context/auth";
import classNames from "classnames";
import PasswordSettings from "../components/sections/Authenticated/PasswordSettings";
import Title from "../components/elements/Title";
import useWindowSize from "../hooks/useWindowSize";
import { isInRange } from "../utils";

const PasswordSettingPage: React.FC = () => {
  const { isDarkTheme } = useAuthState();

  const { width } = useWindowSize();

  const isBetween = isInRange(width || 0, 640, 767);

  const cls = classNames(
    "h-full overflow-x-hidden w-screen",
    isDarkTheme ? "app-dark text-white" : "app-light"
  );
  return (
    <div className={cls}>
      <Title pageTilte='Password' isBetween={isBetween} />
      <div
        className={
          isBetween
            ? "overflow-x-hidden sm:landscape:ml-0 md:landscape:ml-72 md:landscape:mr-6"
            : "overflow-x-hidden sm:ml-52 sm:landscape:ml-0 md:landscape:ml-72 md:landscape:mr-6"
        }
      >
        <PasswordSettings />
      </div>
    </div>
  );
};

export default PasswordSettingPage;
