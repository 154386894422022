import React, { useMemo } from "react";
import { BsCircleHalf } from "react-icons/bs";
import { FiSun, FiMoon } from "react-icons/fi";
import { useAuthState } from "../../context/auth";
import ErplyIcon from "../elements/ErplyIcon";

const ThemeSwitcher: React.FC = () => {
  const { mode, setMode } = useAuthState();

  const display = useMemo(() => {
    switch (mode) {
      case "dark":
        return {
          text: "Dark",
          icon: <FiMoon />,
        };

      case "light":
        return {
          text: "Light",
          icon: <FiSun />,
        };

      default:
        return {
          text: "Auto",
          icon: <BsCircleHalf />,
        };
    }
  }, [mode]);

  return (
    <div className='dropdown sm:top-2 top-16 absolute right-4 dropdown-end sm:block hidden'>
      <label
        tabIndex={0}
        className='btn btn-sm m-1 dark:bg-erply-dark-50 dark:text-white bg-white text-gray-600 border-slate-200 dark:hover:bg-erply-dark-50 hover:bg-white gap-3 capitalize dark:border-slate-600 font-light animate-none rounded-md btn-drop'
        data-testid='theme-dropdown'
      >
        {display.icon}
        <span>{display.text}</span>
        <ErplyIcon icon='Chevron-Down' />
      </label>

      <div
        tabIndex={0}
        className='dropdown-content shadow  p-2 w-40 dark:bg-erply-dark-50 bg-white dark:text-white text-gray-600 outline-none animate-none rounded-md text-sm'
      >
        <li
          className={`flex items-center justify-between cursor-pointer space-x-3 hover:bg-erply-light-200 p-1 rounded-md mb-1 hover:text-white ${
            mode === "system" && "bg-erply-light-200 text-white"
          }`}
          onClick={() => setMode("system")}
          data-test-key='theme-auto'
        >
          Auto
        </li>
        <li
          className={`flex items-center justify-between cursor-pointer space-x-3 hover:bg-erply-light-200 p-1 rounded-md mb-1 hover:text-white ${
            mode === "light" && "bg-erply-light-200 text-white"
          }`}
          onClick={() => setMode("light")}
          data-test-key='theme-light'
        >
          Light
        </li>
        <li
          onClick={() => setMode("dark")}
          className={`flex items-center justify-between cursor-pointer space-x-3 hover:bg-erply-light-200 p-1 rounded-md mb-1 hover:text-white ${
            mode === "dark" && "bg-erply-light-200 text-white"
          }`}
          data-test-key='theme-dark'
        >
          Dark
        </li>
      </div>
    </div>
  );
};

export default ThemeSwitcher;
