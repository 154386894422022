import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FormLayout from "../components/layouts/FormLayout";
import MainLayout from "../components/layouts/MainLayout";
import { noAccountError, useAuthState } from "../context/auth";
import { notify } from "../utils";
import { iNotification } from "react-notifications-component";
import Server from "../service/Server";
import { UserSessionI, TwoFactorRequestI } from "../types/index";
import Button from "../components/elements/Button";
import ErplyIcon from "../components/elements/ErplyIcon";

const ConfirmEmailEnrol: React.FC = () => {
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const { handleChange, setAccountAdminBaseUrl, setUserAPIBaseUrl } =
    useAuthState();

  const navigate = useNavigate();

  useEffect(() => {
    let urlSearchParams = new URLSearchParams(window.location.search);
    let clientCode = urlSearchParams.get("clientCode");
    let key = urlSearchParams.get("key");
    let token = urlSearchParams.get("token");

    if (token && clientCode && key) {
      getCurrentSessionAndConfirmEmail(clientCode, key, token);
    } else {
      navigate("/");
    }

    // eslint-disable-next-line
  }, []);

  const getCurrentSessionAndConfirmEmail = async (
    clientCode: string,
    twoFactorKey: string,
    twoFactorToken: string
  ): Promise<void> => {
    setLoading(true);
    try {
      const { data } = await Server.getServiceEndPoint({
        clientCode,
        request: "getServiceEndpoints",
      });
      if (data.status.responseStatus === "error") {
        notify({
          message: noAccountError(),
          type: "danger",
        } as iNotification);
        setLoading(false);
        setError(true);
      } else {
        const baseUrl: string = data.records[0].auth.url
          ? data.records[0].auth.url
          : "";
        const adminBaseUrl: string = data.records[0]["account-admin"].url
          ? data.records[0]["account-admin"].url
          : "";
        setAccountAdminBaseUrl(adminBaseUrl);
        const userBaseUrl: string = data.records[0]["user"].url
          ? data.records[0]["user"].url
          : "";
        setUserAPIBaseUrl(userBaseUrl);
        try {
          const { data } = await Server.getSession({ baseUrl, clientCode });

          const sessionKey: string = data.data.session?.sessionKey
            ? data.data.session?.sessionKey
            : "";

          confirmEmail(
            baseUrl,
            twoFactorKey,
            twoFactorToken,
            sessionKey,
            clientCode,
            data.data
          );
        } catch (err: any) {
          if (err.response) {
            const {
              response: { data },
            } = err;
            if (data) {
              notify({
                message: `${data.status.errorDescription}`,
                type: "danger",
              } as iNotification);
            } else {
              notify({
                message: "Error performing operation. Please try again",
                type: "danger",
              } as iNotification);
            }
          } else {
            notify({
              message: "Error performing operation. Please try again",
              type: "danger",
            } as iNotification);
          }
          setLoading(false);
          setError(true);
        }
      }
    } catch (err: any) {
      if (err.response) {
        const {
          response: { data },
        } = err;
        if (data) {
          notify({
            message: `${data.status.errorDescription}`,
            type: "danger",
          } as iNotification);
        } else {
          notify({
            message: noAccountError(),
            type: "danger",
          } as iNotification);
        }
      } else {
        notify({
          message: noAccountError(),
          type: "danger",
        } as iNotification);
      }
      setLoading(false);
    }
  };

  const confirmEmail = async (
    baseUrl: string,
    twoFactorKey: string,
    twoFactorToken: string,
    sessionKey: string,
    clientCode: string,
    session: UserSessionI
  ) => {
    try {
      const requestData: TwoFactorRequestI = {
        value: {
          twoFactorKey,
          twoFactorToken,
        },
        baseUrl,
        clientCode,
        sessionKey,
      };
      await Server.confirmEmail(requestData);
      handleChange({
        session,
        clientCode,
        baseUrl,
        sessionKey,
        isAuthenticated: true,
      });
      navigate(`/${clientCode}/apps`);
      notify({
        message: `Email updated successfully`,
        type: "success",
      } as iNotification);
      setLoading(false);
    } catch (err: any) {
      if (err.response) {
        const {
          response: { data },
        } = err;
        if (data) {
          notify({
            message: `${data.status.errorDescription}`,
            type: "danger",
          } as iNotification);
        } else {
          notify({
            message: "Error performing operation. Please try again",
            type: "danger",
          } as iNotification);
        }
      } else {
        notify({
          message: "Error performing operation. Please try again",
          type: "danger",
        } as iNotification);
      }
      setLoading(false);
      setError(true);
    }
  };

  return (
    <MainLayout>
      <FormLayout
        showSpinner={isLoading}
        title='Email Authentication Confirmation'
      >
        {isError ? (
          <div className='flex sm:w-96 w-80'>
            <Button
              className='btn-block gap-2'
              onClick={() => {
                setError(false);
                navigate("/");
              }}
            >
              <ErplyIcon icon='Arrow-Left' />
              Back
            </Button>
          </div>
        ) : null}
      </FormLayout>
    </MainLayout>
  );
};

export default ConfirmEmailEnrol;
