import React, { useState, useEffect } from "react";
import LoginTypeSelector from "../LoginTypeSelector";
import { loginOptions } from "../../../types";
import { UsernameLogin } from "./UsernameLogin";
import SSOLogin from "./SSOLogin";
import FormLayout from "../../layouts/FormLayout";
import { noAccountError, useAuthState } from "../../../context/auth";
import useFormHandler from "../../../hooks/useFormHandler";
import { iNotification } from "react-notifications-component";
import Server from "../../../service/Server";
import { notify } from "../../../utils";
import { SessionRequestI } from "../../../types/index";
import { useNavigate } from "react-router-dom";

interface LoginProps {
  toggleChangePassword: (state: boolean) => void;
  setPasswordResetToken: React.Dispatch<React.SetStateAction<string>>;
  togglRotatePassword: (state: boolean) => void;
}

const Login: React.FC<LoginProps> = ({
  toggleChangePassword,
  setPasswordResetToken,
  togglRotatePassword,
}) => {
  const [loginType, setLoginType] = useState<loginOptions>("username");

  const [errors, setErrors] = useState({
    clientCode: "",
    username: "",
    password: "",
  });
  const [isLoading, setLoading] = useState(false);
  const [clientCode, setCode] = useState("");
  const [username, setUsername] = useState("");

  const navigate = useNavigate();
  const {
    handleChange: setAuth,
    clientCode: userCode,
    username: user,
    setAccountAdminBaseUrl,
    setUserAPIBaseUrl,
    setLoginAttemptCount,
  } = useAuthState();

  const { password, handleChange } = useFormHandler({
    username,
    clientCode,
    sessionKey: "",
    password: "",
    oldPassword: "",
    newPassword: "",
    twoFactorKey: "",
  });

  useEffect(() => {
    setCode(userCode);
  }, [userCode]);

  useEffect(() => {
    setUsername(user);
  }, [user]);

  useEffect(() => {
    const selectType = localStorage.getItem("loginType") as loginOptions;
    if (selectType) {
      setLoginType(selectType);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("loginType", loginType);
  }, [loginType]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;

    handleChange(value, name);

    if (name === "username") {
      if (value) {
        setErrors({ ...errors, [name]: "" });
      } else {
        setErrors({ ...errors, [name]: "User name is required" });
      }
      setUsername(value);
    }

    if (name === "password") {
      if (value) {
        setErrors({ ...errors, [name]: "" });
      } else {
        setErrors({ ...errors, [name]: "Password is required" });
      }
    }

    if (name === "clientCode") {
      if (value) {
        setErrors({ ...errors, [name]: "" });
      } else {
        setErrors({ ...errors, [name]: "Client code is required" });
      }
      setCode(value);
    }
  };

  const isErrorInData = (): boolean => {
    let isError = false;
    const errorsObject = {
      clientCode: "",
      password: "",
      username: "",
    };

    if (!clientCode) {
      isError = true;
      errorsObject.clientCode = "Client code is required";
    }

    if (!password) {
      isError = true;
      errorsObject.password = "Password is required";
    }

    if (!username) {
      isError = true;
      errorsObject.username = "User name is required";
    }

    if (isError) {
      setErrors({ ...errors, ...errorsObject });
    }

    return isError;
  };

  const handleLogin = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    if (isErrorInData()) return;
    // localStorage.setItem(`${clientCode}_TARGET`, target);
    localStorage.setItem("SSO_CLIENT", "");
    try {
      setLoading(true);
      const { data } = await Server.getServiceEndPoint({
        clientCode,
        request: "getServiceEndpoints",
      });
      if (data.status.responseStatus === "error") {
        notify({
          message: noAccountError(),
          type: "danger",
        } as iNotification);
        setLoading(false);
        setLoginAttemptCount((prev) => prev + 1);
        setAuth({
          session: null,
          clientCode,
          baseUrl: "",
          sessionKey: "",
          isAuthenticated: false,
          username,
        });
      } else {
        const baseUrl: string = data.records[0].auth.url
          ? data.records[0].auth.url
          : "";
        const accAdminBaseUrl: string = data.records[0]["account-admin"].url
          ? data.records[0]["account-admin"].url
          : "";
        setAccountAdminBaseUrl(accAdminBaseUrl);
        const userBaseUrl: string = data.records[0]["user"].url
          ? data.records[0]["user"].url
          : "";
        setUserAPIBaseUrl(userBaseUrl);
        try {
          const request: SessionRequestI = {
            value: {
              password,
              username,
              sessionLength: 43200,
            },
            clientCode,
            baseUrl,
          };
          const { data } = await Server.createSession(request);
          setLoginAttemptCount(0);

          if (
            data.data.responseType &&
            data.data.responseType === "RENEW-PASSWORD-TOKEN" &&
            data.data.passwordRenewalToken
          ) {
            toggleChangePassword(true);
            togglRotatePassword(true);
            setPasswordResetToken(data.data.passwordRenewalToken);
            setLoading(false);
            setAuth({
              session: data.data,
              clientCode,
              baseUrl,
              sessionKey: data.data.session?.sessionKey
                ? data.data.session?.sessionKey
                : "",
              isAuthenticated: false,
            });
          } else if (data.data.session?.user.isPasswordExpired) {
            toggleChangePassword(true);
            togglRotatePassword(false);
            setPasswordResetToken("");
            setAuth({
              session: data.data,
              clientCode,
              baseUrl,
              sessionKey: data.data.session?.sessionKey
                ? data.data.session?.sessionKey
                : "",
              isAuthenticated: false,
            });
          } else if (data.data.twoFactorEnabled) {
            setAuth({
              session: data.data,
              clientCode,
              baseUrl,
              sessionKey: data.data.session?.sessionKey
                ? data.data.session?.sessionKey
                : "",
              isAuthenticated: false,
            });
            navigate("/two-factor");
          } else {
            setAuth({
              session: data.data,
              clientCode,
              baseUrl,
              sessionKey: data.data.session?.sessionKey
                ? data.data.session?.sessionKey
                : "",
              isAuthenticated: true,
            });
            setLoading(false);
            // localStorage.setItem("CLIENT", clientCode);
            navigate(`/${clientCode}/apps`);
          }
        } catch (err: any) {
          setLoginAttemptCount((prev) => prev + 1);
          setAuth({
            session: null,
            clientCode,
            baseUrl: "",
            sessionKey: "",
            isAuthenticated: false,
            username,
          });
          if (err.response) {
            const {
              response: { data },
            } = err;
            if (data) {
              notify({
                message: `${data.status.errorDescription}`,
                type: "danger",
              } as iNotification);
            } else {
              notify({
                message: "Error performing operation. Please try again",
                type: "danger",
              } as iNotification);
            }
          } else {
            notify({
              message: "Error performing operation. Please try again",
              type: "danger",
            } as iNotification);
          }
          setLoading(false);
        }
      }
    } catch (error) {
      notify({
        message: noAccountError(),
        type: "danger",
      } as iNotification);
      setLoading(false);
      setLoginAttemptCount((prev) => prev + 1);
      setAuth({
        session: null,
        clientCode,
        baseUrl: "",
        sessionKey: "",
        isAuthenticated: false,
        username,
      });
    }
  };

  const renderLoginType = (type: loginOptions): JSX.Element => {
    switch (type) {
      case "username":
        return (
          <UsernameLogin
            errors={errors}
            username={username}
            password={password}
            onChange={onChange}
            handleLogin={handleLogin}
            clientCode={clientCode}
            isLoading={isLoading}
          />
        );

      case "SSO":
        return <SSOLogin />;

      default:
        return (
          <UsernameLogin
            errors={errors}
            username={username}
            password={password}
            onChange={onChange}
            handleLogin={handleLogin}
            clientCode={clientCode}
            isLoading={isLoading}
          />
        );
    }
  };

  return (
    <FormLayout
      title='Login'
      isLoading={isLoading}
      testIdForm='login-form'
      testIdFormHeader='login-form-header'
    >
      <LoginTypeSelector loginType={loginType} setLoginType={setLoginType} />
      {renderLoginType(loginType)}
    </FormLayout>
  );
};

export default Login;
