import makeStore from "../makeStore";
import reducer from "./reducer";
import { State } from "./interface";

const initialState: State = {
  prompt: "",
  title: "",
  isOpen: false,
  proceed: () => {},
  confirmButtonTitle: "",
  cancelButtonTitle: "",
};

const [ConfirmationProvider, useConfirmationDispatch, useConfirmation] =
  makeStore(reducer, initialState);

export { ConfirmationProvider, useConfirmationDispatch, useConfirmation };
