import React from "react";
import MainLayout from "../components/layouts/MainLayout";
import ResetPassword from "../components/sections/ResetPassword";

const ResetPasswordPage: React.FC = () => {
  return (
    <MainLayout>
      <ResetPassword />
    </MainLayout>
  );
};

export default ResetPasswordPage;
