import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthState } from "../../../context/auth";
import Button from "../../elements/Button";
import TextInput from "../../elements/TextInput";
import classNames from "classnames";
import Alert from "../../elements/Alert";

interface UsernameLoginProps {
  errors: {
    clientCode: string;
    username: string;
    password: string;
  };
  clientCode: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  username: string;
  password: string;
  handleLogin: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
  isLoading: boolean;
}

export const UsernameLogin: React.FC<UsernameLoginProps> = ({
  errors,
  clientCode,
  onChange,
  username,
  password,
  handleLogin,
  isLoading,
}) => {
  const navigate = useNavigate();

  const { hideSignUp, layout, loginAttemptCount } = useAuthState();

  const btnCls = classNames(
    "dark:bg-white btn-block bg-erply-success-200 dark:text-gray-900 text-white  mb-[30px] border-0 dark:hover:text-gray-900 dark:hover:bg-[#E6E6E6] hover:bg-[#4AD17A] active:bg-[#35A95F] dark:active:bg-erply-dark-100",
    isLoading && "btn-disabled loading"
  );

  return (
    <form onSubmit={handleLogin} className='sm:w-96 w-80'>
      <TextInput
        placeholder='Client code'
        value={clientCode}
        onChange={onChange}
        error={!!errors.clientCode}
        name='clientCode'
        className='mb-[20px]'
        data-testid='client-code'
        required
      />
      <TextInput
        name='username'
        placeholder='Username'
        value={username}
        onChange={onChange}
        error={!!errors.username}
        className='mb-[20px]'
        data-testid='username'
        required
      />

      <TextInput
        placeholder='Password'
        error={!!errors.password}
        value={password}
        onChange={onChange}
        name='password'
        type='password'
        className='mb-[20px]'
        data-testid='password'
        required
      />

      <Link
        to='/reset-password'
        className='text-erply-light-200 hover:underline block mb-[10px] text-sm'
        data-testid='forget-password-link'
      >
        Forgot Password ?
      </Link>
      <Button
        className={btnCls}
        type='submit'
        disabled={isLoading}
        data-testid='login-button'
      >
        Login
      </Button>
      {loginAttemptCount >= 3 && (
        <Alert
          message='Having trouble logging in? We can email you a password reset code.'
          centered={false}
          icon='Important-Filled'
          className='bg-[#FFFAE8] cursor-pointer'
          iconColor='text-yellow-600'
          onClick={() => navigate("/reset-password")}
        />
      )}
      {!hideSignUp ? (
        <>
          <hr className=' dark:border-erply-dark-200 mb-[30px]' />
          {layout === "generic" ? (
            <p className='text-sm dark:text-white text-gray-900 m-0'>
              Don’t have an account?{" "}
              <Link
                to='/sign-up'
                className='text-erply-light-200 hover:underline cursor-pointer'
                data-testid='create-full-account-link'
              >
                Create free account
              </Link>{" "}
              or{" "}
              <Link
                to='/demo-account'
                className='text-erply-light-200 hover:underline cursor-pointer'
                data-testid='demo-account-link'
              >
                Try our demo
              </Link>
            </p>
          ) : (
            <>
              <p className='mb-4 text-sm dark:text-white text-gray-900 m-0'>
                Don’t have an account?
              </p>
              <Button
                className='dark:bg-white btn-block bg-erply-light-200 dark:text-gray-900 text-white dark:hover:text-white mb-5 border-0 dark:hover:bg-gray-900 '
                onClick={() => navigate("/sign-up")}
                type='button'
                data-testid='create-full-account-link'
              >
                Create Free Account
              </Button>
              <Button
                className='bg-erply-dark-200 text-white btn-block mb-10 border-0'
                onClick={() => navigate("/demo-account")}
                type='button'
                data-testid='demo-account-link'
              >
                Try Our Demo
              </Button>
            </>
          )}
        </>
      ) : null}
    </form>
  );
};
