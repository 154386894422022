import classNames from "classnames";
import React from "react";

interface ModalProps {
  title: string;
  onClose: () => void;
  children?: React.ReactNode;
  isOpen: boolean;
  testId?: string;
}

const Modal: React.FC<ModalProps> = ({
  title,
  onClose,
  children,
  isOpen,
  testId,
}) => {
  const cls = classNames(
    "modal modal-bottom sm:modal-middle",
    isOpen && "modal-open"
  );

  return (
    <div className={cls} data-testid={testId}>
      <div className='modal-box bg-white dark:bg-erply-dark-500 relative sm:rounded-none sm:!rounded-r-none sm:!rounded-l-none'>
        <button
          className='btn btn-sm btn-circle absolute right-2 top-2 bg-transparent border-none dark:text-white text-gray-900 hover:btn-circle dark:hover:bg-white 
    dark:hover:text-gray-900 hover:text-white'
          onClick={onClose}
        >
          ✕
        </button>
        <h3 className='text-lg font-bold dark:text-white text-gray-900'>
          {title}
        </h3>
        {children}
      </div>
    </div>
  );
};

export default Modal;
