import React from "react";
import { useAuthState } from "../../../context/auth";
import ErplyIcon from "../../elements/ErplyIcon";
import { useParams } from "react-router-dom";
import { getFirstTwoChars } from "../../../utils/index";
import { FranchaiseAccount } from "../../../types/index";

interface UserInfoProps {
  currentUsername: string | null;
  companyInfo: string | null;
  franchaiseAccounts: FranchaiseAccount[];
  isDropDownOpen: boolean;
  setIsDropDownOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserInfo: React.FC<UserInfoProps> = ({
  currentUsername,
  companyInfo,
  franchaiseAccounts,
  isDropDownOpen,
  setIsDropDownOpen,
}) => {
  const { clientCode: clienttParams } = useParams();

  const { clientCode } = useAuthState();

  return (
    <div
      className={`pt-5 px-5 pb-2 w-full ${
        isDropDownOpen ? "bg-erply-dark-50" : "bg-[#151C24]"
      }`}
    >
      <div className='flex space-x-3 items-center justify-between mb-3'>
        <div className='flex space-x-3 items-center'>
          <div>
            <button className='btn btn-circle btn-sm bg-erply-brand-yellow-100 text-gray-900 hover:bg-erply-brand-yellow-100 hover:text-gray-900 cursor-default'>
              {getFirstTwoChars(companyInfo || "ERPLY")}
            </button>
          </div>
          {companyInfo ? (
            <span
              className='text-white font-bold text-sm !capitalize'
              data-testid='side-bar-menu-company'
            >
              {companyInfo.toLowerCase()}
            </span>
          ) : null}
        </div>
        {franchaiseAccounts.length > 0 ? (
          <ErplyIcon
            icon={isDropDownOpen ? "Chevron-Up" : "Chevron-Down"}
            color='#fff'
            onClick={() => setIsDropDownOpen((prev) => !prev)}
          />
        ) : null}
      </div>
      {currentUsername ? (
        <div className='mb-2'>
          <span className=' text-erply-light-10 opacity-70 text-xs'>
            Username
          </span>
          <p
            className='text-white text-sm'
            data-testid='side-bar-menu-username'
          >
            {currentUsername}
          </p>
        </div>
      ) : null}
      <div className={!isDropDownOpen ? "mb-[10px]" : "mb-0"}>
        <span className=' text-erply-light-10 text-xs opacity-70'>
          Account number:
        </span>
        <p
          className='text-white text-sm mb-0'
          data-testid='side-bar-menu-account-number'
        >
          {clientCode || clienttParams}
        </p>
      </div>
    </div>
  );
};

export default UserInfo;
