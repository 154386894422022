import classNames from "classnames";
import React from "react";
import { IconValueType } from "../../styles/iconValues";
import ErplyIcon from "./ErplyIcon";

interface AlertProps {
  message?: string;
  className?: string;
  children?: React.ReactNode;
  icon?: IconValueType;
  iconColor?: string;
  centered?: boolean;
  onClick?: () => void;
}

const Alert: React.FC<AlertProps> = ({
  message,
  className,
  icon = "Info",
  iconColor,
  onClick,
  centered = true,
  children,
}) => {
  const cls = classNames(
    "alert rounded-sm shadow-sm bg-erply-alert-50 text-gray-700",
    className
  );

  return (
    <div className={cls} onClick={onClick}>
      <div className={`flex ${centered ? "items-center" : "items-start"}`}>
        <ErplyIcon icon={icon} className={iconColor} />
        <span>{message}</span>
        <span>{children}</span>
      </div>
    </div>
  );
};

export default Alert;
