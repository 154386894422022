import classNames from "classnames";
import React from "react";

interface TitleProps {
  pageTilte: string;
  containerClass?: string;
  textClass?: string;
  testId?: string;
  isBetween?: boolean;
}

const Title: React.FC<TitleProps> = ({
  pageTilte,
  containerClass,
  textClass,
  testId,
  isBetween,
}) => {
  const containerCls = classNames(
    "mb-7 pt-10 w-full dark:border-t-0 dark:border md:ml-52 lg:ml-44 dark:border-x-0 dark:border-slate-600 sm:mt-0 mt-12",
    containerClass
  );

  const textCls = classNames(
    "sm:ml-10 ml-9 md:ml-24 text-[28px] font-bold dark:text-white text-gray-900 md:landscape:ml-28 sm:landscape:mt-5",
    textClass,
    { "mt-5": isBetween }
  );
  return (
    <div className={containerCls}>
      <h1 className={textCls} data-testid={testId}>
        {pageTilte}
      </h1>
    </div>
  );
};

export default Title;
