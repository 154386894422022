import React from "react";
import { IconValueType } from "../../styles/iconValues";
import { AppNames } from "../../types";
import ErplyIcon from "./ErplyIcon";

interface AppCardProps {
  title: string;
  icon: IconValueType;
  description: string;
  type: AppNames;
  isDarkTheme: boolean;
  href: string;
}

const AppCard: React.FC<AppCardProps> = ({
  title,
  icon,
  description,
  type,
  isDarkTheme,
  href,
}) => {
  const getBg = () => {
    switch (type) {
      case "backoffice":
        return "bg-erply-success-200 hover:bg-white hover:text-erply-success-200";

      case "pointofsale":
        return "bg-erply-light-200 hover:bg-white hover:text-erply-light-200";

      case "retrycrm":
        return "bg-emerald-400 hover:bg-white hover:text-emerald-400";

      default:
        return "bg-erply-brand-purple-100 hover:bg-white hover:text-erply-brand-purple-100";
    }
  };

  const getHover = () => {
    switch (type) {
      case "backoffice":
        return "back-office";

      case "pointofsale":
        return "point-of-sale";

      case "retrycrm":
        return "retry-crm";

      default:
        return "service-app";
    }
  };

  return (
    <a
      className={`app-select  ${getHover()}`}
      style={{
        padding: "10px 20px 10px 20px",
        border: isDarkTheme
          ? "1px solid rgba(255, 255, 255, 0.3)"
          : "1px solid rgba(0, 0, 0, 0.1)",
      }}
      href={href}
      target='_blank'
      rel='noopener noreferrer'
      data-testid={`${type}-link`}
    >
      <div className='flex space-x-4 items-center'>
        <button
          className={`btn btn-circle btn-lg text-white border-none ${getBg()}`}
        >
          <ErplyIcon icon={icon} />
        </button>
        <div className='flex flex-col space-y-1'>
          <h1
            style={{ fontSize: "22px", lineHeight: "26.4px" }}
            className='dark:text-white text-gray-900 font-bold'
          >
            {title}
          </h1>
          <small className='dark:text-erply-light-10 text-erply-alert-100'>
            {description}
          </small>
        </div>
      </div>
      <ErplyIcon icon='Chevron-Right' />
    </a>
  );
};

export default AppCard;
