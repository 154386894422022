import React, { useEffect, useState } from "react";
import {
  Outlet,
  useNavigate,
  useParams,
  useOutletContext,
} from "react-router-dom";
import SideBar from "../components/layouts/SideBar";
import {
  BannerI,
  ErplyRequestData,
  FranchaiseAccount,
  SettingsI,
  GetCAFAConfigResponseI,
} from "../types/index";
import { notify } from "../utils";
import { iNotification } from "react-notifications-component";
import Server from "../service/Server";
import { useAuthState } from "../context/auth";

interface OutletContext {
  settings: SettingsI | null;
  isTwoFactorEnabled: boolean;
  getSettings: () => Promise<void>;
  banners: BannerI[];
  appConfigs: GetCAFAConfigResponseI | null;
  isFetchingConfig: boolean;
}

export function useAuthenticatedContext() {
  return useOutletContext<OutletContext>();
}

const AuthenticatedPage: React.FC = () => {
  const [currentUsername, setUsername] = useState<string | null>(null);
  const [settings, setSettings] = useState<SettingsI | null>(null);
  const [companyInfo, setCompanyInfo] = useState<string | null>(null);
  const [franchaiseAccounts, setAccounts] = useState<FranchaiseAccount[]>([]);
  const [isTwoFactorEnabled, setIsTwoFactorEnabled] = useState(true);
  const [banners, setBanners] = useState<BannerI[]>([]);
  const [appConfigs, setAppConfigs] = useState<GetCAFAConfigResponseI | null>(
    null
  );
  const [isFetchingConfig, setIsFetchingConfig] = useState(true);

  const {
    target,
    clientCode,
    baseUrl,
    sessionKey,
    handleChange,
    setAccountAdminBaseUrl,
    isAuthenticated,
    accAdminBaseUrl,
    setUserAPIBaseUrl,
    userAPIBaseUrl,
  } = useAuthState();
  const { clientCode: paramsClientCode } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      getAppConfiguration();
      getSessionUser();
      getCompanyInfo();
      getBanners();
      getLoginSettingsConfig();
      getFranchaiseAccounts();
      getSettings();
    }

    // eslint-disable-next-line
  }, [isAuthenticated]);

  useEffect(() => {
    const TARGET = localStorage.getItem(`${clientCode}_TARGET`);
    if (target) {
      if (target.includes("{CLIENTCODE}")) {
        const newTarget = target.replace("{CLIENTCODE}", clientCode);
        window.location.href = newTarget;
      } else {
        window.location.href = target;
      }
    } else if (!target && TARGET) {
      if (TARGET.includes("{CLIENTCODE}")) {
        const newTarget = TARGET.replace("{CLIENTCODE}", clientCode);
        window.location.href = newTarget;
      } else {
        window.location.href = TARGET;
      }
    }
    // eslint-disable-next-line
  }, [target, clientCode]);

  useEffect(() => {
    if (!isAuthenticated && paramsClientCode) {
      getCurrentSession(paramsClientCode);
    } else if (!isAuthenticated && !paramsClientCode) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isAuthenticated, paramsClientCode]);

  const getAppConfiguration = async () => {
    const { data } = await Server.getServiceEndPoint({
      clientCode,
      request: "getServiceEndpoints",
    });

    try {
      if (data.status.responseStatus !== "error") {
        try {
          const config = await Server.getApplicationCafaConfig(
            data.records[0].cafa.url,
            clientCode,
            sessionKey
          );
          setAppConfigs(config.data ? config.data[0] : null);
          setIsFetchingConfig(false);
        } catch (error: any) {
          const {
            response: { data },
          } = error;

          if (data) {
            notify({
              message: `${data.message}`,
              type: "danger",
            } as iNotification);
          } else {
            notify({
              message: `Error getting app configuration`,
              type: "danger",
            } as iNotification);
          }
        }
      } else {
        setIsFetchingConfig(false);
        notify({
          message: `Error getting app configuration`,
          type: "danger",
        } as iNotification);
      }
    } catch (error: any) {
      setIsFetchingConfig(false);
      notify({
        message: `Error getting app configuration`,
        type: "danger",
      } as iNotification);
    }
  };

  const getCurrentSession = async (clientCode: string): Promise<void> => {
    try {
      const { data } = await Server.getServiceEndPoint({
        clientCode,
        request: "getServiceEndpoints",
      });
      if (data.status.responseStatus === "error") {
        navigate(`/`);
      } else {
        const baseUrl: string = data.records[0].auth.url
          ? data.records[0].auth.url
          : "";
        const adminBaseUrl: string = data.records[0]["account-admin"].url
          ? data.records[0]["account-admin"].url
          : "";
        setAccountAdminBaseUrl(adminBaseUrl);
        const userBaseUrl: string = data.records[0]["user"].url
          ? data.records[0]["user"].url
          : "";
        setUserAPIBaseUrl(userBaseUrl);
        try {
          const { data } = await Server.getSession({ baseUrl, clientCode });
          handleChange({
            session: data.data,
            clientCode,
            baseUrl,
            sessionKey: data.data.session?.sessionKey
              ? data.data.session?.sessionKey
              : "",
            isAuthenticated: true,
          });
        } catch (error) {
          navigate(`/`);
        }
      }
    } catch (error) {
      navigate(`/`);
    }
  };

  const getSessionUser = async () => {
    try {
      const request: ErplyRequestData = {
        sessionKey,
        clientCode,
        request: "getSessionKeyUser",
      };
      const { data } = await Server.getSessionKeyUser(request);
      if (data.status.responseStatus !== "error") {
        const userInfo = data.records[0];
        const username = `${userInfo.employeeName} ${
          userInfo.userName ? `(${userInfo.userName})` : ""
        }`;
        setUsername(username);
      } else {
        setUsername(null);
      }
    } catch (error) {
      setUsername(null);
    }
  };

  const getFranchaiseAccounts = async () => {
    try {
      const {
        data: { data },
      } = await Server.getFranchaiseAccounts({
        baseUrl,
        sessionKey,
        clientCode,
      });
      const isFound = data.find((e) => e.number === clientCode);

      if (isFound) {
        const filtered = data.filter((e) => e.number !== isFound.number);
        setAccounts(
          filtered.map((e) => ({
            ...e,
            display: `${e.number}-${e.name}-${e.code}`,
          }))
        );
      } else {
        setAccounts(
          data.map((e) => ({
            ...e,
            display: `${e.number}-${e.name}-${e.code}`,
          }))
        );
      }
    } catch (error) {
      setAccounts([]);
    }
  };

  const getCompanyInfo = async () => {
    try {
      const request: ErplyRequestData = {
        sessionKey,
        clientCode,
        request: "getCompanyInfo",
      };

      const { data } = await Server.getCompanyInfo(request);

      if (data.status.responseStatus !== "error") {
        setCompanyInfo(data.records[0].name);
      } else {
        notify({
          message: "Error getting company information. Please try again",
          type: "danger",
        } as iNotification);
      }
    } catch (err: any) {
      if (err.response) {
        const {
          response: { data },
        } = err;
        if (data) {
          notify({
            message: `${data.status.errorDescription}`,
            type: "danger",
          } as iNotification);
        } else {
          notify({
            message: "Error performing operation. Please try again",
            type: "danger",
          } as iNotification);
        }
      } else {
        notify({
          message: "Error performing operation. Please try again",
          type: "danger",
        } as iNotification);
      }
    }
  };

  const getLoginSettingsConfig = async () => {
    try {
      const {
        data: { data },
      } = await Server.getLoginSettingsConfig({
        baseUrl: accAdminBaseUrl,
        sessionKey,
        clientCode,
      });
      if (data["two_factor_enabled"]) {
        setIsTwoFactorEnabled(data["two_factor_enabled"].value as boolean);
      }
    } catch (err: any) {
      if (err.response) {
        const {
          response: { data },
        } = err;
        if (data) {
          notify({
            message: `${data.status.errorDescription}`,
            type: "danger",
          } as iNotification);
        } else {
          notify({
            message: "Error performing operation. Please try again",
            type: "danger",
          } as iNotification);
        }
      } else {
        notify({
          message: "Error performing operation. Please try again",
          type: "danger",
        } as iNotification);
      }
    }
  };

  const getSettings = async () => {
    try {
      const request = {
        baseUrl,
        sessionKey,
        clientCode,
      };
      const { data } = await Server.getSettings(request);
      setSettings(data.data);
    } catch (err: any) {
      if (err.response) {
        const {
          response: { data },
        } = err;
        if (data) {
          notify({
            message: `${data.status.errorDescription}`,
            type: "danger",
          } as iNotification);
        } else {
          notify({
            message: "Error performing operation. Please try again",
            type: "danger",
          } as iNotification);
        }
      } else {
        notify({
          message: "Error performing operation. Please try again",
          type: "danger",
        } as iNotification);
      }
      setSettings(null);
    }
  };

  const getBanners = async () => {
    try {
      const { data } = await Server.getBanners(userAPIBaseUrl);
      setBanners(data.data);
    } catch (error) {}
  };

  return (
    <div className='h-screen flex justify-between overflow-y-auto overflow-x-hidden'>
      <SideBar
        currentUsername={currentUsername}
        companyInfo={companyInfo}
        franchaiseAccounts={franchaiseAccounts}
      />
      {/* <div className='container'>
       
      </div> */}
      <Outlet
        context={{
          settings,
          isTwoFactorEnabled,
          getSettings,
          banners,
          appConfigs,
          isFetchingConfig,
        }}
      />
    </div>
  );
};

export default AuthenticatedPage;
