import React from "react";
import { useNavigate } from "react-router-dom";
import { FranchaiseAccount } from "../../../types/index";
import { getFirstTwoChars } from "../../../utils";

interface FranchiseAccountProps {
  franchaiseAccount: FranchaiseAccount;
  backgroundColor: string;
  isLast: boolean;
}

const FranchiseAccount: React.FC<FranchiseAccountProps> = ({
  franchaiseAccount,
  backgroundColor,
  isLast,
}) => {
  const navigate = useNavigate();

  return (
    <div
      className={`text-white text-sm  px-5 py-4 flex items-center h-[50px] border border-x-0 border-b-0 border-[#343D47] hover:bg-erply-dark-50 cursor-pointer bg-[#151C24] ${
        isLast && "mb-[10px]"
      }`}
      onClick={() => navigate(`/switch-account/${franchaiseAccount.number}`)}
    >
      <div className='flex items-center space-x-4'>
        <div>
          <button
            className='btn btn-circle btn-sm bg-erply-brand-yellow-100 text-gray-900 hover:bg-erply-brand-yellow-100 hover:text-gray-900 cursor-default'
            style={{ backgroundColor }}
          >
            {getFirstTwoChars(franchaiseAccount.name)}
          </button>
        </div>
        <span className='text-white font-bold text-sm'>
          {franchaiseAccount.display}
        </span>
      </div>
    </div>
  );
};

export default FranchiseAccount;
