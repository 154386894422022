import React, { useState } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { useAuthState } from "../../context/auth";
import ErplyIcon from "../elements/ErplyIcon";
import UserInfo from "../sections/Authenticated/UserInfo";
import { FranchaiseAccount } from "../../types/index";
import FranchiseAccount from "../sections/Authenticated/FranchiseAccount";
import { colors, getReleaseInfo } from "../../utils";
import { getFirstTwoChars } from "../../utils/index";
import SubMenu from "../sections/Authenticated/SubMenu";

interface SideBarProps {
  currentUsername: string | null;
  companyInfo: string | null;
  franchaiseAccounts: FranchaiseAccount[];
}

const SideBar: React.FC<SideBarProps> = ({
  currentUsername,
  companyInfo,
  franchaiseAccounts,
}) => {
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isSubMenu, setIsSubMenu] = useState(false);
  const { pathname } = useLocation();
  const { clientCode: clienttParams } = useParams();
  const navigate = useNavigate();

  const { clientCode, handleLogout, session } = useAuthState();

  const { time, version } = getReleaseInfo();

  return (
    <>
      <nav className='absolute h-screen bg-[#151C24] md:block hidden w-64 overflow-auto'>
        <UserInfo
          currentUsername={currentUsername}
          companyInfo={companyInfo}
          franchaiseAccounts={franchaiseAccounts}
          isDropDownOpen={isDropDownOpen}
          setIsDropDownOpen={setIsDropDownOpen}
        />
        <div className='flex flex-col justify-between w-full'>
          {franchaiseAccounts.length && isDropDownOpen ? (
            <>
              <div className='bg-erply-dark-50 border border-x-0 border-[#343D47] border-b-0 px-5 py-2'>
                <span className=' text-erply-light-10 text-xs opacity-70'>
                  Switch account:
                </span>
              </div>
              {franchaiseAccounts.map((e, i) => (
                <FranchiseAccount
                  franchaiseAccount={e}
                  key={e.code}
                  backgroundColor={colors[i]}
                  isLast={i === franchaiseAccounts.length - 1}
                />
              ))}
            </>
          ) : null}
          <div
            className={
              pathname.includes("/app")
                ? "text-white text-sm px-5 flex items-center h-[50px] bg-erply-light-200  border-none border-x-0 border-[#343D47] cursor-pointer"
                : franchaiseAccounts.length && isDropDownOpen
                ? "text-white text-sm px-5 py-4 border border-b-0 border-x-0 border-[#343D47] hover:bg-erply-dark-50 cursor-pointer flex items-center h-[50px]"
                : "text-white text-sm px-5 py-4 border-none  border-x-0 border-slate-300 hover:bg-erply-dark-50 cursor-pointer flex items-center h-[50px]"
            }
            onClick={() => navigate(`/${clientCode || clienttParams}/app`)}
          >
            <Link
              className='flex items-center space-x-4'
              to={`/${clientCode || clienttParams}/app`}
              data-testid='side-bar-menu-applications-link'
            >
              <ErplyIcon icon='Grid-22' />
              <span>Applications</span>
            </Link>
          </div>
          <div
            className={
              pathname.includes("/password")
                ? "text-white text-sm  px-5 py-4 border border-x-0  border-[#343D47] bg-erply-light-200 cursor-pointer flex items-center h-[50px]"
                : "text-white text-sm  px-5 py-4 border border-x-0 border-[#343D47] hover:bg-erply-dark-50 cursor-pointer flex items-center h-[50px]"
            }
            onClick={() => navigate(`/${clientCode || clienttParams}/password`)}
          >
            <Link
              className='flex items-center space-x-4'
              to={`/${clientCode || clienttParams}/password`}
              data-testid='side-bar-menu-password-link'
            >
              <ErplyIcon icon='Key' />
              <span>Password</span>
            </Link>
          </div>
          <div
            className={
              pathname.includes("/two-step-auth")
                ? "text-white text-sm px-5 py-4 border border-x-0 border-t-0 border-[#343D47] bg-erply-light-200 cursor-pointer flex items-center h-[50px]"
                : "text-white text-sm px-5 py-4 border border-x-0 border-t-0 border-[#343D47] hover:bg-erply-dark-50 cursor-pointer flex items-center h-[50px]"
            }
            onClick={() =>
              navigate(`/${clientCode || clienttParams}/two-step-auth`)
            }
          >
            <Link
              className='flex items-center space-x-4'
              to={`/${clientCode || clienttParams}/two-step-auth`}
              data-testid='side-bar-menu-two-step-authentication-link'
            >
              <ErplyIcon icon='Lock-Locked' />
              <span>Two Step Authentication</span>
            </Link>
          </div>
          {/* <div
            className={
              pathname.includes("/support")
                ? "text-white text-sm px-5 py-4 border border-x-0 border-t-0 border-[#343D47] bg-erply-light-200 cursor-pointer flex items-center h-[50px]"
                : "text-white text-sm px-5 py-4 border border-x-0 border-t-0 border-[#343D47] hover:bg-erply-dark-50 cursor-pointer flex items-center h-[50px]"
            }
            onClick={() => navigate(`/${clientCode || clienttParams}/support`)}
          >
            <Link
              className='flex items-center space-x-4'
              to={`/${clientCode || clienttParams}/support`}
            >
              <ErplyIcon icon='Info' />
              <span>Request Support</span>
            </Link>
          </div> */}
        </div>
        <div className='absolute w-full bottom-0'>
          <div className='text-white text-sm px-5 py-4 border border-x-0 border-b-0  border-[#343D47] cursor-pointer hover:bg-erply-dark-50'>
            <div className='flex items-center space-x-4 cursor-pointer'>
              <ErplyIcon icon='Call' />
              <div className='flex flex-col'>
                <a
                  href={`tel:${session?.misc?.supportPhone}`}
                  data-testid='side-bar-menu-support-phone-link'
                >
                  {session?.misc?.supportPhone}
                </a>
                <a
                  href={`mailto:${session?.misc?.supportEmail}`}
                  data-testid='side-bar-menu-support-email-link'
                >
                  {session?.misc?.supportEmail}
                </a>
              </div>
            </div>
          </div>
          <div
            className='text-white text-sm px-5 py-4 border border-x-0  border-[#343D47] cursor-pointer hover:bg-erply-dark-50 flex items-center h-[50px]'
            onClick={handleLogout}
            data-testid='side-bar-menu-logout'
          >
            <div className='flex items-center space-x-4 cursor-pointer'>
              <ErplyIcon icon='Sign-Out' />
              <span>Logout</span>
            </div>
          </div>
          <div className='text-erply-light-10 text-xs px-6 py-4 opacity-70 flex flex-col'>
            <span>v. {version}</span>
            <span>{time}</span>
          </div>
        </div>
      </nav>
      <nav
        className={`lg:hidden bg-erply-dark-400 h-14 fixed w-full flex items-center text-white space-x-5 justify-between md:hidden`}
      >
        {/* className={`${isSubMenu && "bg-erply-dark-10 p-5"}`} */}
        <div className={`p-5 ${isSubMenu && "bg-erply-dark-10 "}`}>
          <ErplyIcon
            icon='Drag'
            onClick={() => {
              if (isDropDownOpen) {
                setIsDropDownOpen(false);
              }
              setIsSubMenu((prev) => !prev);
            }}
          />
        </div>
        <div
          className={`flex space-x-2 items-center ${
            !franchaiseAccounts.length && "pr-4"
          }`}
        >
          <div>
            <button className='btn btn-circle btn-sm bg-erply-brand-yellow-100 text-gray-900 hover:bg-erply-brand-yellow-100 hover:text-gray-900 cursor-default'>
              {getFirstTwoChars(companyInfo || "ERPLY")}
            </button>
          </div>
          {companyInfo ? (
            <span className='text-white font-bold text-sm'>{companyInfo}</span>
          ) : null}
        </div>

        {franchaiseAccounts.length > 0 ? (
          <div className={`p-5 ${isDropDownOpen && "bg-erply-dark-10 "}`}>
            <ErplyIcon
              icon={isDropDownOpen ? "Chevron-Up" : "Chevron-Down"}
              color='#fff'
              onClick={() => {
                setIsDropDownOpen((prev) => !prev);
                if (isSubMenu) {
                  setIsSubMenu(false);
                }
              }}
              className='cursor-pointer'
            />
          </div>
        ) : null}
      </nav>
      {(isDropDownOpen || isSubMenu) && (
        <SubMenu
          currentUsername={currentUsername}
          companyInfo={companyInfo}
          franchaiseAccounts={franchaiseAccounts}
          isDropDownOpen={isDropDownOpen}
        />
      )}
    </>
  );
};

export default SideBar;
