import React, { useState } from "react";
import { AppAuthenticationI, TwoFactorRequestI } from "../../../types/index";
import { notify } from "../../../utils";
import { iNotification } from "react-notifications-component";
import { useAuthState } from "../../../context/auth";
import Server from "../../../service/Server";
import Spinner from "../../elements/Spinner";
import HelperText from "../../elements/HelperText";
import TextInput from "../../elements/TextInput";
import Modal from "../../elements/Modal";
import Button from "../../elements/Button";
import classNames from "classnames";
import Alert from "../../elements/Alert";

interface MSAuthenticationModalProps {
  isOpen: boolean;
  onClose: () => void;
  qrCode: AppAuthenticationI | null;
  getSettings: () => Promise<void>;
}

const MSAuthenticationModal: React.FC<MSAuthenticationModalProps> = ({
  isOpen,
  onClose,
  qrCode,
  getSettings,
}) => {
  const [twoFactorKey, setKey] = useState("");
  const [isLoading, setLoading] = useState(false);
  const { baseUrl, sessionKey, clientCode } = useAuthState();

  const handleClose = () => {
    setKey("");
    setLoading(false);
    onClose();
  };

  const confirmRegistration = async () => {
    try {
      setLoading(true);
      const requestData: TwoFactorRequestI = {
        baseUrl,
        sessionKey,
        clientCode,
        value: {
          twoFactorKey,
          twoFactorToken: qrCode?.twoFactorToken!,
        },
      };
      await Server.confirmMSQR(requestData);
      getSettings();
      notify({
        message: "Microsoft authentication registered successfully",
        type: "success",
      } as iNotification);
      setLoading(false);
      handleClose();
    } catch (err: any) {
      if (err.response) {
        const {
          response: { data },
        } = err;
        if (data && data.status) {
          notify({
            message: `${data.status.errorDescription}`,
            type: "danger",
          } as iNotification);
        } else {
          notify({
            message: "Error performing operation. Please try again",
            type: "danger",
          } as iNotification);
        }
      } else {
        notify({
          message: "Error performing operation. Please try again",
          type: "danger",
        } as iNotification);
      }

      setLoading(false);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!twoFactorKey) return;

    confirmRegistration();
  };

  const btnCls = classNames(
    "dark:bg-white btn-block bg-erply-success-200 dark:text-gray-900 text-white border-0 dark:hover:text-gray-900 dark:hover:bg-[#E6E6E6] hover:bg-[#4AD17A] active:bg-[#35A95F] dark:active:bg-erply-dark-100",
    isLoading && "btn-disabled loading"
  );

  return (
    <Modal
      isOpen={isOpen}
      title='Microsoft Authenticator App'
      onClose={handleClose}
      testId='microsoft-add-channel-modal'
    >
      <div className='py-5'>
        <Alert
          message={`Install Microsoft Authenticator App from your application store and
          use the app to scan this QR code.`}
          className='mt-5'
        />
        {isLoading ? (
          <div className='flex justify-center'>
            <Spinner />
          </div>
        ) : null}

        {qrCode ? (
          <>
            <div className='mt-4 flex justify-center'>
              <img
                src={`data:image/png;base64,${qrCode.qr}`}
                alt='code'
                className='w-60'
              />
            </div>
            <form onSubmit={handleSubmit} className='my-5'>
              <HelperText
                message={`Enter the code from your microsoft authenticator app `}
                className='mb-5'
              />
              <TextInput
                placeholder='Code'
                name='ms'
                value={twoFactorKey}
                onChange={(e) => setKey(e.target.value)}
                data-testid='microsoft-add-channel-code'
                autoFocus
              />
            </form>
          </>
        ) : null}
      </div>
      <div className='modal-action justify-center space-x-3'>
        <div>
          <Button
            className='btn-block bg-erply-dark-20 border-0 text-white dark:text-gray-900 dark:hover:text-white'
            onClick={handleClose}
            data-testid='microsoft-add-channel-cancel-button'
          >
            Cancel
          </Button>
        </div>
        {qrCode && (
          <div>
            <Button
              className={btnCls}
              onClick={confirmRegistration}
              disabled={!twoFactorKey}
              data-testid='microsoft-add-channel-confirm-button'
            >
              Confirm
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default MSAuthenticationModal;
