import React from "react";
import { useAuthState } from "../../../context/auth";
import { FranchaiseAccount } from "../../../types";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { colors, getReleaseInfo } from "../../../utils";
import FranchiseAccount from "./FranchiseAccount";
import ErplyIcon from "../../elements/ErplyIcon";
import Button from "../../elements/Button";

interface SubMenuProps {
  currentUsername: string | null;
  companyInfo: string | null;
  franchaiseAccounts: FranchaiseAccount[];
  isDropDownOpen: boolean;
}

const SubMenu: React.FC<SubMenuProps> = ({
  currentUsername,
  franchaiseAccounts,
  isDropDownOpen,
}) => {
  const { clientCode: clienttParams } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { clientCode, handleLogout } = useAuthState();

  const { time, version } = getReleaseInfo();

  return (
    <div className='absolute h-auto bg-erply-dark-50 w-full overflow-auto  top-14 right-0 border border-x-0 border-b-0  border-[#343D47] z-50 lg:hidden'>
      <div className='pt-5 px-5 pb-2 w-full'>
        {currentUsername ? (
          <div className='mb-2'>
            <span className=' text-erply-light-10 opacity-70 text-xs'>
              Username
            </span>
            <p className='text-white text-sm capitalize'>
              {currentUsername.toLowerCase()}
            </p>
          </div>
        ) : null}
        <div className='mb-0'>
          <span className=' text-erply-light-10 text-xs opacity-70'>
            Account number:
          </span>
          <p className='text-white text-sm mb-0'>
            {clientCode || clienttParams}
          </p>
        </div>
      </div>
      <div className='flex flex-col justify-between w-full bg-erply-dark-400 '>
        {franchaiseAccounts.length && isDropDownOpen ? (
          <>
            <div className='bg-erply-dark-50 border border-x-0 border-[#343D47] border-b-0 px-5 py-2'>
              <span className=' text-erply-light-10 text-xs opacity-70'>
                Switch account:
              </span>
            </div>
            <div className=' max-h-32 overflow-auto'>
              {franchaiseAccounts.map((e, i) => (
                <FranchiseAccount
                  franchaiseAccount={e}
                  key={e.code}
                  backgroundColor={colors[i]}
                  isLast={i === franchaiseAccounts.length - 1}
                />
              ))}
            </div>
          </>
        ) : null}
        {!isDropDownOpen && (
          <>
            <div
              className={
                pathname.includes("/app")
                  ? "text-white text-sm px-5 py-4 flex items-center h-[50px] bg-erply-light-200  border-none border-x-0 border-[#343D47] cursor-pointer"
                  : franchaiseAccounts.length && isDropDownOpen
                  ? "text-white text-sm px-5 py-4 border border-b-0 border-x-0 border-[#343D47] hover:bg-erply-dark-50 cursor-pointer flex items-center h-[50px]"
                  : "text-white text-sm px-5 py-4 border-none  border-x-0 border-slate-300 hover:bg-erply-dark-50 cursor-pointer flex items-center h-[50px]"
              }
              onClick={() => navigate(`/${clientCode || clienttParams}/app`)}
            >
              <Link
                className='flex items-center space-x-4'
                to={`/${clientCode || clienttParams}/app`}
              >
                <ErplyIcon icon='Grid-22' />
                <span>Applications</span>
              </Link>
            </div>
            <div
              className={
                pathname.includes("/password")
                  ? "text-white text-sm  px-5 py-4 border border-x-0 border-[#343D47] bg-erply-light-200 cursor-pointer flex items-center h-[50px]"
                  : "text-white text-sm  px-5 py-4 border border-x-0 border-[#343D47] hover:bg-erply-dark-50 cursor-pointer flex items-center h-[50px]"
              }
              onClick={() =>
                navigate(`/${clientCode || clienttParams}/password`)
              }
            >
              <Link
                className='flex items-center space-x-4'
                to={`/${clientCode || clienttParams}/password`}
              >
                <ErplyIcon icon='Key' />
                <span>Password</span>
              </Link>
            </div>
            <div
              className={
                pathname.includes("/two-step-auth")
                  ? "text-white text-sm px-5 py-4 border border-x-0 border-t-0 border-[#343D47] bg-erply-light-200 cursor-pointer flex items-center h-[50px]"
                  : "text-white text-sm px-5 py-4 border border-x-0 border-t-0 border-[#343D47] hover:bg-erply-dark-50 cursor-pointer flex items-center h-[50px]"
              }
              onClick={() =>
                navigate(`/${clientCode || clienttParams}/two-step-auth`)
              }
            >
              <Link
                className='flex items-center space-x-4'
                to={`/${clientCode || clienttParams}/two-step-auth`}
              >
                <ErplyIcon icon='Lock-Locked' />
                <span>Two Factor Authentication</span>
              </Link>
            </div>
            {/* <div
              className={
                pathname.includes("/support")
                  ? "text-white text-sm px-5 py-4 border border-x-0 border-t-0 border-[#343D47] bg-erply-light-200 cursor-pointer flex items-center h-[50px]"
                  : "text-white text-sm px-5 py-4 border border-x-0 border-t-0 border-[#343D47] hover:bg-erply-dark-50 cursor-pointer flex items-center h-[50px]"
              }
              onClick={() =>
                navigate(`/${clientCode || clienttParams}/support`)
              }
            >
              <Link
                className='flex items-center space-x-4'
                to={`/${clientCode || clienttParams}/support`}
              >
                <ErplyIcon icon='Info' />
                <span>Request Support</span>
              </Link>
            </div> */}
          </>
        )}
      </div>
      <div className='pt-6 px-5 pb-5 w-full'>
        <Button
          className='btn-block gap-3 bg-white text-gray-900 hover:bg-white hover:text-gray-900'
          onClick={handleLogout}
        >
          <ErplyIcon icon='Sign-Out' />
          <span>Log Out</span>
        </Button>
        <div className='text-erply-light-10 text-xs px-1 py-4 opacity-70 flex flex-col'>
          <span>v. {version}</span>
          <span>{time}</span>
        </div>
      </div>
    </div>
  );
};

export default SubMenu;
