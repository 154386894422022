import { useEffect, useState } from "react";
const localStorageKey = "mode";

export type Mode = "light" | "dark" | "system";

export type Theme = "light" | "dark";

const useTheme = () => {
  const [mode, setMode] = useState<Mode>(() => {
    const initialMode =
      (localStorage.getItem(localStorageKey) as Mode | undefined) || "system";
    return initialMode;
  });

  const [theme, setTheme] = useState<Theme>(() => {
    if (mode !== "system") {
      return mode;
    }
    const isSystemInDarkMode = matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;
    return isSystemInDarkMode ? "dark" : "light";
  });

  // When the mode changes, save it to the localStorage and to the database
  useEffect(() => {
    localStorage.setItem(localStorageKey, mode);
  }, [mode]);

  // Update the theme according to the mode
  useEffect(() => {
    if (mode !== "system") {
      setTheme(mode);
      return;
    }

    const isSystemInDarkMode = matchMedia("(prefers-color-scheme: dark)");
    // If system mode, immediately change theme according to the current system value
    setTheme(isSystemInDarkMode.matches ? "dark" : "light");

    // As the system value can change, we define an event listener when in system mode
    // to track down its changes
    const listener = (event: MediaQueryListEvent) => {
      setTheme(event.matches ? "dark" : "light");
    };
    isSystemInDarkMode.addListener(listener);
    return () => {
      isSystemInDarkMode.removeListener(listener);
    };
  }, [mode]);

  // Update the visuals on theme change
  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
      document.documentElement.setAttribute("data-theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      document.documentElement.removeAttribute("data-theme");
    }
  }, [theme]);

  // useEffect(() => {
  //   // On page load or when changing themes, best to add inline in `head` to avoid FOUC
  //   if (
  //     localStorage.theme === "dark" ||
  //     (!("theme" in localStorage) && isDarkMode)
  //   ) {
  //     document.documentElement.classList.add("dark");
  //     document.documentElement.setAttribute("data-theme", "dark");
  //     setIsDarkTheme(true);

  //     //dev
  //     // document.documentElement.classList.remove("dark");
  //     // document.documentElement.removeAttribute("data-theme");
  //   } else {
  //     // for dev
  //     //   document.documentElement.classList.add("dark");
  //     //   document.documentElement.setAttribute("data-theme", "dark");

  //     // main
  //     document.documentElement.classList.remove("dark");
  //     document.documentElement.removeAttribute("data-theme");
  //     setIsDarkTheme(false);
  //   }

  //   // // Whenever the user explicitly chooses light mode
  //   // localStorage.theme = "light";

  //   // // Whenever the user explicitly chooses dark mode
  //   // localStorage.theme = "dark";

  //   // // Whenever the user explicitly chooses to respect the OS preference
  //   // localStorage.removeItem("theme");
  // }, [isDarkMode]);

  return { theme, mode, setMode };
};

export default useTheme;
