import React, { useState, useEffect } from "react";
import { useAuthState } from "../context/auth";
import { useNavigate } from "react-router-dom";
import MainLayout from "../components/layouts/MainLayout";
import Login from "../components/sections/Login";
import UpdatePassword from "../components/sections/UpdatePassword";

const HomePage: React.FC = () => {
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [isPasswordRotation, setIsPasswordRotation] = useState(false);
  const [passwordResetToken, setPasswordResetToken] = useState("");
  const { isAuthenticated, clientCode } = useAuthState();

  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate(`/${clientCode}/apps`);
    }
    localStorage.setItem(`${clientCode}_TARGET`, "");
    // eslint-disable-next-line
  }, [isAuthenticated]);

  const toggleChangePassword = (state: boolean): void => {
    setIsChangePassword(state);
  };

  const togglRotatePassword = (state: boolean): void => {
    setIsPasswordRotation(state);
  };

  return (
    <MainLayout>
      {isChangePassword ? (
        <UpdatePassword
          toggleChangePassword={toggleChangePassword}
          isPasswordRotation={isPasswordRotation}
          passwordRenewalToken={passwordResetToken}
          isHome
        />
      ) : (
        <Login
          toggleChangePassword={toggleChangePassword}
          setPasswordResetToken={setPasswordResetToken}
          togglRotatePassword={togglRotatePassword}
        />
      )}
    </MainLayout>
  );
};

export default HomePage;
